import React from 'react'
import classNames from 'classnames'

import Button, { type ButtonProps } from '@uiLibrary/Button'

import styles from './ButtonGroup.module.scss'

export type ButtonGroupProps = {
  buttons: ButtonProps[]
  useLeftToRightFocusOrder?: boolean
}

/**
 * This component shows a set of buttons. Unless the useLeftToRightFocusOrder prop is set, focus goes from
 * right to left.
 *
 * On smaller screens the buttons are shown below each other, with the rightmost button being the top button.
 *
 * TODO: decide/inquire whether this component should only support up to three button (primary, secondary, tertiary).
 * according to design, the rightmost button should be the primary, then the secondary and then the tertiary.
 * This should maybe be enforced by changing the interface.
 */
export const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({
  buttons,
  useLeftToRightFocusOrder
}) => {
  const buttonsToDisplay = useLeftToRightFocusOrder
    ? buttons
    : Array.from(buttons).reverse()
  return (
    <div
      className={classNames(
        styles.buttongroup,
        useLeftToRightFocusOrder ? styles.useLeftToRightFocus : undefined
      )}
      data-testid="buttonGroup"
    >
      {buttonsToDisplay.map((props, i) => (
        <Button
          variant={props.variant}
          label={props.label}
          onClick={props.onClick}
          onMouseDown={props.onMouseDown}
          onBlur={props.onBlur}
          isSubmit={props.isSubmit}
          isLoading={props.isLoading}
          id={props.id}
          key={i}
          tabIndex={props.tabIndex}
        />
      ))}
    </div>
  )
}
