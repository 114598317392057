import React from 'react'
import { FieldError } from 'react-hook-form'
import className from 'classnames'

import Hint from '@uiLibrary/typography/Hint'
import FormFieldLabel from '@uiLibrary/FormFieldLabel'
import Icon from '@uiLibrary/Icon'
import IconTooltip from '@uiLibrary/IconTooltip/IconTooltip'

import TextWithBoldAndItalic from './TextWithBoldAndItalic'
import FormFieldContainer from './FormFieldContainer'
import styles from './FormField.module.scss'

export type FormFieldRenderProps = {
  props: {
    id: string
    /* eslint-disable @typescript-eslint/naming-convention */
    'aria-describedby'?: string
    'aria-labelledby'?: string
    /* eslint-enable @typescript-eslint/naming-convention */
  }
  required: boolean
  invalid: boolean
}

export type Props = {
  id: string
  required?: boolean
  label?: string
  helpText?: React.ReactNode
  tooltip?: React.ReactNode
  renderAsFieldset?: boolean
  error: FieldError | undefined
  warning?: string
  render: (props: FormFieldRenderProps) => JSX.Element
  alwaysProvideLabelIdViaAriaLabelledby?: true
}

type Message = {
  htmlId: string
  type: 'error' | 'warning'
  message: string
  screenReaderTypeLabel: string
}

export const REQUIRED_ERROR_MESSAGE = 'Dies ist ein Pflichtfeld'
export const FALLBACK_ERROR_MESSAGE =
  'Das Feld enthält Fehler, bitte beheben Sie diese.'

const getErrorMessageFromFieldError = (error: FieldError): string => {
  if (error.message) return error.message
  if (error.type === 'required') return REQUIRED_ERROR_MESSAGE
  return FALLBACK_ERROR_MESSAGE
}

const getMessage = (
  id: string,
  error: FieldError | undefined,
  warning: string | undefined
): Message | undefined => {
  const htmlId = `${id}-message`
  if (error) {
    return {
      type: 'error',
      htmlId,
      screenReaderTypeLabel: 'Fehler',
      message: getErrorMessageFromFieldError(error)
    }
  }
  if (warning) {
    return {
      type: 'warning',
      htmlId,
      screenReaderTypeLabel: 'Warnung',
      message: warning
    }
  }
  return undefined
}

const FormField: React.FunctionComponent<Props> = ({
  helpText,
  id,
  label,
  tooltip,
  required,
  renderAsFieldset,
  render,
  warning,
  error,
  alwaysProvideLabelIdViaAriaLabelledby
}) => {
  const invalid = error !== undefined
  const renderProps: FormFieldRenderProps = {
    props: {
      id
    },
    invalid,
    required: required ?? false
  }
  const message = getMessage(id, error, warning)
  const hintHtmlId = `${id}-hint`
  const labelHtmlId = `${id}-label`

  // setup aria-labelledby
  if (helpText) {
    // We connect a helptext via aria-labelledby (due to a decision to get screen readers to announce them more prominently)
    // Since aria-labelledby takes precedence over labels connected via for="..", we need to also connect the label that
    // way in this case.
    renderProps.props['aria-labelledby'] = labelHtmlId + ' ' + hintHtmlId
  } else if (alwaysProvideLabelIdViaAriaLabelledby) {
    renderProps.props['aria-labelledby'] = labelHtmlId
  }

  // setup aria-describedby
  // we use it for error messages, as aria-errormessage is not widely enough supported.
  if (message) renderProps.props['aria-describedby'] = message.htmlId

  return (
    <FormFieldContainer
      className={styles.formField}
      renderAsLegend={renderAsFieldset ?? false}
      invalid={invalid}
      messageId={message?.htmlId}
    >
      {label && (
        <FormFieldLabel
          asLegend={!!renderAsFieldset}
          controlId={id}
          label={label}
          required={required}
          labelId={labelHtmlId}
        />
      )}
      {!renderAsFieldset && tooltip && (
        <IconTooltip id={`${id}-tooltip`}>{tooltip}</IconTooltip>
      )}

      {render(renderProps)}
      {message && (
        <div id={message.htmlId} className={className(styles.message)}>
          <Icon color="red" iconName="error" variant="normal" />
          <span className="sr-only">{`${message.screenReaderTypeLabel}:\u00A0`}</span>
          <TextWithBoldAndItalic text={message.message} />
        </div>
      )}
      {helpText && (
        <div id={hintHtmlId} className={styles.hint}>
          <Hint>{helpText}</Hint>
        </div>
      )}
    </FormFieldContainer>
  )
}

export default FormField
