import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgHeart: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M16.4473 3.95729C14.7348 2.49791 12.1879 2.76041 10.616 4.38229L10.0004 5.01666L9.38476 4.38229C7.81601 2.76041 5.26601 2.49791 3.55351 3.95729C1.59101 5.63229 1.48788 8.63854 3.24413 10.4542L9.29101 16.6979C9.68163 17.101 10.316 17.101 10.7066 16.6979L16.7535 10.4542C18.5129 8.63854 18.4098 5.63229 16.4473 3.95729V3.95729Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgHeart
