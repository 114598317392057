import React, { forwardRef } from 'react'
import classnames from 'classnames'

import styles from '../Heading.module.scss'
import { HeadingProps } from '../Heading'

export const Heading1 = forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, id, className, headingStyle = 'h1' }, ref) => {
    const classNames = classnames(styles[headingStyle], className)

    return (
      <h1
        ref={ref}
        className={classNames}
        id={id}
        /*
      Some of our UI components (SkipLink; Modal; perhaps more) expect that
      every page and every modal contains a programmatically focusable h1 element.
      */
        tabIndex={-1}
      >
        {children}
      </h1>
    )
  }
)
Heading1.displayName = 'Heading1'
