import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { ManagerReport, managerReportsEndpoint } from '../../api/report'
import { ManagerReportsSlice } from '../slices/managerReports'

export const fetchManagerReports = createApiAsyncThunk<ManagerReport[]>(
  'reports/fetchManagerReports',
  async (args, thunkApi) => {
    const response = await fetch(managerReportsEndpoint, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchManagerReportsPendingCase = (
  builder: ActionReducerMapBuilder<ManagerReportsSlice>
): void => {
  builder.addCase(fetchManagerReports.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      pending: true
    }
  }))
}

export const addFetchManagerReportsFulfilledCase = (
  builder: ActionReducerMapBuilder<ManagerReportsSlice>
): void => {
  builder.addCase(fetchManagerReports.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchManagerReportsRejectedCase = (
  builder: ActionReducerMapBuilder<ManagerReportsSlice>
): void => {
  builder.addCase(fetchManagerReports.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
