import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgEdit: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V7C13 6.44772 12.5523 6 12 6C11.4477 6 11 6.44772 11 7V11H3V3H7C7.55228 3 8 2.55228 8 2C8 1.44772 7.55228 1 7 1H3Z"
      fill="currentColor"
    />
    <path
      d="M11.2929 1.29289C11.6834 0.902369 12.3166 0.902369 12.7071 1.29289C13.0976 1.68342 13.0976 2.31658 12.7071 2.70711L11.8107 3.60355L10.3964 2.18934L11.2929 1.29289Z"
      fill="currentColor"
    />
    <path
      d="M5.29289 7.29289L9.68934 2.89645L11.1036 4.31066L6.70711 8.70711C6.31658 9.09763 5.68342 9.09763 5.29289 8.70711C4.90237 8.31658 4.90237 7.68342 5.29289 7.29289Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEdit
