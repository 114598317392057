import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { OrderFormConfig, orderFormConfigEndpoint } from '@api/orderFormConfig'

import { selectCsrfToken } from '../slices/session'
import { OrdersSlice } from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'

export const fetchOrderFormConfig = createApiAsyncThunk<OrderFormConfig>(
  'orders/fetchOrderFormConfig',
  async (args, thunkApi) => {
    const response = await fetch(`${orderFormConfigEndpoint}`, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOrderFormConfigPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderFormConfig.pending, (state) => ({
    ...state,
    orderFormConfig: {
      ...state.orderFormConfig,
      pending: true
    }
  }))
}

export const addFetchOrderFormConfigFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderFormConfig.fulfilled, (state, action) => ({
    ...state,
    orderFormConfig: {
      ...state.orderFormConfig,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchOrderFormConfigRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderFormConfig.rejected, (state, action) => ({
    ...state,
    orderFormConfig: {
      ...state.orderFormConfig,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
