import React from 'react'
import classNames from 'classnames'

import Icon from '@uiLibrary/Icon'
import { iconMap } from '@uiLibrary/Icon/iconMap'

import styles from './IconButton.module.scss'

export type IconButtonProps = {
  variant: 'primary' | 'secondary'
  iconName: keyof typeof iconMap
  onClick?: React.MouseEventHandler
  ariaLabel: string
}
export const IconButton: React.FunctionComponent<IconButtonProps> = ({
  variant,
  iconName,
  onClick,
  ariaLabel
}) => {
  return (
    <button
      type="button"
      className={classNames(styles.iconWrapper, styles[variant])}
      onClick={onClick}
      aria-label={ariaLabel}
    >
      <Icon variant="normal" iconName={iconName} />
    </button>
  )
}
