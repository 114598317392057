import React from 'react'

import ServerCommunicationErrorContent from '@uiLibrary/portal/ServerCommunicationErrorContent'
import { FetchError } from '@redux/apiAsyncThunk'

import { useI18n } from '../../../i18n'

import styles from './NewsletterSubscriptionFailedPane.module.scss'

type Props = {
  closeButton?: JSX.Element
  fetchError: FetchError
}

const NewsletterSubscriptionFailedPane: React.FunctionComponent<Props> = ({
  closeButton,
  fetchError
}) => {
  const { t } = useI18n()

  return (
    <div className={styles.newsletterSubscriptionFailedPane}>
      <ServerCommunicationErrorContent
        title={t('newsletterSettings.serverErrorTitle')}
        message={t('newsletterSettings.serverErrorMessage')}
        error={fetchError}
      />
      {closeButton}
    </div>
  )
}

export default NewsletterSubscriptionFailedPane
