// See https://github.com/i18next/i18next/issues/1035

import i18next, { TFunction } from 'i18next'
import { initReactI18next, useTranslation } from 'react-i18next'
import { de } from 'date-fns/locale'
import { format as dateFnsFormat } from 'date-fns'
import * as Sentry from '@sentry/react'
import moment from 'moment'
import type { Locale } from 'date-fns'

import german from './locales/german'
import landbw from './locales/landbw'

export type JobRadLocale = Locale & {
  currency: string
  currencySymbol: string
  thousandSeparator: string
  decimalSeparator: string
}

const languages = {
  de: {
    locale: {
      ...de,
      currency: 'EUR',
      currencySymbol: '€',
      thousandSeparator: '.',
      decimalSeparator: ','
    } as JobRadLocale,
    dateFormat: 'dd.MM.yyyy',
    translation: german
  },
  landbw: {
    locale: {
      ...de,
      currency: 'EUR',
      currencySymbol: '€',
      thousandSeparator: '.',
      decimalSeparator: ','
    } as JobRadLocale,
    dateFormat: 'dd.MM.yyyy',
    translation: landbw
  }
} as const

export type FormatDateFunction = (
  inputDate: string | Date,
  format?: string
) => string

export const useI18n = (): {
  locale: JobRadLocale
  dateFormat: string
  formatDate: FormatDateFunction
  t: TFunction
} => {
  const { i18n, t } = useTranslation()
  const { locale, dateFormat } =
    languages[i18n.language as keyof typeof languages]

  /**
   * Formats a date string or object to the current locale
   * @param inputDate Date as String or Date object
   * @param format Defaults to the dateFormat of the current locale
   * @returns Formatted date string
   */
  const formatDate: FormatDateFunction = (inputDate, format = 'DD.MM.YYYY') => {
    try {
      const date = inputDate instanceof Date ? inputDate : new Date(inputDate)
      dateFnsFormat(date, dateFormat, { locale })
    } catch (e) {
      const message = e instanceof Error ? e.message : 'unkown error'
      Sentry.captureEvent({
        message,
        exception: {
          values: [{ type: 'DATE-FNS', value: message }]
        },
        extra: {
          dateValue: inputDate,
          dateFormat: format,
          locale: locale
        }
      })
    }

    return moment(inputDate).format(format)
  }

  return { locale, dateFormat, formatDate, t }
}

export const resources = {
  de: { translation: languages.de.translation },
  landbw: { translation: languages.landbw.translation }
} as const
export const defaultNS = 'translation' as const

// eslint-disable-next-line import/no-named-as-default-member
i18next.use(initReactI18next).init({
  lng: 'de',
  fallbackLng: 'de',
  interpolation: {
    escapeValue: false
  },
  resources
})

/**
 * Do not use this inside components. Instead, use the `useI18n` hook.
 * Reason: `useI18n` is more type-safe because it internally uses react-i18next
 * instead of i18next.
 */
const i18n = i18next

export default i18n
