import React from 'react'
import { Trans } from 'react-i18next'

import { Body } from '@uiLibrary/typography/Body/Body'
import Icon from '@uiLibrary/Icon'
import { Link } from '@uiLibrary/portal/Link/Link'

import { useI18n } from '../../../../i18n'

import styles from './FooterContent.module.scss'

const FooterContent: React.FunctionComponent = () => {
  const { t } = useI18n()
  return (
    <div className={styles.copyrightSection}>
      <Body small inline className={styles.copyright}>
        {t('footer.copyrightDisclaimer', {
          year: new Date().getFullYear()
        })}
      </Body>
      <Body small inline lang="en">
        <Trans
          i18nKey={'footer.poweredWithLoveText'}
          components={{
            heartIcon: (
              <Icon color="white" variant="big" iconName="heart" alt="heart" />
            ),
            linktarget: (
              <Link
                iconName="externalLink"
                small
                linkTarget={{
                  internal: false,
                  location: 'https://www.jobrad.org/'
                }}
                reverse
                linkText={t('footer.poweredByJobrad')}
                openInNewTab
              />
            )
          }}
        />
      </Body>
    </div>
  )
}

export default FooterContent
