import React from 'react'

import Modal from '@uiLibrary/Modal'
import ModalButtons from '@uiLibrary/Modal/ModalButtons'
import Body from '@uiLibrary/typography/Body'
import Heading2 from '@uiLibrary/typography/Heading2'

import { useI18n } from '../../../i18n'

import styles from './AppInfoModal.module.scss'

type BodyObject = {
  headline1: string
  listItems1: []
  headline2: string
  listItems2: []
}

type AppInfoModalProps = {
  title: string
  body: BodyObject | string
  open: boolean
  onClose: () => void
}

const AppInfoModal: React.FunctionComponent<AppInfoModalProps> = ({
  title,
  open,
  onClose,
  body
}) => {
  const { t } = useI18n()

  return (
    <Modal title={title} open={open} onClose={onClose}>
      {typeof body === 'object' && (
        <div>
          <Heading2 headingStyle="h4">{body.headline1}</Heading2>
          <ul>
            {body.listItems1.map((listItem, index) => {
              return <li key={index}>{listItem}</li>
            })}
          </ul>
          <Heading2 headingStyle="h4">{body.headline2}</Heading2>
          <ul>
            {body.listItems2.map((listItem, index) => {
              return <li key={index}>{listItem}</li>
            })}
          </ul>
        </div>
      )}
      {typeof body === 'string' && (
        <Body className={styles['message']}>{body}</Body>
      )}
      <ModalButtons
        secondaryLabel={t('appInfo.modalButtonlabel')}
        onSecondaryClick={onClose}
      />
    </Modal>
  )
}

export default AppInfoModal
