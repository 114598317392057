import * as React from 'react'
import type { FunctionComponent, SVGProps } from 'react'

const SvgTime: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <circle cx="7" cy="7" r="7" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.5C7.55228 1.5 8 1.94772 8 2.5V6.58579L10.2071 8.79289C10.5976 9.18342 10.5976 9.81658 10.2071 10.2071C9.81658 10.5976 9.18342 10.5976 8.79289 10.2071L6 7.41421V2.5C6 1.94772 6.44772 1.5 7 1.5Z"
      fill="white"
    />
  </svg>
)
export default SvgTime
