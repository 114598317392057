import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Offer, OfferDetail } from '../../api/offer'
import { FetchError } from '../apiAsyncThunk'
import { RootState, ErrorAndPendingAwareState } from '../index'
import {
  addFetchOffersFulfilledCase,
  addFetchOffersPendingCase,
  addFetchOffersRejectedCase
} from '../api/fetchOffers'
import {
  addFetchOfferDetailFulfilledCase,
  addFetchOfferDetailPendingCase,
  addFetchOfferDetailRejectedCase
} from '../api/fetchOfferDetail'

export type OfferList = ErrorAndPendingAwareState<Offer[]>

export type OffersSlice = {
  dataError?: FetchError
  list: OfferList
  details: Partial<Record<string, ErrorAndPendingAwareState<OfferDetail>>>
  localList: Offer[]
}

export const initialState: OffersSlice = {
  list: {
    pending: false
  },
  localList: [],
  details: {}
}

const offersSlice = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    addLocalOffer: (state, action: PayloadAction<Offer>) => ({
      ...state,
      localList: [...state.localList, action.payload]
    }),
    removeLocalOffer: (state, action: PayloadAction<number>) => ({
      ...state,
      localList: state.localList.filter(
        (localOffer) => localOffer.id !== action.payload
      )
    })
  },
  extraReducers: (builder) => {
    addFetchOffersPendingCase(builder)
    addFetchOffersFulfilledCase(builder)
    addFetchOffersRejectedCase(builder)

    addFetchOfferDetailPendingCase(builder)
    addFetchOfferDetailFulfilledCase(builder)
    addFetchOfferDetailRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareOffers = (
  state: RootState
): ErrorAndPendingAwareState<Offer[]> => state.offers.list

const selectErrorAndPendingAwareOfferDetailBaseReturn = {
  error: undefined,
  pending: false,
  data: undefined
}
export const selectErrorAndPendingAwareOfferDetail = (
  state: RootState,
  offerCode: string
): ErrorAndPendingAwareState<OfferDetail> => {
  const errorAndPendingAwareOfferDetail = state.offers.details[offerCode]
  if (errorAndPendingAwareOfferDetail === undefined)
    return selectErrorAndPendingAwareOfferDetailBaseReturn
  return errorAndPendingAwareOfferDetail
}

export const selectLocalOffers = (state: RootState): Offer[] =>
  state.offers.localList

export default offersSlice.reducer

export const { addLocalOffer, removeLocalOffer } = offersSlice.actions
