import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgMail: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1 3C0.999998 2.44772 1.44771 2 2 2H12C12.5523 2 13 2.44772 13 3V11C13 11.5523 12.5523 12 12 12H2.00002C1.44773 12 1.00002 11.5523 1.00002 11L1 3ZM3.00001 5.24996L3.00001 10H11V5.24995L8.19999 7.34995C7.48888 7.88328 6.51111 7.88328 5.79999 7.34995L3.00001 5.24996ZM9.33326 4H4.66673L6.99999 5.74995L9.33326 4Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMail
