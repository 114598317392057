import { createSlice } from '@reduxjs/toolkit'

import { Order } from '../../api/order'
import { RootState, ErrorAndPendingAwareState } from '../'
import {
  addFetchOrdersEmployeeFulfilledCase,
  addFetchOrdersEmployeePendingCase,
  addFetchOrdersEmployeeRejectedCase
} from '../api/fetchOrdersEmployee'

export type EmployeeOrderList = ErrorAndPendingAwareState<Order[]>

export type OrdersEmployeeSlice = {
  list: EmployeeOrderList
}

export const initialState: OrdersEmployeeSlice = {
  list: {
    pending: false
  }
}

const ordersEmployeeSlice = createSlice({
  name: 'ordersEmployee',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchOrdersEmployeePendingCase(builder)
    addFetchOrdersEmployeeFulfilledCase(builder)
    addFetchOrdersEmployeeRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareOrdersEmployee = (
  state: RootState
): ErrorAndPendingAwareState<Order[]> => state.ordersEmployee.list

export default ordersEmployeeSlice.reducer
