import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgSearch: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13 6C13 8.76142 10.7614 11 8 11C6.98092 11 6.03304 10.6951 5.24261 10.1716L2.70711 12.7071C2.31658 13.0976 1.68342 13.0976 1.29289 12.7071C0.902369 12.3166 0.902369 11.6834 1.29289 11.2929L3.8284 8.75739C3.30488 7.96696 3 7.01908 3 6C3 3.23858 5.23858 1 8 1C10.7614 1 13 3.23858 13 6ZM11 6C11 7.65685 9.65685 9 8 9C6.34315 9 5 7.65685 5 6C5 4.34315 6.34315 3 8 3C9.65685 3 11 4.34315 11 6Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgSearch
