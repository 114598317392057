import React from 'react'

import { useI18n } from '../../../../i18n'

type Prop = {
  message?: string
}

const ConnectivityTroubleshootingMessage: React.FunctionComponent<Prop> = ({
  message
}) => {
  const { t } = useI18n()

  return message ? (
    <span>{message}</span>
  ) : (
    <span>
      {t('uiLibrary.portal.connectivityTroubleshootingMessage.message')}
      {/*as soon as we have logging in place we should add:
      "Wir sind über das Problem informiert und arbeiten an der Behebung." */}
    </span>
  )
}

export default ConnectivityTroubleshootingMessage
