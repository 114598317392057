import { createSlice } from '@reduxjs/toolkit'

import { RootState, ErrorAndPendingAwareState } from '@redux'
import { ManagerReport } from '@api/report'

import {
  addFetchManagerReportsFulfilledCase,
  addFetchManagerReportsPendingCase,
  addFetchManagerReportsRejectedCase
} from '../api/fetchManagerReports'

export type ManagerReportsState = ErrorAndPendingAwareState<ManagerReport[]>

export type ManagerReportsSlice = {
  list: ManagerReportsState
}

export const initialState: ManagerReportsSlice = {
  list: {
    pending: false
  }
}

const managerReportsSlice = createSlice({
  name: 'managerReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchManagerReportsFulfilledCase(builder)
    addFetchManagerReportsPendingCase(builder)
    addFetchManagerReportsRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareManagerReports = (
  state: RootState
): ErrorAndPendingAwareState<ManagerReport[]> => state.managerReports.list

export default managerReportsSlice.reducer
