// references
// https://github.com/reduxjs/redux-toolkit/issues/486
// https://github.com/reduxjs/redux-toolkit/issues/571
// https://github.com/reduxjs/redux-toolkit/commit/767605ddab535b11e65b19d7cb0ca91cad66f250

import {
  AnyAction,
  AsyncThunk,
  AsyncThunkPayloadCreator,
  createAsyncThunk,
  isRejected,
  isRejectedWithValue,
  Middleware,
  SerializedError
} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { ApiError } from '../api'

import {
  ApiAsyncThunkRejectedAction,
  ApiAsyncThunkRejectedWithApiErrorAction
} from './apiAsyncThunkActionTypes'

import { DispatchAction, RootState } from './index'

export type CustomApiError = ApiError & {
  isExpectedError: boolean
}

export type FetchError = {
  apiError?: CustomApiError
  indefiniteError?: SerializedError
}

export const buildFetchError = ({
  error,
  payload
}: ApiAsyncThunkRejectedAction<unknown>): FetchError =>
  payload ? { apiError: payload } : { indefiniteError: error }

export const failedWithStatus = (error: FetchError, code: number): boolean =>
  !!error.apiError && error.apiError.code === code

const apiFailedWithStatus = (error: ApiError, codes: number[]): boolean => {
  return !!error.code && codes.includes(error.code)
}

export type ThunkApiConfig = {
  rejectValue: CustomApiError
  state: RootState
  dispatch: DispatchAction
}

export type ApiAsyncThunk<Returned, ThunkArg> = AsyncThunk<
  Returned,
  ThunkArg,
  ThunkApiConfig
>

// createAsyncThunk wrapper for api fetching
// currently only sets the rejectValue type
export function createApiAsyncThunk<Returned, ThunkArg = void>(
  typePrefix: string,
  payloadCreator: AsyncThunkPayloadCreator<Returned, ThunkArg, ThunkApiConfig>
): ApiAsyncThunk<Returned, ThunkArg> {
  return createAsyncThunk<Returned, ThunkArg, ThunkApiConfig>(
    typePrefix, // todo: maybe put something in the type to indicate that this is an apiAsyncThunk?
    payloadCreator
  )
}

export const isApiAsyncThunkRejectedWithApiErrorAction = (
  action: AnyAction
): action is ApiAsyncThunkRejectedWithApiErrorAction =>
  action.type.endsWith('/rejected') &&
  'requestId' in action.meta &&
  action.payload

// Caution: Do not log action.meta for portal session patches!
// It may contain the password of the user!
export const sentryErrorLogger: Middleware = () => (next) => (action) => {
  if (action === undefined || action.payload === undefined) {
    return next(action)
  }

  if (!action.payload.isExpectedError) {
    if (isRejectedWithValue(action)) {
      const message = `${action.type} with code ${action.payload.code}: ${action.payload.message}`

      Sentry.captureEvent({
        message,
        exception: {
          values: [{ type: 'Api Error', value: message }]
        },
        fingerprint: [action.type, action.payload.code, action.payload.message],
        extra: action.payload
      })
    } else if (isRejected(action)) {
      const message = `${action.type} with: ${action.payload.message}`

      Sentry.captureEvent({
        message,
        exception: {
          values: [{ type: 'Api Error', value: message }]
        },
        fingerprint: [action.type, action.payload.message],
        extra: action.payload
      })
    }
  }

  return next(action)
}

export const buildRejectValue = (
  responseBody: ApiError,
  expectedErrorCodes?: number[]
): CustomApiError => {
  const expectedErrorCodesWith401 = expectedErrorCodes
    ? [...expectedErrorCodes, 401]
    : [401]

  const expectedError = apiFailedWithStatus(
    responseBody,
    expectedErrorCodesWith401
  )

  return {
    ...responseBody,
    isExpectedError: expectedError
  }
}
