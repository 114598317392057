import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgCloseChip: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <circle cx="10" cy="10" r="9" fill="currentColor" />
    <path
      d="M11.5164 10L13.7907 7.72568C14.0698 7.44659 14.0698 6.99409 13.7907 6.71477L13.2852 6.20932C13.0061 5.93023 12.5536 5.93023 12.2743 6.20932L10 8.48364L7.72568 6.20932C7.44659 5.93023 6.99409 5.93023 6.71477 6.20932L6.20932 6.71477C5.93023 6.99386 5.93023 7.44636 6.20932 7.72568L8.48364 10L6.20932 12.2743C5.93023 12.5534 5.93023 13.0059 6.20932 13.2852L6.71477 13.7907C6.99386 14.0698 7.44659 14.0698 7.72568 13.7907L10 11.5164L12.2743 13.7907C12.5534 14.0698 13.0061 14.0698 13.2852 13.7907L13.7907 13.2852C14.0698 13.0061 14.0698 12.5536 13.7907 12.2743L11.5164 10Z"
      fill="white"
    />
  </svg>
)
export default SvgCloseChip
