import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { OfferDetail, offerDetailEndpoint } from '../../api/offer'
import { OffersSlice } from '../slices/offers'

export const fetchOfferDetail = createApiAsyncThunk<OfferDetail, string>(
  'offers/fetchOfferDetail',
  async (args, thunkApi) => {
    const response = await fetch(`${offerDetailEndpoint(args)}`, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOfferDetailFulfilledCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOfferDetail.fulfilled, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg]: {
        pending: false,
        error: undefined,
        data: action.payload
      }
    }
  }))
}

export const addFetchOfferDetailPendingCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOfferDetail.pending, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg]: {
        ...state.details[action.meta.arg],
        pending: true
      }
    }
  }))
}

export const addFetchOfferDetailRejectedCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOfferDetail.rejected, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg]: {
        data: undefined,
        pending: false,
        error: buildFetchError(action)
      }
    }
  }))
}
