import React from 'react'

import { isHtmlElement } from '@misc/htmlDom'

import i18n from '../../i18n'

const focusTheFocusableElement = (
  e: React.MouseEvent<HTMLAnchorElement>
): void => {
  e.preventDefault()

  const focusableHeading1Element = ':where(h1)[tabindex="-1"]'

  const focusConditions =
    ':not(:disabled):not([readonly]):not([aria-hidden="true"])'
  const selector = focusableHeading1Element + focusConditions
  const mainContentEl = document.getElementById('maincontent')

  if (!mainContentEl) return

  const elements = mainContentEl.querySelectorAll(selector)
  const heading1Element = [...elements].find(isHtmlElement)
  if (!heading1Element) return

  heading1Element.focus()
}

export const SkipLink: React.FunctionComponent = () => (
  <span className="skip-link">
    <a href="#maincontent" onClick={focusTheFocusableElement}>
      {i18n.t('skipToContent')}
    </a>
  </span>
)
