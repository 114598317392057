import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgCheck: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.7071 3.79289C20.0976 4.18342 20.0976 4.81658 19.7071 5.20711L8.70711 16.2071C8.31658 16.5976 7.68342 16.5976 7.29289 16.2071L0.292893 9.20711C-0.0976311 8.81658 -0.0976311 8.18342 0.292893 7.79289C0.683417 7.40237 1.31658 7.40237 1.70711 7.79289L8 14.0858L18.2929 3.79289C18.6834 3.40237 19.3166 3.40237 19.7071 3.79289Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCheck
