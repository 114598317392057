import React, { FunctionComponent, useCallback, useState } from 'react'

import AppInfoAlert from '../AppInfoAlert/AppInfoAlert'
import { MaintenanceValues } from '../../../api/session'
import { useI18n } from '../../../i18n'

import MaintenanceModeModal from './MaintenanceModeModal/MaintenanceModeModal'

type MaintenanceModeMessagesProps = {
  maintenanceValues: MaintenanceValues
}

const MaintenanceModeMessages: FunctionComponent<
  MaintenanceModeMessagesProps
> = ({ maintenanceValues }) => {
  const { t } = useI18n()
  const [modalOpenedByUser, setModalOpenedByUser] = useState(false)

  const handleOpenModal = useCallback(
    (): void => setModalOpenedByUser(true),
    [setModalOpenedByUser]
  )

  const handleCloseModal = useCallback((): void => {
    setModalOpenedByUser(false)
  }, [setModalOpenedByUser])

  return (
    <>
      <AppInfoAlert
        infoText={t('maintenanceMode.title')}
        handleOpenModal={handleOpenModal}
      />
      <MaintenanceModeModal
        open={modalOpenedByUser}
        onClose={handleCloseModal}
        message={maintenanceValues.maintenance_notification}
      />
    </>
  )
}

export default MaintenanceModeMessages
