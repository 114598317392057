import React from 'react'

import { FetchError } from '@redux/apiAsyncThunk'
import ErrorDebugInfo from '@uiLibrary/portal/ServerCommunicationErrorContent/ServerCommunicationErrorContentBase/ErrorDebugInfo/ErrorDebugInfo'

type ServerCommunicationErrorContentBaseProps = {
  title: string
  error: FetchError
  children: React.ReactNode
}

const ServerCommunicationErrorContentBase: React.FunctionComponent<
  ServerCommunicationErrorContentBaseProps
> = ({ title, error, children }) => {
  return (
    <>
      {title}
      {'. '}
      {children}
      <ErrorDebugInfo fetchError={error} />
    </>
  )
}

export default ServerCommunicationErrorContentBase
