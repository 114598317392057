import { config } from '../config'

import { Schemas } from './index'

export type HelpdeskTicket = Schemas['HelpDeskTicket']

type HelpdeskTicketEndpointParameters = {
  method: 'POST'
  body: string
}

export const getHelpdeskTicketEndpointParameters = (
  helpdeskTicket: HelpdeskTicket
): HelpdeskTicketEndpointParameters => {
  return {
    method: 'POST',
    body: JSON.stringify(helpdeskTicket)
  }
}

export const helpdeskTicketEndpoint = `${config.apiUrl}v1/helpdesk/tickets`
