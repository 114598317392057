import React from 'react'
import classNames from 'classnames'

import ErrorBox from '@uiLibrary/ErrorBox'
import Heading4 from '@uiLibrary/typography/Heading4'
import Button from '@uiLibrary/Button'
import Body from '@uiLibrary/typography/Body'
import Heading5 from '@uiLibrary/typography/Heading5'

import {
  HelpdeskTicketTransmissionError,
  HELPDESKTICKET_TRANSMISSION_ERRORS,
  HELPDESKTICKET_TRANSMISSION_STATES,
  HelpdeskTicketTransmissionState
} from '../HelpdeskPopover'
import { useI18n } from '../../../../../i18n'

import styles from './HelpdeskTicketTransmitted.module.scss'

type HelpdeskTicketSubmittedProps = {
  setHelpdeskTicketTransmissionState: (
    isSubmitted: HelpdeskTicketTransmissionState
  ) => void
  helpdeskTransmissionError: HelpdeskTicketTransmissionError
  closeButtonHandler: () => void
}

const HelpdeskTicketTransmitted: React.FunctionComponent<
  HelpdeskTicketSubmittedProps
> = ({
  setHelpdeskTicketTransmissionState,
  helpdeskTransmissionError,
  closeButtonHandler
}) => {
  const { t } = useI18n()

  const closeHelpdeskTicketPopover = (): void => {
    closeButtonHandler()
    setHelpdeskTicketTransmissionState(
      HELPDESKTICKET_TRANSMISSION_STATES.notTransmitted
    )
  }
  return (
    <div className={styles.helpdeskSuccessContent}>
      {helpdeskTransmissionError ===
        HELPDESKTICKET_TRANSMISSION_ERRORS.virus && (
        <div className={styles.marginBottom}>
          <ErrorBox
            ariaLive={'assertive'}
            errorMessage={t(
              'features.helpdesk.helpdeskTicketTransmitted.errorVirusMessage'
            )}
          />
        </div>
      )}

      {helpdeskTransmissionError ===
      HELPDESKTICKET_TRANSMISSION_ERRORS.generalError ? (
        <Heading5 className={classNames(styles.textCenter, styles.error)}>
          {t('features.helpdesk.helpdeskTicketTransmitted.errorMessage')}
        </Heading5>
      ) : (
        <Heading4>
          {t('features.helpdesk.helpdeskTicketTransmitted.header')}
        </Heading4>
      )}
      <div className={styles.marginBottom}>
        {helpdeskTransmissionError ===
        HELPDESKTICKET_TRANSMISSION_ERRORS.generalError ? (
          <Body>
            {t('features.helpdesk.helpdeskTicketTransmitted.generalError')}
          </Body>
        ) : (
          <Body>{t('features.helpdesk.helpdeskTicketTransmitted.body')}</Body>
        )}
      </div>
      <Button
        label={t('closeLabel')}
        variant={'secondary'}
        onClick={closeHelpdeskTicketPopover}
      />
    </div>
  )
}

export default HelpdeskTicketTransmitted
