// TODO: Have useTypedLocation return the portal, then delete this hook

// eslint-disable-next-line no-restricted-imports
import { useLocation } from 'react-router-dom'

export type Portal = 'employee' | 'manager'

export const usePortal = (): Portal => {
  const routeIsManagerPortal = useLocation().pathname.includes('manager')
  return routeIsManagerPortal ? 'manager' : 'employee'
}
