import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import { OrdersSlice } from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { OrderMessages, orderMessagesEndpoint } from '../../api/order'

export const fetchOrderMessages = createApiAsyncThunk<OrderMessages, number>(
  'orders/fetchOrderMessages',
  async (orderId, thunkApi) => {
    const response = await fetch(orderMessagesEndpoint(orderId), {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOrderMessagesPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderMessages.pending, (state, action) => ({
    ...state,
    messages: {
      ...state.messages,
      [action.meta.arg]: {
        ...state.messages[action.meta.arg],
        pending: true
      }
    }
  }))
}

export const addFetchOrderMessagesFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderMessages.fulfilled, (state, action) => ({
    ...state,
    messages: {
      ...state.messages,
      [action.meta.arg]: {
        pending: false,
        error: undefined,
        data: action.payload
      }
    }
  }))
}

export const addFetchOrderMessagesRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderMessages.rejected, (state, action) => ({
    ...state,
    messages: {
      ...state.messages,
      [action.meta.arg]: {
        pending: false,
        error: buildFetchError(action),
        data: undefined
      }
    }
  }))
}
