import React from 'react'

import styles from './FormattedJsonCode.module.scss'

type Props = {
  object: unknown
}

const FormattedJsonCode: React.FunctionComponent<Props> = ({ object }) => {
  return (
    <div className={styles.code}>
      {JSON.stringify(object, undefined, 2).replaceAll('\\n', '\n')}
    </div>
  )
}

export default FormattedJsonCode
