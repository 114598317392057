import React from 'react'

import Modal from '@uiLibrary/Modal'
import ModalButtons from '@uiLibrary/Modal/ModalButtons'

import { useI18n } from '../../../i18n'

type HelpdeskScreenshotPreviewModalProps = {
  isModalOpen: boolean
  closeModal: () => void
  screenshot: string
}

const HelpdeskScreenshotPreviewModal: React.FunctionComponent<
  HelpdeskScreenshotPreviewModalProps
> = ({ isModalOpen, closeModal, screenshot }) => {
  const { t } = useI18n()
  const downloadScreenshot = (): void => {
    const download = document.createElement('a')
    download.href = screenshot
    download.download = 'Bildschirmfoto.png'
    download.click()
    download.remove()
  }

  return (
    <Modal
      title={t('features.helpdesk.fileAndScreenshotCapture.screenShotFileName')}
      open={isModalOpen}
      onClose={closeModal}
    >
      <div>
        <img src={screenshot} style={{ maxWidth: '100%' }} />
      </div>
      <ModalButtons
        primaryLabel={t(
          'features.helpdesk.fileAndScreenshotCapture.screenShotModal.download'
        )}
        onPrimaryClick={downloadScreenshot}
        tertiaryLabel={t('closeLabel')}
        onTertiaryClick={closeModal}
      />
    </Modal>
  )
}

export default HelpdeskScreenshotPreviewModal
