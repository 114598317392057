import React from 'react'

import { Link } from '@uiLibrary/portal/Link/Link'
import { Session } from '@api/session'

import { useI18n } from '../../i18n'

import styles from './Footer.module.scss'
import LastFooterColumn from './LastFooterColumn'
import FooterExplanatoryVideos from './FooterExplanatoryVideos'

type Props = {
  session?: Session
  portal?: 'employee' | 'manager'
}
const Footer: React.FunctionComponent<Props> = ({ session, portal }) => {
  const isManager = session?.access_rights ? session?.access_rights : false
  const { t } = useI18n()

  return (
    <footer
      className={portal === 'manager' ? styles.managerFooter : styles.footer}
    >
      <div className={styles['footer-content']}>
        <div className={styles['footer-left']}>
          <h2 id="important-links-in-footer">
            {t('footer.importantLinks.header')}
          </h2>
          <ul aria-describedby="important-links-in-footer">
            {isManager && session?.contract.manager_faq_url && (
              <li>
                <Link
                  small
                  linkTarget={{
                    internal: false,
                    location: session?.contract.manager_faq_url
                  }}
                  reverse
                  linkText={t('footer.importantLinks.managerFaq')}
                  iconName="externalLink"
                  openInNewTab
                />
              </li>
            )}
            {session?.contract.employee_faq_url && (
              <li>
                <Link
                  iconName="externalLink"
                  small
                  linkTarget={{
                    internal: false,
                    location: session?.contract.employee_faq_url
                  }}
                  reverse
                  linkText={t('footer.importantLinks.employeeFaq')}
                  openInNewTab
                />
              </li>
            )}
          </ul>
        </div>

        <div className={styles['footer-middle']}>
          {/* the links to the videos require a slug (and therefor the session) */}
          {session !== undefined && <FooterExplanatoryVideos />}
        </div>
        <div className={styles['footer-imprint']}>
          <div className={styles['footer-imprint-links']}>
            <Link
              linkTarget={{
                internal: false,
                location: 'https://www.jobrad.org/impressum'
              }}
              small
              reverse
              openInNewTab
              iconName="externalLink"
              linkText={t('footer.imprintLinkText')}
            />

            <div className={styles.imprintSeparator} />

            <Link
              small
              linkTarget={{
                internal: false,
                location: 'https://www.jobrad.org/datenschutz'
              }}
              reverse
              openInNewTab
              iconName="externalLink"
              linkText={t('footer.privacyLinkText')}
            />
          </div>
        </div>
        <LastFooterColumn />
      </div>
    </footer>
  )
}

export default Footer
