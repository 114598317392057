import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import {
  buildFetchError,
  createApiAsyncThunk,
  buildRejectValue
} from '../apiAsyncThunk'
import { Session, sessionFetchEndpoint, SessionEdit } from '../../api/session'
import { selectCsrfToken, SessionSlice } from '../slices/session'

export const patchSession = createApiAsyncThunk<Session, SessionEdit>(
  'session/patchSession',
  async (arg, thunkApi) => {
    const response = await fetch(sessionFetchEndpoint, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'content-type': 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      method: 'PATCH',
      body: JSON.stringify(arg)
    })

    const responseBody = await response.json()

    if (!response.ok) {
      const expectedResponseCodes = [401]
      return thunkApi.rejectWithValue(
        buildRejectValue(responseBody, expectedResponseCodes)
      )
    }

    return responseBody
  }
)

export const addPatchSessionFulfilledCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(patchSession.fulfilled, (state, action) => ({
    ...state,
    dataError: undefined,
    data: action.payload,
    pending: false
  }))
}

export const addPatchSessionRejectedCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(patchSession.rejected, (state, action) => ({
    ...state,
    dataError: buildFetchError(action),
    pending: false
  }))
}

export const addPatchSessionPendingCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(patchSession.pending, (state) => ({
    ...state,
    pending: true
  }))
}
