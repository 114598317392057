import React, { useRef } from 'react'
import { Dialog } from '@headlessui/react'

import Heading1 from '@uiLibrary/typography/Heading1'

import styles from './Modal.module.scss'

export type ModalProps = {
  title: string
  open: boolean
  onClose: () => void
  children?: React.ReactNode
  isAlert?: true
}

/**
 * This component shows arbitrary content inside a modal. Clicking outside the modal closes the modal.

 * The modal itself does not provide buttons. Typically, you will want to use the `ModalButtons` component
 * as part of the modal content (children) ot provide buttons.
 *
 * For most use-cases the `ConfirmModal` will be sufficient. It simplifies the usage by already providing two buttons
 * and supporting only text content.
 */
export const Modal: React.FunctionComponent<ModalProps> = ({
  title,
  open,
  onClose,
  children,
  isAlert
}) => {
  /*
  This is a workaround for https://github.com/tailwindlabs/headlessui/issues/2922
  and should be removed as soon as the bug is fixed.
   */
  const headingRef = useRef<HTMLHeadingElement>(null)

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={styles.dialog}
      initialFocus={isAlert ? undefined : headingRef}
      role={isAlert ? 'alertdialog' : 'dialog'}
    >
      <Dialog.Overlay className={styles.backdrop} />
      <Dialog.Panel className={styles.panel}>
        <Dialog.Title
          as={Heading1}
          ref={headingRef}
          className={styles.title}
          headingStyle="h4"
        >
          {title}
        </Dialog.Title>

        {children && <div className={styles.children}>{children}</div>}
      </Dialog.Panel>
    </Dialog>
  )
}
