import React from 'react'

import ButtonGroup, { type ButtonGroupProps } from '@uiLibrary/ButtonGroup'

type Props = {
  primaryLabel?: string
  secondaryLabel?: string
  tertiaryLabel?: string
  primaryIsLoading?: boolean
  onPrimaryClick?: () => void
  onSecondaryClick?: () => void
  onTertiaryClick?: () => void
  useLeftToRightFocusOrder?: boolean
}

/**
 * This component implements a ButtonGroup, which is supposed to be used in modals.
 *
 * It supports up to three buttons: one primary, one secondary and one tertiary. All combinations are possible.
 * The primary button can have a loading spinner.
 *
 * The focus order can be reversed, similar to ButtonGroup. This is intended for Modals where the default button should
 * be the dismiss/cancel button, such as a ConfirmModal / a modal implementing the AlertDialog pattern.
 */
export const ModalButtons: React.FC<Props> = ({
  primaryLabel,
  secondaryLabel,
  tertiaryLabel,
  primaryIsLoading,
  onPrimaryClick,
  onSecondaryClick,
  onTertiaryClick,
  useLeftToRightFocusOrder
}) => {
  const buttons: ButtonGroupProps['buttons'] = []
  if (tertiaryLabel) {
    buttons.push({
      variant: 'tertiary',
      label: tertiaryLabel ? tertiaryLabel : '',
      onClick: onTertiaryClick
    })
  }

  if (secondaryLabel) {
    buttons.push({
      variant: 'secondary',
      label: secondaryLabel ? secondaryLabel : '',
      onClick: onSecondaryClick
    })
  }

  if (primaryLabel) {
    buttons.push({
      variant: 'primary',
      label: primaryLabel ? primaryLabel : '',
      onClick: onPrimaryClick,
      isLoading: primaryIsLoading
    })
  }

  return (
    <div>
      <ButtonGroup
        buttons={buttons}
        useLeftToRightFocusOrder={useLeftToRightFocusOrder}
      />
    </div>
  )
}
