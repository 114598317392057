import { StoreEnhancer, configureStore } from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { config } from '../config'

import rootReducer, { RootState } from './reducers'
import { patchSession } from './api/patchSession'
import { sentryErrorLogger } from './apiAsyncThunk'

const defaultMiddlewareOptions = {
  immutableCheck: true
}

const enhancers: StoreEnhancer[] = []
if (config.sentryShouldBeEnabled) {
  const sentryEnhancer = Sentry.createReduxEnhancer({
    actionTransformer: (action) => {
      // Remove passwords sent for password changes
      if (action.type.startsWith(patchSession.typePrefix)) {
        return null
      }

      return action
    }
  })

  enhancers.push(sentryEnhancer)
}

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(defaultMiddlewareOptions).concat([sentryErrorLogger]),
  reducer: rootReducer,
  enhancers
})

export default store

type ChangeHandler<T> = (value: T) => void
type Selector<Value> = (state: RootState) => Value
type Comparator<Value> = (a: Value | undefined, b: Value) => boolean
export const subscribeToStoreChange = <Value>(
  selector: Selector<Value>,
  onChange: ChangeHandler<Value>,
  equalityFn?: Comparator<Value>
): void => {
  let lastValue: Value | undefined = undefined
  const compareFunction: Comparator<Value> = equalityFn ?? ((a, b) => a === b)
  store.subscribe(() => {
    const currentValue = selector(store.getState())
    if (compareFunction(lastValue, currentValue)) return
    lastValue = currentValue
    onChange(currentValue)
  })
}
