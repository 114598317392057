// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router-dom'
import { useCallback } from 'react'

import { useAccessInfo } from '@context/useAccessInfo'

import {
  getLocationContextEvent,
  InternalLocation,
  TypedLocation
} from './useTypedLocation'

const getUrlPath = (
  location: InternalLocation,
  slug: string,
  accessCode: string
): string => {
  // Please keep it alphabetically sorted.
  switch (location.pageName) {
    case 'Delivery Confirmation':
      return `/receiving/${location.context.shipmentCode}`
    case 'Employee Account':
      return `/${slug}/account/${accessCode}`
    case 'Employee Digital UEV Signature':
      return `/${slug}/leasing/${location.context.orderId}/${accessCode}/uev`
    case 'Employee Explanatory Videos':
      return `/${slug}/explanatory-videos-for-employees/${accessCode}`
    case 'Employee Leasing Detail':
      return `/${slug}/leasing/${location.context.orderId}/${accessCode}`
    case 'Employee Order Create':
      return `/${slug}/order/create/${accessCode}`
    case 'Employee Order Edit':
      return `/${slug}/leasing/edit/${location.context.orderId}/${accessCode}`
    case 'Leasing RVA Product Team':
      return `/leasing/end/${location.context.rvaKauCode}`
    case 'Manager Explanatory Videos':
      return `/${slug}/manager/explanatory-videos-for-managers/${accessCode}`
    case 'Manager Order Create':
      return `/${slug}/manager/order/create/${accessCode}`
    case 'Manager Order Detail':
      return `/${slug}/manager/leasing/${location.context.orderId}/${accessCode}`
    case 'Manager Order Edit':
      return `/${slug}/manager/leasing/edit/${location.context.orderId}/${accessCode}`
    case 'Manager Order List':
      return `/${slug}/manager/leasing/${accessCode}`
    case 'Manager Reports':
      return `/${slug}/manager/reports/${accessCode}`
    case 'Manager Vva':
      return `/${slug}/manager/leasing/${location.context.orderId}/${accessCode}/vva`
    case 'Manager Transfer Contract':
      return `/${slug}/manager/contract/${accessCode}`
    case 'My Orders And Offers':
      return `/${slug}/leasing/${accessCode}`
    case 'Redirect':
      return `/${slug}/login/${accessCode}`
    case 'Regular Contract Termination Faq':
      return `/leasing/ending-faq/${location.context.rvaKauCode}`
    default:
      throw new Error()
  }
}

const getUrlQueryString = (location: InternalLocation): string | null => {
  if (
    (location.pageName === 'Employee Order Create' ||
      location.pageName === 'Manager Order Create') &&
    location.context.offerCode !== null
  ) {
    return `?offer_code=${location.context.offerCode}`
  }
  if (
    location.pageName === 'Leasing RVA Product Team' &&
    location.context.event === 'user clicked link from within the portal'
  ) {
    return '?utm_source=list&utm_medium=page'
  }
  if (
    location.pageName === 'Manager Order Detail' &&
    location.context.event === 'user created VVA ticket'
  )
    // TODO: Rework this whole "success_message=..." thing (see `ActionStatusMessage`)
    //  After that, this special treatment can be removed.
    return '?success_message=fs_vva_ticket'
  return null
}

const getUrlHashString = (location: InternalLocation): string | null => {
  if (
    (location.pageName === 'Employee Explanatory Videos' ||
      location.pageName === 'Manager Explanatory Videos') &&
    location.context.videoId !== null
  ) {
    return `#${location.context.videoId}`
  }
  return null
}

export const getUrlPathWithQueryAndHash = (
  location: InternalLocation,
  slug: string,
  accessCode: string
): string => {
  const urlPath = getUrlPath(location, slug, accessCode)
  const urlQueryString = getUrlQueryString(location)
  const urlHashString = getUrlHashString(location)
  let urlPathWithQueryAndHash = urlPath
  if (urlQueryString !== null)
    urlPathWithQueryAndHash = urlPathWithQueryAndHash.concat(urlQueryString)
  if (urlHashString !== null)
    urlPathWithQueryAndHash = urlPathWithQueryAndHash.concat(urlHashString)
  return urlPathWithQueryAndHash
}

export type TypedNavigateFunction = (navigationTarget: TypedLocation) => void

/**
 * Return a function for navigating to a given location within the app.
 *
 * This hook works like [`useNavigate`](https://reactrouter.com/en/6.23.1/hooks/use-navigate)
 * except that the returned function accepts a `TypedLocation` rather than a
 * `Location` object.
 *
 * @see TypedLocation
 */
export const useTypedNavigate = (): TypedNavigateFunction => {
  // TODO: Consider adding support for relative navigation

  const navigate = useNavigate()
  const { slug, accessCode } = useAccessInfo()

  return useCallback(
    (navigationTarget): void => {
      if (!navigationTarget.internal) {
        navigate(navigationTarget.location)
        return
      }

      const urlPathWithQueryString = getUrlPathWithQueryAndHash(
        navigationTarget,
        slug,
        accessCode
      )
      const event = getLocationContextEvent(navigationTarget)

      navigate(urlPathWithQueryString, { state: { event: event } })
    },
    [accessCode, navigate, slug]
  )
}
