import React from 'react'
import classNames from 'classnames'

import styles from './LoadingSpinner.module.scss'
import { LoadingSpinnerWheel } from './Wheel'

type LoadingSpinnerProps = {
  local?: boolean
}

const LoadingSpinner: React.FunctionComponent<LoadingSpinnerProps> = ({
  local
}) => (
  <div
    className={classNames(styles.overlay, local && styles.local)}
    data-testid="loadingSpinner"
  >
    <div className={styles.container}>
      <LoadingSpinnerWheel />
    </div>
  </div>
)

export default LoadingSpinner
