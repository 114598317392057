import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgClose: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M2.29289 10.2929C1.90237 10.6834 1.90237 11.3166 2.29289 11.7071C2.68342 12.0976 3.31658 12.0976 3.70711 11.7071L6.99999 8.41423L10.2929 11.7071C10.6834 12.0977 11.3166 12.0977 11.7071 11.7071C12.0976 11.3166 12.0976 10.6834 11.7071 10.2929L8.4142 7.00002L11.7071 3.70711C12.0976 3.31658 12.0976 2.68342 11.7071 2.29289C11.3166 1.90237 10.6834 1.90237 10.2929 2.29289L6.99999 5.5858L3.70711 2.29292C3.31658 1.9024 2.68342 1.9024 2.29289 2.29292C1.90237 2.68345 1.90237 3.31661 2.29289 3.70714L5.58577 7.00002L2.29289 10.2929Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgClose
