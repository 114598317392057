import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgChevronLeft: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.70711 2.29289C10.0976 2.68342 10.0976 3.31658 9.70711 3.70711L6.41421 7L9.70711 10.2929C10.0976 10.6834 10.0976 11.3166 9.70711 11.7071C9.31658 12.0976 8.68342 12.0976 8.29289 11.7071L4.29289 7.70711C3.90237 7.31658 3.90237 6.68342 4.29289 6.29289L8.29289 2.29289C8.68342 1.90237 9.31658 1.90237 9.70711 2.29289Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChevronLeft
