import React from 'react'

import {
  getLocationContextEvent,
  InternalLocation
} from '@hooks/useTypedLocation'
import { getUrlPathWithQueryAndHash } from '@hooks/useTypedNavigate'
import { useAccessInfo } from '@context/useAccessInfo'

import type { LinkIconName } from '../Link'
import LinkInner from '../LinkInner'

type Props = {
  linkText: string
  linkTarget: InternalLocation
  id: string | undefined
  small: boolean | undefined
  reverse: boolean | undefined
  openInNewTab: boolean | undefined
  iconName: LinkIconName | undefined
}
const InternalLink: React.FunctionComponent<Props> = ({
  linkText,
  linkTarget,
  id,
  small,
  reverse,
  iconName,
  openInNewTab
}) => {
  const { slug, accessCode } = useAccessInfo()
  const to = getUrlPathWithQueryAndHash(linkTarget, slug, accessCode)
  const event = getLocationContextEvent(linkTarget)
  const state = { event: event }

  return (
    <LinkInner
      linkText={linkText}
      to={to}
      state={state}
      id={id}
      small={small}
      reverse={reverse}
      openInNewTab={openInNewTab}
      iconName={iconName}
      reloadDocument={false}
    />
  )
}

export default InternalLink
