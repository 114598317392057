export type TextToken = { text: string; type: 'normal' | 'bold' | 'italic' }

const tagRegexp = new RegExp('<([bi])>(.*?)<\\/\\1>', 'g')

/**
 * tokenizes a string which may includes <b>...</b> and <i>...</i> tags into an array of TextToken objects
 * @param text: text to tokenize
 */
export const tokenizeText = (text: string): TextToken[] => {
  const tokens: TextToken[] = []
  let lastIndex = 0
  for (let result; (result = tagRegexp.exec(text)); ) {
    // add text before match to token list
    const preText = text.slice(lastIndex, result.index)
    if (preText.length) tokens.push({ text: preText, type: 'normal' })
    // add matched tag
    const [match, tag, content] = result
    tokens.push({
      text: content,
      type: tag === 'b' ? 'bold' : 'italic'
    })
    // prepare next loop
    lastIndex = result.index + match.length
  }

  // add text behind last match to token list
  const remaining = text.slice(lastIndex)
  if (remaining.length) tokens.push({ text: remaining, type: 'normal' })
  return tokens
}
