import React from 'react'
import { RouteObject } from 'react-router-dom'

import PublicLayout from './PublicLayout'
import SessionRoute from './SessionRoute'
import PortalLayout from './PortalLayout'

const PageRegularContractTerminationFaq = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_regular_termination_faq" */
      '@pages/PageRegularContractTerminationFaq/PageRegularContractTerminationFaq'
    )
)

const PageLeasingRVAProductTeam = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_leasing_rva_productteam" */
      '@pages/PageLeasingRegularContractTermination'
    )
)

const PageNotFound = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_not_found" */ '@pages/PageNotFound/PageNotFound'
    )
)

const PageDeliveryConfirmation = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_delivery_confirmation" */
      '@pages/PageDeliveryConfirmation'
    )
)
const PageManagerOrderDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_manager_order_detail" */
      '@pages/PageManagerOrderDetail/PageManagerOrderDetail'
    )
)
const PageNewOrder = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_new_order" */
      '@pages/PageOrderCreate/PageOrderCreate'
    )
)
const PageEditOrder = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_edit_order" */
      '@pages/PageOrderEdit/PageOrderEdit'
    )
)
const PageManagerVva = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_manager_vva" */
      '@pages/PageManagerVva/PageManagerVva'
    )
)
const PageManagerTransferContract = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_manager_uev" */
      '@pages/PageManagerTransferContract/PageManagerTransferContract'
    )
)

const PageEmployeeAccount = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_employee_account" */ '@pages/PageEmployeeAccount/PageEmployeeAccount'
    )
)

const PageMyOrdersAndOffers = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_my_orders_and_offers" */ '@pages/PageMyOrdersAndOffers'
    )
)

const PageEmployeeLeasingDetail = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_employee_leasing_detail" */
      '@pages/PageEmployeeLeasingDetail/PageEmployeeLeasingDetail'
    )
)
const PageEmployeeDigitalUEVSignature = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_employee_leasing_detail" */
      '@pages/PageEmployeeDigitalUEVSignature/PageEmployeeDigitalUEVSignature'
    )
)
const PageManagerOrderList = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_manager_order_list" */
      '@pages/PageManagerOrderList/PageManagerOrderList'
    )
)
const PageManagerReports = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_manager_reports" */
      '@pages/PageManagerReports/PageManagerReports'
    )
)
const PageEmployeeExplanatoryVideos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_explanatory_videos" */
      '@pages/PageExplanatoryVideos/PageEmployeeExplanatoryVideos'
    )
)
const PageManagerExplanatoryVideos = React.lazy(
  () =>
    import(
      /* webpackChunkName: "page_explanatory_videos" */
      '@pages/PageExplanatoryVideos/PageManagerExplanatoryVideos'
    )
)

export const routes: RouteObject[] = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: 'receiving/:shipmentCode',
        element: <PageDeliveryConfirmation />
      },
      // The routes below are used and maintained by the contract termination product team.
      {
        path: '/leasing/ending-faq/:rvaKauCode',
        element: <PageRegularContractTerminationFaq />
      },
      {
        path: 'leasing/end/:rvaKauCode',
        element: <PageLeasingRVAProductTeam />
      },
      {
        // This route is left for a while for backwards compatibility reasons (previous KAUs were sent out
        // with this URL) but might be also the new URL for the next test version of the RVA page
        path: '/leasing/finish/:rvaKauCode',
        element: <PageLeasingRVAProductTeam />
      }
      // end of routes of regular contract termination product team.
    ]
  },
  {
    element: <SessionRoute />,
    children: [
      {
        element: <PortalLayout portal="manager" />,
        children: [
          {
            path: ':slug/manager/contract/:accessCode',
            element: <PageManagerTransferContract />
          },
          {
            path: ':slug/manager/leasing/:accessCode',
            element: <PageManagerOrderList />
          },
          {
            path: ':slug/manager/leasing/:orderId/:accessCode',
            element: <PageManagerOrderDetail />
          },
          {
            path: ':slug/manager/leasing/:orderId/:accessCode/vva/',
            element: <PageManagerVva />
          },
          {
            path: ':slug/manager/leasing/edit/:orderId/:accessCode',
            element: <PageEditOrder portal="manager" />
          },
          {
            path: ':slug/manager/order/create/:accessCode',
            element: <PageNewOrder portal="manager" />
          },
          {
            path: ':slug/manager/reports/:accessCode',
            element: <PageManagerReports />
          },
          {
            path: ':slug/manager/explanatory-videos-for-managers/:accessCode',
            element: <PageManagerExplanatoryVideos />
          },
          { path: ':slug/manager/*', element: <PageNotFound /> }
        ]
      },
      {
        element: <PortalLayout portal="employee" />,
        children: [
          {
            path: ':slug/account/:accessCode',
            element: <PageEmployeeAccount />
          },
          {
            path: ':slug/leasing/:accessCode',
            element: <PageMyOrdersAndOffers />
          },
          {
            path: ':slug/leasing/:orderId/:accessCode',
            element: <PageEmployeeLeasingDetail />
          },
          {
            path: ':slug/leasing/:orderId/:accessCode/uev',
            element: <PageEmployeeDigitalUEVSignature />
          },
          {
            path: ':slug/leasing/edit/:orderId/:accessCode',
            element: <PageEditOrder portal="employee" />
          },
          {
            path: ':slug/order/create/:accessCode',
            element: <PageNewOrder portal="employee" />
          },
          {
            path: ':slug/explanatory-videos-for-employees/:accessCode',
            element: <PageEmployeeExplanatoryVideos />
          },
          { path: ':slug/*', element: <PageNotFound /> }
        ]
      }
    ]
  }
]
