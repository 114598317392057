import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider as ReduxProvider } from 'react-redux'
import { CookiesProvider } from 'react-cookie'

import { initializeGTM } from '@misc/google-tag-manager'

import App from './App/App'
import store from './redux/store'
import { config } from './config'
import { setupSentry } from './sentry'

// GTM
if (!config.debug) {
  initializeGTM('GTM-P5N277S')
}

if (config.sentryShouldBeEnabled) {
  setupSentry()
}

const container = document.getElementById('app')
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!)
root.render(
  <React.StrictMode>
    <ReduxProvider store={store}>
      <CookiesProvider>
        <App />
      </CookiesProvider>
    </ReduxProvider>
  </React.StrictMode>
)
