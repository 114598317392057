import React from 'react'

import { FetchError } from '@redux/apiAsyncThunk'

import { config } from '../../../../../config'

import FormattedJsonCode from './FormattedJsonCode/FormattedJsonCode'
import styles from './ErrorDebugInfo.module.scss'

type Props = {
  fetchError?: FetchError
  indefiniteError?: Error
}

const ErrorDebugInfo: React.FunctionComponent<Props> = ({
  fetchError,
  indefiniteError
}) => {
  if (!config.debug) return null
  if (fetchError === undefined && indefiniteError === undefined) return null
  return (
    <div className={styles.debugInfo}>
      {fetchError && (
        <>
          <span>Antwort vom Server (nur sichtbar im Entwicklungsmodus):</span>
          <FormattedJsonCode
            object={fetchError.apiError || fetchError.indefiniteError}
          />
        </>
      )}
      {indefiniteError && (
        <>
          <span>Stack Trace (nur sichtbar im Entwicklungsmodus):</span>
          <FormattedJsonCode object={indefiniteError} />
        </>
      )}
      <br />
      <br />
    </div>
  )
}

export default ErrorDebugInfo
