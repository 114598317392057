import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgPhoto: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M7.00003 9.5C8.1046 9.5 9.00003 8.60457 9.00003 7.5C9.00003 6.39543 8.1046 5.5 7.00003 5.5C5.89546 5.5 5.00003 6.39543 5.00003 7.5C5.00003 8.60457 5.89546 9.5 7.00003 9.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.00002 2.41667L5.00002 1.5H9.00002L10 2.41667L12.1909 2.84267C12.6608 2.93404 13 3.34559 13 3.82428V11.5C13 12.0523 12.5523 12.5 12 12.5H2.00002C1.44773 12.5 1.00002 12.0523 1.00002 11.5L1 3.82429C1 3.34559 1.33924 2.93404 1.80913 2.84267L4.00002 2.41667ZM3 4.64857L4.93557 4.27221L5.77799 3.5H8.22205L9.06446 4.27221L11 4.64857V10.5H3.00002L3 4.64857Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPhoto
