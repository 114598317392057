export const fileToBase64 = async (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onload = function () {
      resolve(reader.result as string)
    }

    reader.onabort = reader.onerror = reject

    reader.readAsDataURL(file)
  })

export const isFileTypeSupported = (
  file: File,
  validTypes: Array<[string, string]>
): boolean =>
  !!validTypes.find(
    ([extension, mimeType]) =>
      file.name.toLowerCase().endsWith(extension) && file.type === mimeType
  )

export const isFileSizeSupported = (
  file: File,
  maxFileSizeInBytes: number
): boolean => maxFileSizeInBytes > file.size

export const isFileSupported = (
  file: File,
  validTypes: Array<[string, string]>,
  maxFileSizeInBytes: number
): boolean =>
  isFileTypeSupported(file, validTypes) &&
  isFileSizeSupported(file, maxFileSizeInBytes)
