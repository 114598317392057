import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgExternalLink: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M3 1C1.89543 1 1 1.89543 1 3V11C1 12.1046 1.89543 13 3 13H11C12.1046 13 13 12.1046 13 11V8C13 7.44772 12.5523 7 12 7C11.4477 7 11 7.44772 11 8V11H3V3H6C6.55228 3 7 2.55228 7 2C7 1.44772 6.55228 1 6 1H3Z"
      fill="currentColor"
    />
    <path
      d="M9 1C8.44772 1 8 1.44772 8 2C8 2.55228 8.44772 3 9 3H9.58579L5.29289 7.29289C4.90237 7.68342 4.90237 8.31658 5.29289 8.70711C5.68342 9.09763 6.31658 9.09763 6.70711 8.70711L11 4.41421V5C11 5.55229 11.4477 6 12 6C12.5523 6 13 5.55229 13 5V2C13 1.44772 12.5523 1 12 1H9Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgExternalLink
