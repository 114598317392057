import { resources as translations } from '../../src/i18n'
import { config } from '../config'

import { Schemas } from './index'

export type Offer = Schemas['Offer']
export type OfferDetail = Schemas['OfferDetail']
export type OfferEdit = Schemas['OfferEdit']

export const offersEndpoint = `${config.apiUrl}v1/offers`
export const offerEndpoint = (offerCode: string): string =>
  `${offersEndpoint}/${offerCode}`
export const offerDetailEndpoint = (offerCode: string): string =>
  `${offersEndpoint}/${offerCode}?extended`

// todo: these should also come from the schema
// however, currently the schema does only supply a generic error schema,
// so these can not be extracted from the schema yet. We need to find
// a good solution here.
export const OFFER_VALIDATION_CODES = [
  'bad_format',
  'no_exist',
  'used',
  'cancelled',
  'failed_accessories',
  'failed_contract',
  'failed_category',
  'failed_price',
  'failed_category_price',
  'failed_model_year',
  'partially_disabled_dealer'
] as const
export type OfferValidation = (typeof OFFER_VALIDATION_CODES)[number]
/* eslint-disable @typescript-eslint/naming-convention */

type offerCodeValidationKeys =
  keyof (typeof translations.de.translation)['offerCodeValidationErrors']
export const OFFER_VALIDATION_CODES_I18N_KEYS: Record<
  OfferValidation,
  `offerCodeValidationErrors.${offerCodeValidationKeys}`
> = {
  bad_format: 'offerCodeValidationErrors.badFormat',
  no_exist: 'offerCodeValidationErrors.noExist',
  used: 'offerCodeValidationErrors.used',
  cancelled: 'offerCodeValidationErrors.cancelled',
  failed_accessories: 'offerCodeValidationErrors.failedAccessories',
  failed_contract: 'offerCodeValidationErrors.failedContract',
  failed_category: 'offerCodeValidationErrors.failedCategory',
  failed_price: 'offerCodeValidationErrors.failedPrice',
  failed_category_price: 'offerCodeValidationErrors.failedCategoryPrice',
  failed_model_year: 'offerCodeValidationErrors.failedModelYear',
  partially_disabled_dealer: 'offerCodeValidationErrors.partiallyDisabledDealer'
}
/* eslint-enable @typescript-eslint/naming-convention */

export const isOfferValidationCode = (code: string): code is OfferValidation =>
  (OFFER_VALIDATION_CODES as readonly string[]).includes(
    code as OfferValidation
  )

export const getInvalidAccessories = (
  offer: Pick<Offer, 'invalid_accessories'>
): string[] | undefined => {
  const invalidAccessories = offer.invalid_accessories
  if (!invalidAccessories) return undefined
  if (invalidAccessories.length === 0) return undefined
  return invalidAccessories
}

export const isOfferInvalid = (
  offer: Pick<Offer, 'validation_error'>
): boolean => {
  return offer.validation_error ? true : false
}
