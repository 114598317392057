import React, { useState } from 'react'

import { ContractInfo } from '@api/contract'

import HelpdeskTicketTransmitted from './HelpdeskTicketTransmitted'
import HelpdeskForm from './HelpdeskForm'

export const HELPDESKTICKET_TRANSMISSION_STATES = {
  notTransmitted: 'NotTransmitted',
  transmitted: 'Transmitted',
  transmitting: 'Transmitting'
} as const
export type HelpdeskTicketTransmissionState =
  (typeof HELPDESKTICKET_TRANSMISSION_STATES)[keyof typeof HELPDESKTICKET_TRANSMISSION_STATES]

export const HELPDESKTICKET_TRANSMISSION_ERRORS = {
  noError: 'NoError',
  generalError: 'GeneralError',
  virus: 'Virus'
} as const

export type HelpdeskTicketTransmissionError =
  (typeof HELPDESKTICKET_TRANSMISSION_ERRORS)[keyof typeof HELPDESKTICKET_TRANSMISSION_ERRORS]

type HelpdeskPopoverProps = {
  contract: Pick<ContractInfo, 'manager_faq_url' | 'employee_faq_url'>
  closePopover: () => void
}

const HelpdeskPopover: React.FunctionComponent<HelpdeskPopoverProps> = ({
  contract,
  closePopover
}) => {
  const [helpdeskTicketTransmissionState, setHelpdeskTicketTransmissionState] =
    useState<HelpdeskTicketTransmissionState>(
      HELPDESKTICKET_TRANSMISSION_STATES.notTransmitted
    )
  const [helpdeskTransmissionError, setHelpdeskTransmissionError] =
    useState<HelpdeskTicketTransmissionError>(
      HELPDESKTICKET_TRANSMISSION_ERRORS.noError
    )

  const isTicketTransmitted =
    helpdeskTicketTransmissionState ===
    HELPDESKTICKET_TRANSMISSION_STATES.transmitted

  return isTicketTransmitted ? (
    <HelpdeskTicketTransmitted
      setHelpdeskTicketTransmissionState={setHelpdeskTicketTransmissionState}
      helpdeskTransmissionError={helpdeskTransmissionError}
      closeButtonHandler={() => closePopover()}
    />
  ) : (
    <HelpdeskForm
      helpdeskTicketTransmissionState={helpdeskTicketTransmissionState}
      contract={contract}
      setHelpdeskTicketTransmissionState={setHelpdeskTicketTransmissionState}
      setHelpdeskTransmissionError={setHelpdeskTransmissionError}
      closeButtonHandler={() => closePopover()}
    />
  )
}

export default HelpdeskPopover
