export const initializeGTM = (gtmId: string): void => {
  if (gtmId === '') {
    // eslint-disable-next-line no-console
    console.warn('Missing Google Tag Manager Id')
    return
  }

  const innerIframe = `
  <iframe src="https://www.googletagmanager.com/ns.html?id=${gtmId}"
  height="0" width="0" style="display:none;visibility:hidden"></iframe>`
  const noScript = document.createElement('noscript')
  noScript.innerHTML = innerIframe

  const innerScript = `
        (function(w,d,s,l,i){w[l]=w[l]||[];
          w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
          var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
          j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl+'&gtm_cookies_win=x';
          f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`
  const script = document.createElement('script')
  script.innerHTML = innerScript

  document.head.insertBefore(script, document.head.childNodes[0])
  document.body.insertBefore(noScript, document.body.childNodes[0])
}
