import React, { AriaAttributes } from 'react'
import classNames from 'classnames'

import { Icon } from '@uiLibrary/Icon/Icon'
import { Body } from '@uiLibrary/typography/Body/Body'

import styles from './ErrorBox.module.scss'

// Types defining aria-live values for accessibility
type AriaLiveType = AriaAttributes['aria-live']

type Props = {
  ariaLive: AriaLiveType // Accessibility attribute for screen readers: polite or assertive
  errorMessage: string | React.ReactElement // Error message of the errorBox. Todo: maybe restrict type to string back in the future
  bulletPoints?: string[] // Optional bullet points
}

export const ErrorBox: React.FC<Props> = ({
  ariaLive,
  errorMessage,
  bulletPoints
}) => {
  // Determine the CSS classes for the errorBox
  const containerClassName = classNames(styles.errorBox)

  // Common content for errorBox
  const commonContent = (
    <div className={styles.containerSm}>
      <Icon color="red" iconName="error" variant="normal" />
      <Body small>{errorMessage}</Body>
    </div>
  )

  return errorMessage ? (
    <div className={containerClassName} role="alert" aria-live={ariaLive}>
      {commonContent}
      {bulletPoints && bulletPoints.length > 0 && (
        <ul className={styles.list}>
          {bulletPoints.map((point, index) => (
            <li key={index}>
              <Body small>{point}</Body>
            </li>
          ))}
        </ul>
      )}
    </div>
  ) : null
}
