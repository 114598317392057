import React from 'react'

import Heading4 from '@uiLibrary/typography/Heading4'

import { useI18n } from '../../../../../../i18n'

import styles from './HelpdeskFileAndScreenshotCapture.module.scss'
import HelpdeskScreenshotCapture from './HelpdeskScreenshotCapture'
import HelpdeskFileUpload from './HelpdeskFileUpload'

type HelpdeskFileAndScreenshotCaptureProps = {
  screenshot: string
  uploadedFile: string
  setScreenshot: (screenshot: string) => void
  setUploadedFile: (uploadedFile: string) => void
}

const HelpdeskFileAndScreenshotCapture: React.FunctionComponent<
  HelpdeskFileAndScreenshotCaptureProps
> = ({ screenshot, uploadedFile, setScreenshot, setUploadedFile }) => {
  const { t } = useI18n()

  return (
    <div className={styles.uploadContainer}>
      <Heading4>
        {t('features.helpdesk.fileAndScreenshotCapture.attachFile')}
      </Heading4>

      <HelpdeskFileUpload
        uploadedFile={uploadedFile}
        setUploadedFile={setUploadedFile}
      />

      <HelpdeskScreenshotCapture
        screenshot={screenshot}
        setScreenshot={setScreenshot}
      />
    </div>
  )
}

export default HelpdeskFileAndScreenshotCapture
