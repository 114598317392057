import React from 'react'

import AppInfoModal from '../../AppInfoModal/AppInfoModal'
import { useI18n } from '../../../../i18n'

type PreviewModeModalProps = {
  open: boolean
  onClose: () => void
  message: string
}

const MaintenanceModeModal: React.FunctionComponent<PreviewModeModalProps> = ({
  open,
  onClose,
  message
}) => {
  const { t } = useI18n()
  return (
    <AppInfoModal
      title={t('maintenanceMode.modal.headline')}
      body={message}
      onClose={onClose}
      open={open}
    />
  )
}

export default MaintenanceModeModal
