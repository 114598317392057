import React, { useEffect, useState } from 'react'
import { Trans } from 'react-i18next'
import { useForm, useWatch } from 'react-hook-form'

import Heading4 from '@uiLibrary/typography/Heading4'
import Body from '@uiLibrary/typography/Body'
import Link from '@uiLibrary/portal/Link'
import InputField from '@uiLibrary/InputField'
import InputFieldMultiLine from '@uiLibrary/InputFieldMultiLine'
import ButtonGroup from '@uiLibrary/ButtonGroup'
import { ContractInfo } from '@api/contract'
import { HelpdeskTicket } from '@api/helpdesk'
import { usePortal } from '@hooks/portal'
import { useTypedDispatch } from '@redux'
import { addHelpdeskTicket } from '@redux/api/addHelpdeskTicket'

import { useI18n } from '../../../../../i18n'
import {
  HELPDESKTICKET_TRANSMISSION_ERRORS,
  HelpdeskTicketTransmissionError,
  HELPDESKTICKET_TRANSMISSION_STATES,
  HelpdeskTicketTransmissionState
} from '../HelpdeskPopover'

import styles from './HelpdeskForm.module.scss'
import HelpdeskFileAndScreenshotCapture from './HelpdeskFileAndScreenshotCapture/HelpdeskFileAndScreenshotCapture'

type HelpdeskFormProps = {
  helpdeskTicketTransmissionState: HelpdeskTicketTransmissionState
  setHelpdeskTicketTransmissionState: (
    isSubmitted: HelpdeskTicketTransmissionState
  ) => void
  setHelpdeskTransmissionError: (error: HelpdeskTicketTransmissionError) => void
  contract: Pick<ContractInfo, 'manager_faq_url' | 'employee_faq_url'>
  closeButtonHandler: () => void
}

const HelpdeskForm: React.FunctionComponent<HelpdeskFormProps> = ({
  setHelpdeskTicketTransmissionState,
  setHelpdeskTransmissionError,
  helpdeskTicketTransmissionState,
  contract,
  closeButtonHandler
}) => {
  const dispatch = useTypedDispatch()
  const { t } = useI18n()
  const portal = usePortal()

  const [helpdeskTicketScreenshot, setHelpdeskTicketScreenshot] = useState('')
  const [helpdeskTicketUploadedFile, setHelpdeskTicketUploadedFile] =
    useState('')

  // TODO: handle helpdeskTicketScreenshot and helpdeskTicketUploadedFile via the form as well
  const form = useForm<{
    helpdeskTicketTitle: string
    helpdeskTicketDescription: string
  }>({
    mode: 'onSubmit'
  })

  const { formState, getValues, handleSubmit, register, reset, control } = form
  const { errors: formErrors } = formState
  const helpdeskTicketDescription = useWatch({
    control,
    name: 'helpdeskTicketDescription'
  })

  const [formDataIsSucessfullySubmitted, setFormDataIsSucessfullySubmitted] =
    useState(false)

  // React-hook-form recommends to use reset inside useEffect
  useEffect(() => {
    const clearFormData = (): void => {
      setHelpdeskTicketScreenshot('')
      setHelpdeskTicketUploadedFile('')
      reset({
        helpdeskTicketTitle: '',
        helpdeskTicketDescription: ''
      })
    }

    if (formDataIsSucessfullySubmitted) {
      clearFormData()
    }
  }, [
    formDataIsSucessfullySubmitted,
    reset,
    setHelpdeskTicketScreenshot,
    setHelpdeskTicketUploadedFile
  ])

  const submitHelpdeskTicket = async (): Promise<void> => {
    if (
      helpdeskTicketTransmissionState ===
      HELPDESKTICKET_TRANSMISSION_STATES.transmitting
    )
      return

    const helpdeskTicketToSubmit: HelpdeskTicket = {
      type: 'question',
      question: {
        issue: {
          title: getValues('helpdeskTicketTitle'),
          description: helpdeskTicketDescription,
          capturedShot: helpdeskTicketScreenshot,
          portal: portal
        },
        info: {
          url: document.URL,
          height: screen.height,
          availHeight: screen.availHeight,
          system: navigator.userAgent,
          availWidth: screen.availWidth,
          width: screen.width
        },
        file: helpdeskTicketUploadedFile,
        origin: portal
      }
    }
    setFormDataIsSucessfullySubmitted(false)

    setHelpdeskTicketTransmissionState(
      HELPDESKTICKET_TRANSMISSION_STATES.transmitting
    )

    const resultAction = await dispatch(
      addHelpdeskTicket(helpdeskTicketToSubmit)
    )

    if (addHelpdeskTicket.rejected.match(resultAction)) {
      setHelpdeskTicketTransmissionState(
        HELPDESKTICKET_TRANSMISSION_STATES.transmitted
      )

      if (resultAction.payload?.code === 400) {
        setHelpdeskTransmissionError(HELPDESKTICKET_TRANSMISSION_ERRORS.virus)
      } else {
        setHelpdeskTransmissionError(
          HELPDESKTICKET_TRANSMISSION_ERRORS.generalError
        )
      }
    } else {
      setFormDataIsSucessfullySubmitted(true)
      setHelpdeskTicketTransmissionState(
        HELPDESKTICKET_TRANSMISSION_STATES.transmitted
      )

      setHelpdeskTransmissionError(HELPDESKTICKET_TRANSMISSION_ERRORS.noError)
    }
  }

  const minimalNecessaryCharsInHelpdeskDescription = 20
  const minimalNecessaryCharsInHelpdeskDescriptionHelpText = t(
    'features.helpdesk.minimalNecessaryCharsInTicketDescriptionHelpText'
  )

  const uri =
    portal === 'employee' ? contract.employee_faq_url : contract.manager_faq_url

  return (
    <form className={styles.content}>
      <div className={styles.formInputContent}>
        <Heading4>{t('features.helpdesk.header')}</Heading4>
        <Body className={styles.faqText}>
          {uri && (
            <Trans
              i18nKey={'features.helpdesk.linkToFaqText'}
              components={{
                faqLink: (
                  <Link
                    iconName="externalLink"
                    linkTarget={{ internal: false, location: uri }}
                    linkText="FAQs"
                    openInNewTab
                  />
                )
              }}
            />
          )}
        </Body>
        <Body>{t('features.helpdesk.requiredFieldsHint')}</Body>
        <InputField
          required
          id="helpdesk-title"
          label={t('features.helpdesk.ticketTitleLabel')}
          error={formErrors.helpdeskTicketTitle}
          {...register('helpdeskTicketTitle', {
            required: {
              value: true,
              message: 'Pflichtfeld. Bitte nennen Sie einen Betreff.'
            }
          })}
        />
        <InputFieldMultiLine
          required
          id="helpdesk-description"
          label={t('features.helpdesk.ticketDescriptionLabel')}
          error={formErrors.helpdeskTicketDescription}
          {...register('helpdeskTicketDescription', {
            required: {
              value: true,
              message: minimalNecessaryCharsInHelpdeskDescriptionHelpText
            },
            minLength: {
              value: minimalNecessaryCharsInHelpdeskDescription,
              message: minimalNecessaryCharsInHelpdeskDescriptionHelpText
            }
          })}
        />
      </div>
      <HelpdeskFileAndScreenshotCapture
        screenshot={helpdeskTicketScreenshot}
        uploadedFile={
          helpdeskTicketUploadedFile ? helpdeskTicketUploadedFile : ''
        }
        setScreenshot={setHelpdeskTicketScreenshot}
        setUploadedFile={setHelpdeskTicketUploadedFile}
      />
      <div className={styles.formButtons}>
        <ButtonGroup
          buttons={[
            {
              label: t('closeLabel'),
              variant: 'secondary',
              onClick: () => closeButtonHandler()
            },
            {
              label: t('features.helpdesk.sendFormLabel'),
              isSubmit: true,
              onClick: handleSubmit(() => submitHelpdeskTicket()),
              variant: 'primary',
              isLoading:
                helpdeskTicketTransmissionState ===
                HELPDESKTICKET_TRANSMISSION_STATES.transmitting
            }
          ]}
        />
      </div>
    </form>
  )
}

export default HelpdeskForm
