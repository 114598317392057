import { Dialog } from '@headlessui/react'

/**
 * This Component can be used to provide a description for the modal, which is linked with the proper a11y attributes.
 *
 * See ConfirmModal for an example of how to use it.
 */
export const ModalDescription = Dialog.Description

export { Modal as default } from './Modal'
