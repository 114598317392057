import React from 'react'

import Heading2 from '@uiLibrary/typography/Heading2'

import { useI18n } from '../../../../src/i18n'

import styles from './ExplanatoryVideo.module.scss'

export const explanatoryVideosList = {
  /* eslint-disable @typescript-eslint/naming-convention */
  employee_intro: {
    id: 'employee_intro',
    label: 'pages.pageExplanatoryVideos.videos.employeeIntro',
    url: 'https://player.vimeo.com/video/425891896'
  },
  employee_order_create: {
    id: 'employee_order_create',
    label: 'pages.pageExplanatoryVideos.videos.employeeOrderCreate',
    url: 'https://player.vimeo.com/video/145260456'
  },
  manager_intro: {
    id: 'manager_intro',
    label: 'pages.pageExplanatoryVideos.videos.managerIntro',
    url: 'https://player.vimeo.com/video/425849151'
  },
  manager_order_create: {
    id: 'manager_order_create',
    label: 'pages.pageExplanatoryVideos.videos.managerOrderCreate',
    url: 'https://player.vimeo.com/video/431464994'
  },
  manager_order_edit: {
    id: 'manager_order_edit',
    label: 'pages.pageExplanatoryVideos.videos.managerOrderEdit',
    url: 'https://player.vimeo.com/video/145257310'
  },
  manager_ivo: {
    id: 'manager_ivo',
    label: 'pages.pageExplanatoryVideos.videos.mangerIvo',
    url: 'https://player.vimeo.com/video/250591351'
  }
} as const

export type VideoIds = keyof typeof explanatoryVideosList

type ExplanatoryVideoProps = {
  videoId: VideoIds
}

const ExplanatoryVideo = React.forwardRef<
  HTMLHeadingElement,
  ExplanatoryVideoProps
>(({ videoId }, ref) => {
  const { t } = useI18n()

  const video = explanatoryVideosList[videoId]

  const videoFrameTitle = t('pages.pageExplanatoryVideos.videoFrameTitle', {
    videoTitle: t(video.label)
  })

  return (
    <div>
      <Heading2 ref={ref} className={styles['title']}>
        {t(video.label)}
      </Heading2>
      <iframe
        aria-label={videoFrameTitle}
        title={videoFrameTitle}
        className={styles['video']}
        src={video.url}
        width="100%"
        height="100%"
        allowFullScreen
      ></iframe>
    </div>
  )
})

ExplanatoryVideo.displayName = 'ExplanatoryVideo'
export default ExplanatoryVideo
