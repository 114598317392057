import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import { OrdersSlice } from '../slices/orders'
import { buildRejectValue, createApiAsyncThunk } from '../apiAsyncThunk'
import { OrderDetail, orderDetailEndpoint, OrderEdit } from '../../api/order'

import { fetchOrderMessages } from './fetchOrderMessages'
import { fetchOrderDocuments } from './fetchOrderDocuments'

export type PatchOrderDetailArgument = {
  orderId: number
  data: OrderEdit
}

export const patchOrderDetail = createApiAsyncThunk<
  OrderDetail,
  PatchOrderDetailArgument
>('orders/patchOrderDetail', async (arg, thunkApi) => {
  const response = await fetch(orderDetailEndpoint(arg.orderId), {
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      accept: 'application/json',
      'content-type': 'application/json',
      'x-csrf-token': selectCsrfToken(thunkApi.getState())
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    method: 'PATCH',
    body: JSON.stringify(arg.data)
  })

  const responseBody = await response.json()

  if (response.ok) {
    await thunkApi.dispatch(fetchOrderMessages(arg.orderId))
    if (arg.data.portal_state?.new_state === 'sent') {
      /*
       * The backend may have new documents for us (in particular,
       * documents of type `info` such as "merkblatt-jobrad-inspektion.pdf").
       */
      await thunkApi.dispatch(fetchOrderDocuments(arg.orderId))
    }
  } else {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return responseBody
})

export const addPatchOrderDetailFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(patchOrderDetail.fulfilled, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        pending: false,
        error: undefined,
        data: action.payload
      }
    }
  }))
}

export const addPatchOrderDetailPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(patchOrderDetail.pending, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        ...state.details[action.meta.arg.orderId],
        pending: true
      }
    }
  }))
}

export const addPatchOrderDetailRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(patchOrderDetail.rejected, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        ...state.details[action.meta.arg.orderId],
        pending: false
        // We don't need the error state in this case.
      }
    }
  }))
}
