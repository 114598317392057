import { SlugAndAccessCode } from '@context/useAccessInfo'

export const addAccessCodeSlug = (
  accessInfo: SlugAndAccessCode,
  uri = ''
): string => {
  const inner = uri === '' ? '/' : `/${uri.replace(/^\/|\/$/g, '')}/`
  return `/${accessInfo.slug}${inner}${accessInfo.accessCode}`
}

export const composeLogoutUri = (
  accessInfo: SlugAndAccessCode,
  redirectURL?: string
): string => {
  const url = addAccessCodeSlug(accessInfo, 'logout')
  if (!redirectURL) return url
  return `${url}?redirect=${redirectURL}`
}

export function getParameterByName(name: string): string | null {
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(name)
}

export function removeParameterByName(name: string): void {
  const searchParams = new URLSearchParams(window.location.search)
  if (!searchParams.has(name)) return

  // compute new path
  searchParams.delete(name)
  const search = searchParams.toString()
  const path =
    window.location.pathname +
    (search ? `?${search}` : '') +
    window.location.hash

  // replace path
  history.replaceState(null, '', path)
}

type URLParameterMap = {
  [param: string]: string | number | boolean | undefined
}

/**
 * Convert an object `{ key1: value1, key2: value2 }` into a URL query parameter list `key1=value&key2=value2`.
 * The object's values get URL-encoded, and keys that have `undefined` as value get filtered out.
 *
 * TODO:
 *   1. Delete this function.
 *   2. Remove all calls to this function -> instead use (the native JS class) `URLSearchParams`
 *   3. Remove all calls to `encodeURIComponent` -> instead use `URLSearchParams`
 *   4. Find a way to ensure the use of `URLSearchParams` will not be forgotten when adding new API calls, because
 *      it has already happened several times that search params were appended to URLs without URL-encoding.
 */
export const buildParameterString = (parameterMap: URLParameterMap): string => {
  return Object.entries(parameterMap)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) =>
      value === undefined
        ? '' // Make TypeScript happy
        : `${key}=${encodeURIComponent(value)}`
    )
    .join('&')
}

export const addParameterStringToPath = (
  path: string,
  parameters: string
): string => {
  if (parameters === '') return path
  return `${path}?${parameters}`
}

export const EMAIL_PATTERN = new RegExp(
  "[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\\.)+[a-z](?:[a-z0-9-]*[a-z0-9])?"
)

// This pattern accepts rather too much. Change it once this becomes a problem.
export const WEBSITE_PATTERN = new RegExp(
  '([https://]|[http://]|[www.])*([a-z0-9])+[.]([a-z0-9-]+[a-z0-9])'
)
