import React, { FunctionComponent, useCallback, useState } from 'react'
import { useCookies } from 'react-cookie'

import AppInfoAlert from '../AppInfoAlert/AppInfoAlert'
import { useI18n } from '../../../i18n'

import PreviewModeModal from './PreviewModeModal/PreviewModeModal'

const cookie = {
  name: 'pm',
  value: 'closed',
  options: {
    path: '/',
    sameSite: 'lax',
    maxAge: 7 * 24 * 3600
  }
}

const PreviewModeMessages: FunctionComponent = () => {
  const { t } = useI18n()
  const [cookies, setCookie] = useCookies([cookie.name])
  const [modalOpenedByUser, setModalOpenedByUser] = useState(false)

  const handleOpenModal = useCallback(
    (): void => setModalOpenedByUser(true),
    [setModalOpenedByUser]
  )

  const handleCloseModal = useCallback((): void => {
    setCookie(cookie.name, cookie.value, cookie.options)
    setModalOpenedByUser(false)
  }, [setCookie, setModalOpenedByUser])

  const validCookieSet = cookies[cookie.name] === cookie.value
  const modalOpen = modalOpenedByUser || !validCookieSet

  return (
    <>
      <AppInfoAlert
        infoText={t('previewMode.title')}
        handleOpenModal={handleOpenModal}
      />
      <PreviewModeModal open={modalOpen} onClose={handleCloseModal} />
    </>
  )
}

export default PreviewModeMessages
export { cookie as previewModeCookieContract }
