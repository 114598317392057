import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { useTypedDispatch, useTypedSelector } from '@redux'
import { selectErrorAndPendingAwareNewsletterSettings } from '@redux/slices/newsletterSettings'
import { fetchNewsletterSettings } from '@redux/api/fetchNewsletterSettings'

import styles from './LastFooterColumn.module.scss'
import NewsletterFooterContent from './NewsletterFooterContent'
import NewsletterModal from './NewsletterModal'
import JobradInfoFooterContent from './JobradInfoFooterContent/JobradInfoFooterContent'
import FooterContent from './FooterContent'

const LastFooterColumn: React.FunctionComponent = () => {
  const dispatch = useTypedDispatch()
  const {
    data: newsletterSettings,
    pending: fetchIsPending,
    error: fetchError
  } = useTypedSelector(selectErrorAndPendingAwareNewsletterSettings)
  const [newsletterModalIsOpen, setNewsletterModalIsOpen] = useState(false)

  useEffect(() => {
    if (newsletterSettings === undefined) dispatch(fetchNewsletterSettings())
  }, [dispatch, newsletterSettings])

  const userIsSubscribedToAllNewsletters =
    newsletterSettings && newsletterSettings.subscribed_to_all_newsletters
  const newsLetterClassName = userIsSubscribedToAllNewsletters
    ? styles.right
    : styles.newsletter

  return (
    <>
      <div className={classNames(newsLetterClassName, styles.lastFooterColumn)}>
        <NewsletterModal
          open={newsletterModalIsOpen}
          onClose={() => setNewsletterModalIsOpen(false)}
        />
        {userIsSubscribedToAllNewsletters || fetchError || fetchIsPending ? (
          <JobradInfoFooterContent />
        ) : (
          <NewsletterFooterContent
            onButtonClick={() => setNewsletterModalIsOpen(true)}
          />
        )}
      </div>
      <FooterContent />
    </>
  )
}

export default LastFooterColumn
