import React from 'react'
import { Link as RouterLink } from 'react-router-dom'
import className from 'classnames'
import type { Location } from 'react-router'

import type { LinkIconName } from '../Link'
import { useI18n } from '../../../../i18n'
import { Icon } from '../../../Icon/Icon'

import styles from './LinkInner.module.scss'

type Props = {
  linkText: string
  to: string
  state: Location['state'] | null
  reloadDocument: boolean
  id: string | undefined
  small: boolean | undefined
  reverse: boolean | undefined
  openInNewTab: boolean | undefined
  iconName: LinkIconName | undefined
}
const LinkInner: React.FunctionComponent<Props> = ({
  linkText,
  to,
  state,
  reloadDocument,
  id,
  small,
  reverse,
  iconName,
  openInNewTab
}) => {
  const { t } = useI18n()

  const linkClassName = className(
    small ? styles['linkSmall'] : styles['linkNormal'],
    reverse ? styles['linkReverse'] : ''
  )
  return (
    <RouterLink
      to={to}
      state={state}
      className={linkClassName}
      reloadDocument={reloadDocument}
      id={id}
      target={openInNewTab ? '_blank' : undefined}
      rel={openInNewTab ? 'noopener noreferrer' : undefined}
    >
      {iconName && (
        <span className={styles.icon}>
          <Icon variant="normal" iconName={iconName} color="green" />
        </span>
      )}
      <span>{linkText}</span>
      {openInNewTab && (
        <span className="sr-only">
          {t('uiLibrary.link.newTabScreenReaderText')}
        </span>
      )}
    </RouterLink>
  )
}

export default LinkInner
