/*
    Returns an array if the passed const array contains
    all elements from the template union type and is readonly.
    Otherwise typescript will throw an exception.
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const getArrayFromUnionType =
  <T>() =>
  <U>(
    array: U &
      (U extends readonly T[]
        ? [T] extends [U[number]]
          ? unknown
          : 'The given array does not fit the given union type or is not readonly.'
        : 'The given array does not fit the given union type or is not readonly.')
  ) =>
    array

// idea from https://www.benmvp.com/blog/filtering-undefined-elements-from-array-typescript/
export const filterUndefined = <T>(data: Array<T | undefined>): T[] =>
  data.filter((item): item is T => item !== undefined)

/* recover known keys from Types which include a mapped index type with keys string | number.
See https://github.com/microsoft/TypeScript/issues/25987#issuecomment-441224690 and
https://github.com/Microsoft/TypeScript/issues/12215#issuecomment-414782407 for background
examples, and explanation. Quick example below:

type Test =  {
  id?: string
  test: boolean
  // to allow third party extensions
  [k: string]: any
}
keyof Test == string | number
KnownKeys<Test> == 'id' | 'test'
 */
type KnownKeys<T> = {
  [K in keyof T]: string extends K ? never : number extends K ? never : K
} extends { [_ in keyof T]: infer U }
  ? unknown extends U
    ? never
    : U
  : never

// Omit using KnownKeys instead of keyof
export type OmitFromKnownKeys<T, Keys> = Pick<T, Exclude<KnownKeys<T>, Keys>>

export const undefinedToNull = <T>(data: T | undefined): T | null =>
  data !== undefined ? data : null

/**
 Create a type from an object type where certain keys can be set to `Null`.
 */
export type SetAllowsNull<T, KeysWhichAllowNull extends keyof T> = {
  [K in keyof T]: K extends KeysWhichAllowNull ? T[K] | null : T[K]
}

/**
 *  Sets the type of specific keys of an object to a specified one.
 *
 *  Example:
 * ```
 * type A = { foo: string, bar: string, test: boolean }
 * type B = PartialTypeChange<A, 'foo' | 'test', number>
 * ==>  B = { foo: number, bar: string, test: number }
 * ```
 */

export type PartialTypeChange<
  ObjectType,
  KeysWhoseTypeShouldBeChanged extends keyof ObjectType,
  NewType
> = {
  [KeyType in keyof ObjectType]: KeyType extends KeysWhoseTypeShouldBeChanged
    ? NewType
    : ObjectType[KeyType]
}

/**
 *  Makes all keys T required, but allows undefined for keys which were previously optional.
 *
 *  Example:
 * ```
 * type A = { foo: string, bar?: string, test?: boolean }
 * type B = RequiredWithUndefinedOnOptionalKeys<A>
 * ==>  B = { foo: string, bar: string | undefined, test: boolean | undefined }
 * ```
 */
export type RequiredWithUndefinedOnOptionalKeys<T> = {
  [K in keyof Required<T>]: T[K]
}
