import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgArrowLeft: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M12 8C12.5523 8 13 7.55228 13 7C13 6.44772 12.5523 6 12 6H4.41421L6.70711 3.70711C7.09763 3.31658 7.09763 2.68342 6.70711 2.29289C6.31658 1.90237 5.68342 1.90237 5.29289 2.29289L1.29289 6.29289C0.902369 6.68342 0.902369 7.31658 1.29289 7.70711L5.29289 11.7071C5.68342 12.0976 6.31658 12.0976 6.70711 11.7071C7.09763 11.3166 7.09763 10.6834 6.70711 10.2929L4.41421 8L12 8Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgArrowLeft
