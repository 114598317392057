import React from 'react'
import { Menu } from '@headlessui/react'

import { useTypedLocation } from '@hooks/useTypedLocation'

import { NavigationItem } from '../NavItem'

import MenuButton from './MenuButton'
import MenuItems from './MenuItems'
import styles from './Dropdown.module.scss'

export type ItemGroup = {
  title?: string
  items: NavigationItem[]
}

type DropdownProps = {
  label?: string
  itemGroups: ItemGroup[]
  variant: 'overflow' | 'menu'
}

export const Dropdown: React.FunctionComponent<DropdownProps> = ({
  label,
  itemGroups,
  variant
}) => {
  const navigationTargets = itemGroups.flatMap((part) =>
    part.items.map((value) => value.navigationTarget)
  )
  const { pageName } = useTypedLocation()
  const match = navigationTargets.find((navigationTarget) =>
    navigationTarget.internal ? navigationTarget.pageName === pageName : false
  )

  return (
    <Menu as="div" className={styles.dropdown}>
      {({ open }) => (
        <>
          <MenuButton
            variant={variant}
            label={label}
            open={open}
            selected={variant === 'overflow' && !!match}
          />

          <MenuItems variant={variant} isShown={open} itemGroups={itemGroups} />

          {open && (
            <div aria-hidden className={styles['dropdown-backdrop']}></div>
          )}
        </>
      )}
    </Menu>
  )
}
