import React, { useEffect } from 'react'
import { Outlet, useParams } from 'react-router-dom'

import { useTypedDispatch, useTypedSelector } from '@redux'
import {
  selectNonUnauthorizedFetchSessionError,
  selectSession
} from '@redux/slices/session'
import { fetchPortalSession } from '@redux/api/fetchPortalSession'
import { LoadingSpinner } from '@uiLibrary/LoadingSpinner'
import ErrorModal from '@uiBuildingBlocks/overlays/ErrorModal/ErrorModal'
import { SessionContext } from '@context/SessionContext'
import PageRedirect from '@pages/PageRedirect/PageRedirect'
import { SlugAndAccessCode } from '@context/useAccessInfo'
import PageNotFound from '@pages/PageNotFound/PageNotFound'

import i18n, { useI18n } from '../../../i18n'

/**
 * This function gets the accessInfo from the currently matched route.
 *
 * It expects the slug in the ":slug" param, and the "accessCode" in the ":accessCode" param, or to be the last
 * part in the "*" param (in order to be able to handle 404 pages).
 *
 * If no acessInfo is found in the current route, undefined is returned.
 */
const useGetAccessInfoFromRoute = (): SlugAndAccessCode | undefined => {
  const { slug, accessCode, '*': catchAll } = useParams()
  if (slug === undefined) return
  if (accessCode !== undefined) return { slug, accessCode }

  // try to get the accessCode from the "*" catchAll parameter to handle the 404 pages correctly
  if (catchAll === undefined) return
  const parts = catchAll.split('/')
  return { slug, accessCode: parts[parts.length - 1] }
}

/**
 * This Route component implements the session handling/fetching for the portal pages, including the handling of a
 * non-matching access code and/or slug.
 *
 * It ensures that a session is present. If the slug/access code matches the session, it renders its respective
 * sub-route. If not, it renders the redirect page, which redirects to the matching login page. If there is no
 * accessCode/slug present, the 404 Page is rendered (we don't know which login page to redirect to).
 *
 */
export const SessionRoute: React.FunctionComponent = () => {
  const { t } = useI18n()

  const dispatch = useTypedDispatch()
  const authState = useTypedSelector((state) => state.app.authState)
  const session = useTypedSelector(selectSession)
  const error = useTypedSelector(selectNonUnauthorizedFetchSessionError)

  const accessInfoFromRoute = useGetAccessInfoFromRoute()

  // load session in beginning
  useEffect(() => {
    if (authState !== undefined) return
    dispatch(fetchPortalSession())
      .unwrap()
      .then((session) => {
        if (!session.contract.use_public_service_naming) return
        i18n.changeLanguage('landbw').then()
      })
      .catch(() => undefined)
  }, [dispatch, authState])

  // handle session loading failed
  if (error) {
    return (
      <ErrorModal
        errorMessage={t('sessionFetchErrorMessage')}
        fetchError={error}
      />
    )
  }

  // handle session still loading
  if (session === undefined && authState === undefined)
    return <LoadingSpinner />

  // handle no access info in URL
  if (accessInfoFromRoute == undefined) return <PageNotFound />

  // handle unauthorized
  if (
    session === undefined ||
    accessInfoFromRoute.accessCode !== session.contract.access_code ||
    accessInfoFromRoute.slug !== session.contract.slug
  ) {
    return <PageRedirect accessInfo={accessInfoFromRoute} />
  }

  return (
    <SessionContext.Provider value={session}>
      <Outlet />
    </SessionContext.Provider>
  )
}
