import { Menu } from '@headlessui/react'
import React, { Fragment, useEffect } from 'react'

import { ItemGroup } from '../Dropdown'
import MenuItem from '../MenuItem'

import styles from './MenuItems.module.scss'

type Props = {
  itemGroups: ItemGroup[]
  isShown: boolean
  variant: 'overflow' | 'menu'
}

export const MenuItems: React.FunctionComponent<Props> = ({
  itemGroups,
  isShown
}) => {
  useEffect(() => {
    // A class will be added to the html tag when the full-screen
    // mobile navigation dropdown is shown in order to avoid that the application
    // is scrollable when it is not visible
    // headless-ui does it like this as well
    const root = document.getElementsByTagName('html')[0] // '0' to assign the first (and only `HTML` tag)

    if (isShown) {
      root.classList.add('lock-document-scroll-mobile')
    } else {
      root.classList.remove('lock-document-scroll-mobile')
    }
  }, [isShown])

  return (
    <Menu.Items className={styles.itemContainer}>
      {itemGroups.map((itemGroup, index) => (
        <Fragment key={index}>
          {itemGroup.title && (
            <h2
              id={`heading-${itemGroup.title}`}
              // It would be better not to have any role, however headless-ui sets role="none" if no role is specified and that is worse
              role={'heading'}
              aria-level={2}
            >
              {itemGroup.title}
            </h2>
          )}

          <ul
            aria-describedby={
              itemGroup.title ? `${itemGroup.title}-heading` : undefined
            }
            role="group"
            className={styles.menuGroup}
          >
            {itemGroup.items.map((item) => (
              <MenuItem
                key={item.label}
                navigationTarget={item.navigationTarget}
                label={item.label}
                withExternalIndicatorIcon={item.withExternalIndicatorIcon}
              />
            ))}
          </ul>
        </Fragment>
      ))}
    </Menu.Items>
  )
}
