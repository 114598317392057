import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgInfo: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 7C0 3.13541 3.13428 0 7 0C10.8657 0 14 3.13541 14 7C14 10.8669 10.8657 14 7 14C3.13428 14 0 10.8669 0 7ZM6.99999 2.5484C7.74825 2.5484 8.35483 3.15498 8.35483 3.90324C8.35483 4.6515 7.74825 5.25808 6.99999 5.25808C6.25174 5.25808 5.64516 4.6515 5.64516 3.90324C5.64516 3.15498 6.25174 2.5484 6.99999 2.5484ZM8.80645 10.7419C8.80645 10.9557 8.63312 11.129 8.41935 11.129H5.58064C5.36687 11.129 5.19354 10.9557 5.19354 10.7419V9.96776C5.19354 9.75398 5.36687 9.58066 5.58064 9.58066H5.96774V7.51614H5.58064C5.36687 7.51614 5.19354 7.34282 5.19354 7.12905V6.35485C5.19354 6.14108 5.36687 5.96776 5.58064 5.96776H7.64515C7.85893 5.96776 8.03225 6.14108 8.03225 6.35485V9.58066H8.41935C8.63312 9.58066 8.80645 9.75398 8.80645 9.96776V10.7419Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgInfo
