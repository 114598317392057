import React from 'react'
import { Button } from 'react-bootstrap'

import JobradModal from '../JobradModal/JobradModal'
import { FetchError } from '../../../redux/apiAsyncThunk'
import ErrorDebugInfo from '../../feedback/error/ErrorDebugInfo/ErrorDebugInfo'
import { useI18n } from '../../../i18n'

import styles from './ErrorModal.module.scss'

type Props = {
  errorMessage: string
  fetchError?: FetchError
  indefiniteError?: Error
}

/**
 * @deprecated New component will be built in https://jira.dev.jobrad.org/browse/A11Y-939
 */
const ErrorModal: React.FunctionComponent<Props> = ({
  errorMessage,
  fetchError,
  indefiniteError
}) => {
  const { t } = useI18n()
  const onClickReloadButton = (): void => {
    window.location.reload()
  }
  return (
    <JobradModal
      className={styles.errorModal}
      show={true}
      displayDivider={false}
      onHide={() => false}
      header={<p>Hier ging was schief, die Luft ist raus, das tut uns leid!</p>}
      footer={
        <Button variant="link" onClick={onClickReloadButton}>
          Seite neu laden
        </Button>
      }
      enforceFocus={false}
    >
      <div className={styles.body}>
        <p>{errorMessage}</p>
        <p>
          {t(
            'uiBuildingBlocks.feedback.error.connectivityTroubleshootingMessage'
          )}
        </p>
        <ErrorDebugInfo
          fetchError={fetchError}
          indefiniteError={indefiniteError}
        />
        {/* todo: add image of broken bike or smt like this */}
      </div>
    </JobradModal>
  )
}

export default ErrorModal
