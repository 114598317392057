import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgServiceFull: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.4598 2.73513C14.4851 2.71421 14.5095 2.69202 14.533 2.66851C15.065 2.13647 14.901 1.24032 14.1614 1.10183C12.3006 0.753415 10.3054 1.29906 8.86568 2.73878C7.33924 4.26521 6.81903 6.41573 7.30056 8.365C7.31317 8.41604 7.31106 8.45819 7.30524 8.48369C7.3021 8.49744 7.2934 8.50883 7.28342 8.5188L1.86939 13.9328C0.710205 15.092 0.710204 16.9714 1.86939 18.1306C3.02857 19.2898 4.90798 19.2898 6.06716 18.1306L11.4812 12.7166C11.4912 12.7066 11.5026 12.6979 11.5163 12.6948C11.5418 12.6889 11.584 12.6868 11.635 12.6994C13.5843 13.181 15.7348 12.6608 17.2612 11.1343C18.7009 9.69461 19.2466 7.6994 18.8982 5.83862C18.7597 5.09905 17.8635 4.93498 17.3315 5.46703C17.308 5.49053 17.2858 5.51495 17.2649 5.54019L17.2616 5.53691L15.86 6.93845C15.4737 7.32484 14.8472 7.32484 14.4608 6.93845L13.0615 5.53919C12.6751 5.1528 12.6751 4.52633 13.0615 4.13993L14.4631 2.73839L14.4598 2.73513ZM10.2649 4.13803C9.24869 5.15428 8.89944 6.58603 9.22166 7.89043C9.38122 8.53633 9.27054 9.33021 8.69894 9.9018L3.26865 15.3321C2.88225 15.7185 2.88225 16.345 3.26865 16.7314C3.65504 17.1177 4.28151 17.1177 4.6679 16.7314L10.0982 11.3011C10.6698 10.7295 11.4637 10.6188 12.1096 10.7783C13.414 11.1006 14.8457 10.7513 15.862 9.73506C16.1218 9.47521 16.338 9.18822 16.5105 8.88321C15.3952 9.45289 13.9949 9.27106 13.0615 8.33771L11.6623 6.93845C10.7289 6.00511 10.5471 4.60485 11.1167 3.48957C10.8118 3.66204 10.5248 3.8782 10.2649 4.13803Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgServiceFull
