import { useContext } from 'react'

import { Session } from '@api/session'

import { SessionContext } from './SessionContext'

export const useSession: () => Session = () => {
  const session = useContext(SessionContext)
  if (session === null) {
    throw Error('No SessionContext.Provider is present in the component tree.')
  }

  return session
}
