import React from 'react'

import ErrorBox from '@uiLibrary/ErrorBox'
import { FetchError } from '@redux/apiAsyncThunk'

import { ConnectivityTroubleshootingMessage } from './ConnectivityTroubleshootingMessage'
import { ServerCommunicationErrorContentBase } from './ServerCommunicationErrorContentBase'

type ServerCommunicationErrorContentProps = {
  title: string
  message?: string
  body?: string
  error: FetchError
}

/**
 * This component shows an Error message for when the API replied with an error. A generic error text is shown,
 * unless the user provides a more specific text via the `body` prop.
 *
 * In the `DEBUG` environment variable is set, the error is shown as JSON-like object to aid development.
 */
export const ServerCommunicationErrorContent: React.FunctionComponent<
  ServerCommunicationErrorContentProps
> = ({ title, message, body, error }) => {
  return (
    <ErrorBox
      ariaLive="assertive"
      errorMessage={
        <ServerCommunicationErrorContentBase title={title} error={error}>
          {body ? (
            <span>{body}</span>
          ) : (
            <ConnectivityTroubleshootingMessage message={message} />
          )}
        </ServerCommunicationErrorContentBase>
      }
    />
  )
}
