import { createSlice } from '@reduxjs/toolkit'

import { Country } from '../../api/country'
import { RootState, ErrorAndPendingAwareState } from '../index'
import {
  addFetchCountriesFulfilledCase,
  addFetchCountriesPendingCase,
  addFetchCountriesRejectedCase
} from '../api/fetchCountries'

export type CountriesList = ErrorAndPendingAwareState<Country[]>

export type CountriesSlice = {
  list: CountriesList
}

export const initialState: CountriesSlice = {
  list: {
    pending: false
  }
}

const countriesSlice = createSlice({
  name: 'countries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchCountriesPendingCase(builder)
    addFetchCountriesFulfilledCase(builder)
    addFetchCountriesRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareCountries = (
  state: RootState
): ErrorAndPendingAwareState<Country[]> => state.countries.list

export default countriesSlice.reducer
