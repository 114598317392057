import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import {
  fetchNewsletterSettingsEndpoint,
  NewsletterSettings
} from '@api/newsletterSettings'
import { NewsletterSettingsSlice } from '@redux/slices/newsletterSettings'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'

export const fetchNewsletterSettings = createApiAsyncThunk<NewsletterSettings>(
  'fetchNewsletterSettings',
  async (_, thunkApi) => {
    const response = await fetch(fetchNewsletterSettingsEndpoint, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      signal: thunkApi.signal
    })
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody, [404]))
    }

    return responseBody
  }
)

export const addFetchNewsletterSettingsPendingCase = (
  builder: ActionReducerMapBuilder<NewsletterSettingsSlice>
): void => {
  builder.addCase(fetchNewsletterSettings.pending, (state) => ({
    ...state,
    pending: true
  }))
}

export const addFetchNewsletterSettingsFulfilledCase = (
  builder: ActionReducerMapBuilder<NewsletterSettingsSlice>
): void => {
  builder.addCase(fetchNewsletterSettings.fulfilled, (_, action) => ({
    error: undefined,
    pending: false,
    data: action.payload
  }))
}

export const addFetchNewsletterSettingsRejectedCase = (
  builder: ActionReducerMapBuilder<NewsletterSettingsSlice>
): void => {
  builder.addCase(fetchNewsletterSettings.rejected, (state, action) => ({
    ...state,
    pending: false,
    data: undefined,
    error: buildFetchError(action)
  }))
}
