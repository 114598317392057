import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import { OrderList, OrdersSlice } from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { OrderDetail, orderDetailEndpoint } from '../../api/order'

export const setOrderAsReadIfExists = (
  list: OrderList,
  orderId: number
): OrderList => {
  if (!list.data) return list
  const index = list.data.page.findIndex((x) => x.id === orderId)
  if (index === -1) return list
  return {
    ...list,
    data: {
      ...list.data,
      page: [
        ...list.data.page.slice(0, index),
        {
          ...list.data.page[index],
          // eslint-disable-next-line @typescript-eslint/naming-convention
          read_by_manager: true
        },
        ...list.data.page.slice(index + 1)
      ]
    }
  }
}

export const fetchOrderDetail = createApiAsyncThunk<OrderDetail, number>(
  'orders/fetchOrderDetail',
  async (orderId, thunkApi) => {
    const response = await fetch(orderDetailEndpoint(orderId), {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOrderDetailFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDetail.fulfilled, (state, action) => ({
    ...state,
    list: setOrderAsReadIfExists(state.list, action.payload.id),
    details: {
      ...state.details,
      [action.meta.arg]: {
        pending: false,
        error: undefined,
        data: action.payload
      }
    }
  }))
}

export const addFetchOrderDetailPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDetail.pending, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg]: {
        ...state.details[action.meta.arg],
        pending: true
      }
    }
  }))
}

export const addFetchOrderDetailRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDetail.rejected, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg]: {
        data: undefined,
        pending: false,
        error: buildFetchError(action)
      }
    }
  }))
}
