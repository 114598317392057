import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgDownload: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 16 16"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 1a1 1 0 0 1 1 1v5.586l1.293-1.293a1 1 0 1 1 1.414 1.414l-3 3a1 1 0 0 1-1.414 0l-3-3a1 1 0 0 1 1.414-1.414L7 7.586V2a1 1 0 0 1 1-1M2 15a.999.999 0 0 1-1-1v-2a1 1 0 1 1 2 0v1h10v-1a1 1 0 1 1 2 0v2a1 1 0 0 1-1 1z"
      clipRule="evenodd"
    />
  </svg>
)
export default SvgDownload
