import React, { useRef } from 'react'

import { useI18n } from '../../../i18n'
import Dropdown, { ItemGroup } from '../../Header/Dropdown'
import NavItem, { NavigationItem } from '../NavItem'

import styles from './HeaderLayout.module.scss'
import { usePriorityNavigation } from './usePriorityNavigation'

export type HeaderLogo = {
  source: string
  linkTo: string
}

type HeaderLayoutProps = {
  navigationItems?: NavigationItem[]
  menuItems?: ItemGroup[]
  logo: HeaderLogo
  menuLabel?: string
  isManagerHeader: boolean
  companyName?: string
}

export const HeaderLayout: React.FunctionComponent<HeaderLayoutProps> = ({
  navigationItems,
  menuItems,
  menuLabel,
  logo,
  isManagerHeader,
  companyName
}) => {
  const visibleLinkListRef = useRef<HTMLUListElement>(null)
  const imageRef = useRef<HTMLAnchorElement>(null)
  const leftItemsRef = useRef<HTMLDivElement>(null)

  const { t } = useI18n()

  const { navItems, overflowItems } = usePriorityNavigation({
    navigationItems,
    visibleLinkListRef,
    imageRef,
    leftItemsRef
  })

  const overflowItemsAsMenuParts: ItemGroup[] = overflowItems.map((item) => ({
    items: [item]
  }))

  const logoAlt = companyName
    ? t('navigation.companyLogo', { companyName: companyName })
    : t('navigation.jobRadLogo')

  return (
    <div
      className={
        // remove managerHeader-styles after re-design of manager pages
        isManagerHeader ? styles['managerHeader'] : styles['header']
      }
    >
      <div ref={leftItemsRef} className={styles['left-items']}>
        <a ref={imageRef} href={logo.linkTo} rel="noopener noreferrer">
          <img src={logo.source} alt={logoAlt} />
        </a>

        {navItems && navItems.length > 0 && (
          <nav aria-label={t('navigation.navigationAriaLabel')}>
            <ul ref={visibleLinkListRef}>
              {navItems.map((item) => {
                if (navigationItems) {
                  return (
                    <NavItem
                      key={item.label}
                      navigationTarget={item.navigationTarget}
                      label={item.label}
                    />
                  )
                }
              })}
              {overflowItemsAsMenuParts.length > 0 && (
                <Dropdown
                  label={t('navigation.overflowButton')}
                  variant="overflow"
                  itemGroups={overflowItemsAsMenuParts}
                ></Dropdown>
              )}
            </ul>
          </nav>
        )}
      </div>

      {menuItems && (
        <Dropdown
          label={menuLabel}
          variant="menu"
          itemGroups={menuItems}
        ></Dropdown>
      )}
    </div>
  )
}
