import React, { ErrorInfo } from 'react'
import * as Sentry from '@sentry/react'
import { withTranslation, WithTranslation } from 'react-i18next'

import ErrorModal from '../../uiBuildingBlocks/overlays/ErrorModal/ErrorModal'

type Props = WithTranslation & {
  children: React.ReactNode
}
type State = {
  error?: Error
  errorInfo?: ErrorInfo
}

class JobradErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {}
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    this.setState({
      error: error,
      errorInfo: errorInfo
    })
  }

  render(): React.ReactNode {
    return (
      <Sentry.ErrorBoundary
        fallback={({ error, componentStack }) => {
          const indefiniteError = {
            name: error.name,
            message: error.message,
            stack: componentStack || error.stack
          }

          return (
            <ErrorModal
              errorMessage={this.props.t('errorBoundaryErrorMessage')}
              indefiniteError={indefiniteError}
            />
          )
        }}
      >
        {this.props.children}
      </Sentry.ErrorBoundary>
    )
  }
}

export default withTranslation()(JobradErrorBoundary)
