import { SlugAndAccessCode } from '@context/useAccessInfo'
import { Session } from '@api/session'
import { composeLogoutUri } from '@misc/uri'

import i18n from '../../i18n'

import { ItemGroup } from './Dropdown'
import { NavigationItem } from './NavItem'
import { getEmployeeSectionForDropdownItems } from './navItemsEmployee'

export const getManagerNavigationItems = (
  accessInfo: SlugAndAccessCode,
  session: Session
): NavigationItem[] => {
  const { access_rights: accessRights, contract } = session

  const managerNavigationItems: NavigationItem[] = []
  accessRights.portal_manager_dashboard &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.managerOverview')}`,
      navigationTarget: {
        internal: false,
        location: `/${contract.slug}/manager/${contract.access_code}`
      }
    })
  accessRights.portal_manager &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.employeeOrders')}`,
      navigationTarget: {
        internal: true,
        pageName: 'Manager Order List',
        context: { event: null }
      }
    })
  accessRights.portal_manager_edit_reports &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.contractEditor')}`,
      navigationTarget: {
        internal: true,
        pageName: 'Manager Transfer Contract'
      }
    })
  accessRights.portal_manager_settings &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.managerSettings')}`,
      navigationTarget: {
        internal: false,
        location: `/${contract.slug}/manager/settings/${contract.access_code}`
      }
    })
  accessRights.portal_manager_hr_reports &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.managerHrReports')}`,
      navigationTarget: {
        internal: false,
        location: `/${accessInfo.slug}/manager/hr_reports/${accessInfo.accessCode}`
      }
    })
  accessRights.portal_manager_self_reports &&
    managerNavigationItems.push({
      label: `${i18n.t('navigation.managerReports')}`,
      navigationTarget: {
        internal: true,
        pageName: 'Manager Reports'
      }
    })

  return managerNavigationItems
}

const getManagerSectionForDropdownItems = (
  accessInfo: SlugAndAccessCode,
  session: Session
): NavigationItem[] => {
  const managerSectionItems: NavigationItem[] = getManagerNavigationItems(
    accessInfo,
    session
  )

  session.contract.manager_faq_url &&
    managerSectionItems.push({
      label: `${i18n.t('navigation.managerFaq')}`,
      navigationTarget: {
        internal: false,
        location: session.contract.manager_faq_url
      },
      withExternalIndicatorIcon: true
    })

  return managerSectionItems
}

export const getManagerDropdownItems = (
  accessInfo: SlugAndAccessCode,
  session: Session
): ItemGroup[] => {
  return [
    {
      title: `${i18n.t('navigation.managerLinks')}`,
      items: getManagerSectionForDropdownItems(accessInfo, session)
    },
    {
      title: `${i18n.t('navigation.employeeLinks')}`,
      items: getEmployeeSectionForDropdownItems(session)
    },
    {
      items: [
        {
          label: `${i18n.t('navigation.logout')}`,
          navigationTarget: {
            internal: false,
            location: composeLogoutUri(
              accessInfo,
              session.contract.portal_settings.ext_portal_url
            )
          }
        }
      ]
    }
  ]
}
