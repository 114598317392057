import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { Offer, offersEndpoint } from '../../api/offer'
import { OffersSlice } from '../slices/offers'

export const fetchOffers = createApiAsyncThunk<Offer[]>(
  'offers/fetchOffers',
  async (args, thunkApi) => {
    const response = await fetch(`${offersEndpoint}`, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOffersPendingCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOffers.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      pending: true
    }
  }))
}

export const addFetchOffersFulfilledCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOffers.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchOffersRejectedCase = (
  builder: ActionReducerMapBuilder<OffersSlice>
): void => {
  builder.addCase(fetchOffers.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
