import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgEuro: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M13.9071 16.125C14.1778 16.0893 14.25 16.0179 14.25 15.7321V15C14.25 14.6964 14.1959 14.6429 13.871 14.6429C13.5823 14.6429 13.095 14.7143 12.0844 14.7143C10.2256 14.7143 9.3052 14.1786 8.99841 12.125H12.698C12.9867 12.125 13.0589 12.0714 13.0589 11.7679V11.1964C13.0589 10.8929 12.9867 10.8393 12.698 10.8393H8.90817C8.89013 10.6429 8.89013 10.4286 8.89013 10.1964C8.89013 9.92857 8.89013 9.66071 8.90817 9.41071H12.698C12.9867 9.41071 13.0589 9.35714 13.0589 9.05357V8.48214C13.0589 8.17857 12.9867 8.125 12.698 8.125H8.99841C9.3052 5.91072 10.2256 5.26786 12.0844 5.26786C13.095 5.26786 13.5823 5.375 13.871 5.375C14.1778 5.375 14.232 5.30357 14.232 5V4.26786C14.232 4 14.1778 3.92857 13.9071 3.89286C13.2394 3.80357 12.5897 3.75 11.7415 3.75C9.01645 3.75 7.59076 4.83929 7.22983 8.125H6.11093C5.82219 8.125 5.75 8.19643 5.75 8.48214V9.03571C5.75 9.33929 5.82219 9.41071 6.11093 9.41071H7.1396C7.12155 9.64286 7.12155 9.89286 7.12155 10.1607C7.12155 10.3929 7.12155 10.625 7.1396 10.8393H6.11093C5.82219 10.8393 5.75 10.9107 5.75 11.1964V11.75C5.75 12.0536 5.82219 12.125 6.11093 12.125H7.22983C7.60881 15.1786 9.0345 16.25 11.7415 16.25C12.4092 16.25 13.2394 16.2143 13.9071 16.125Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgEuro
