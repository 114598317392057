import React from 'react'

import { Session } from '@api/session'
import { SlugAndAccessCode } from '@context/useAccessInfo'

import { ItemGroup } from './Dropdown'
import jobRadLogo from './jobRadLogo.svg'
import HeaderLayout, { HeaderLogo } from './HeaderLayout'
import {
  getManagerDropdownItems,
  getManagerNavigationItems
} from './navItemsManager'
import styles from './Header.module.scss'
import PreviewModeMessages from './PreviewModeMessages/PreviewModeMessages'
import MaintenanceModeMessages from './MaintenanceModeMessages/MaintenanceModeMessages'
import { NavigationItem } from './NavItem'
import {
  getEmployeeDropdownItems,
  getEmployeeNavigationItems
} from './navItemsEmployee'

type Props = {
  session?: Session
  accessInfo?: SlugAndAccessCode
  portal?: 'employee' | 'manager'
}

export const Header: React.FunctionComponent<Props> = ({
  session,
  accessInfo,
  portal
}) => {
  const previewMode = session?.contract.is_in_preview_mode
  const maintenanceInfo = session?.contract.info_maintenance_activated

  let navItems: NavigationItem[] | undefined
  let dropdownItems: ItemGroup[] | undefined
  // default logo for public header
  let logo: HeaderLogo = {
    source: jobRadLogo,
    linkTo: 'https://www.jobrad.org/'
  }

  if (session && accessInfo) {
    // header is either used by employee or manager
    const { portal_manager: isManager } = session.access_rights

    navItems =
      portal === 'manager'
        ? getManagerNavigationItems(accessInfo, session)
        : getEmployeeNavigationItems()

    dropdownItems = isManager
      ? getManagerDropdownItems(accessInfo, session)
      : getEmployeeDropdownItems(accessInfo, session)

    logo = {
      source: session.contract.logo || jobRadLogo,
      linkTo:
        session.contract.portal_settings.ext_portal_url ||
        session.contract.access_url ||
        'https://www.jobrad.org/'
    }
  }

  return (
    <div className={styles.headerWrapper}>
      {previewMode && <PreviewModeMessages />}
      {maintenanceInfo?.maintenance_notification && (
        <MaintenanceModeMessages maintenanceValues={maintenanceInfo} />
      )}
      <HeaderLayout
        navigationItems={navItems}
        menuItems={dropdownItems}
        menuLabel={session?.name}
        logo={logo}
        // remove after re-design of manager pages
        isManagerHeader={portal === 'manager'}
        companyName={session?.company_name}
      />
    </div>
  )
}
