import { useSession } from '@context/useSession'

export type SlugAndAccessCode = {
  slug: string
  accessCode: string
}

// TODO: Have useTypedLocation return the access info, then delete this hook
export const useAccessInfo = (): SlugAndAccessCode => {
  const session = useSession()
  return {
    accessCode: session.contract.access_code,
    slug: session.contract.slug
  }
}
