import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgCheckbox: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <rect width="20" height="20" rx="1" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.2929 5.29289C18.6834 5.68342 18.6834 6.31658 18.2929 6.70711L8.6814 16.3186C8.30106 16.6989 7.688 16.7103 7.29384 16.3443L1.76044 11.2061C1.34509 10.8204 1.33299 10.167 1.73378 9.76622L2.32913 9.17087C2.70521 8.79479 3.30987 8.77889 3.7052 9.13468L8 13L16.2929 4.70711C16.6834 4.31658 17.3166 4.31658 17.7071 4.70711L18.2929 5.29289Z"
      fill="white"
    />
  </svg>
)
export default SvgCheckbox
