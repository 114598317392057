const german = {
  homepage: 'Homepage',
  contact: 'Kontakt',
  continue: 'Weiter',
  employerArea: 'Arbeitgeberbereich',
  employeeOrder: 'Mitarbeiterantrag',
  employeeOrders: 'Mitarbeiteranträge',
  employeeBikes: 'Meine JobRäder',
  cancel: 'Abbrechen',
  skipToContent: 'Zu Hauptinhalt springen',
  genericServerCommunicationError: 'Fehler beim Absenden',
  errorBoundaryErrorMessage: 'Ein Fehler ist aufgetreten.',
  maxOrderCountReachedForEmployeeWarningMessage:
    'Sie haben die maximale Anzahl an Anträgen erreicht.',
  sessionFetchErrorMessage:
    'Beim Kommunizieren mit dem JobRad Portal ist ein Fehler aufgetreten.',
  copyrightDisclaimer: 'Copyright ©',
  personalInformation: 'Persönliche Informationen',
  requiredField:
    'Mit * gekennzeichnete Felder sind für die Registrierung erforderlich.',
  emailAddress: 'E-Mail-Adresse',
  submitButtonLabel: 'Änderungen speichern',
  discardChangesConfirm: 'Änderungen verwerfen',
  cancelProcessing: 'Bearbeitung abbrechen',
  export: 'Export',
  month: 'Monat',
  day: 'Tag',
  per: 'pro',
  selectDate:
    'Datumsformat TT.MM.JJJJ. Eingabefeld Leer. Drücke Enter oder Leertaste um ein Datum auszuwählen',
  previousMonth: 'Vorheriger Monat Schalter',
  nextMonth: 'Nächster Monat Schalter',
  previewMode: {
    title: 'Ihr meinJobRad-Portal befindet sich im Vorschaumodus.',
    modal: {
      title: 'Portal-Vorschaumodus',
      headline1: 'Was genau ist der Vorschaumodus?',
      listItems1: [
        'Der Vorschaumodus dient dem vorherigen Testen Ihres meinJobRad-Portals.',
        'Bestellungen werden nach Freischaltung Ihres meinJobRad-Portals gelöscht.',
        'Registrierte Portalnutzer bleiben nach Freischaltung bestehen.'
      ],
      headline2: 'Die Schritte bis zur Freischaltung des meinJobRad-Portals:',
      listItems2: [
        'Die eingereichten Vertragsdokumente werden nach positiver Prüfung angenommen und gegengezeichnet an Sie zurückgesandt.',
        'Ihr meinJobRad-Portal wird freigeschaltet und die bevollmächtigten Personen erhalten eine Benachrichtigung per E-Mail.'
      ]
    }
  },
  maintenanceMode: {
    title: 'Ihr meinJobRad-Portal befindet sich im Wartungsmodus.',
    modal: {
      headline: 'Aktuelle Wartungsarbeiten'
    }
  },
  appInfo: {
    alertButtonLabel: 'Weitere Informationen',
    modalButtonlabel: 'Fenster schließen'
  },
  mandatoryFieldsInfo: 'Mit * gekennzeichnete Felder sind erforderlich.',
  closeLabel: 'Schließen',
  greenCheckMark: 'Grüner Haken',
  offerCodeValidationErrors: {
    badFormat:
      'Geben Sie einen gültigen Angebotscode ein. Der Angebotscode besteht aus Acht Ziffern, getrennt durch einen Bindestrich, z.B. 123456-01',
    noExist:
      'Dieser Angebotscode existiert nicht in unserem System. Überprüfen Sie bitte Ihre Eingabe.',
    used: 'Der eingegebene Angebotscode wurde bereits benutzt.',
    cancelled: 'Der eingegebene Angebotscode wurde abgebrochen.',
    failedAccessories:
      'Ihr Angebot enthält nicht leasingfähiges Zubehör gemäß den Vorgaben ' +
      'Ihres Arbeitgebers: {{errors}}. Bitte lassen ' +
      'Sie sich durch Ihren Fachhändler das Angebot ohne dieses Zubehör anpassen.',
    failedContract:
      'Eine oder mehrere Angaben in diesem Angebot sind für Ihr Unternehmen ' +
      'nicht zulässig.',
    failedCategory:
      'Das Angebot enthält eine Fahrrad-Kategorie, welche laut ' +
      'den vereinbarten Rahmenbedingungen Ihres Unternehmens nicht zulässig ist.',
    failedPrice:
      'Der Preis des Fahrrads ist laut den vereinbarten ' +
      'Rahmenbedingungen Ihres Unternehmens nicht zulässig.',
    failedCategoryPrice:
      'Der Preis und die Fahrrad-Kategorie des ' +
      'Fahrrads sind laut den vereinbarten Rahmenbedingungen Ihres ' +
      'Unternehmens nicht zulässig.',
    failedModelYear: 'Bitte gebe das Modelljahr im Format JJJJ an.',
    partiallyDisabledDealer:
      'Von diesem Fachhändler sind bis auf weiteres keine Angebote mehr ' +
      'verfügbar. Sobald der Fachhändler seine Kooperation aktualisiert ' +
      'hat, kann die Bestellung wie gewohnt weitergehen. Sollte dieser ' +
      'Status länger anhalten, haben Sie die Möglichkeit über einen anderen ' +
      'Fachhändler ein neues Angebot zu beziehen.'
  },
  navigation: {
    navigationAriaLabel: 'Hauptmenü',
    managerOverview: 'Jobrad Übersicht',
    employeeOrders: 'Mitarbeiteranträge',
    contractEditor: 'Überlassungsvertrag',
    managerSettings: 'Einstellungen',
    managerReports: 'Auswertungen & Reports',
    managerHrReports: 'Auswertungen & Reports',
    account: 'Mein Konto',
    newOrder: 'Neuer Antrag',
    faq: 'Häufige Fragen',
    managerLinks: 'Arbeitgeberbereich',
    employeeLinks: 'Persönlicher Bereich',
    logout: 'Abmelden',
    overflowButton: 'Mehr',
    menuButton: 'Menü',
    companyLogo: 'Zur Startseite Mein JobRad Portal {{companyName}}',
    jobRadLogo: 'Zur Startseite Mein JobRad Portal JobRad',
    managerFaq: 'Häufige Fragen für Bevollmächtigte',
    employeeFaq: 'Häufige Fragen für Jobradler'
  },
  newsletterSettings: {
    subscriptionHeader: 'Tauchen Sie ein in die JobRad-Welt!',
    subscribeButtonLabel: 'Newsletter abonnieren',
    cancelButtonLabel: 'Abbrechen',
    subscriptionText: 'Newsletter abonniert',
    subscriptionViewBodyPart1:
      'Neuigkeiten aus der Rad- und Mobilitätsszene, exklusive Angebote ausgesuchter JobRad-Partner, ' +
      'sowie Aktionen nur für JobRadler – das und mehr regelmäßig in Ihrem Postfach.',
    subscriptionViewBodyPart2:
      'Ich bin damit einverstanden, dass die JobRad GmbH meine Kontodaten (wie z.B. Name, Anschrift, ' +
      'E-Mail-Adresse, Telefonnummer) sowie meine Nutzungsdaten (z.B. genutzte Services) verarbeiten darf, ' +
      'um Marktforschung und Produktentwicklung zu betreiben und mich (z.B. per E-Mail oder im meinJobRad-Portal) ' +
      'über Produkte und Dienstleistungen der <jobRadGroupLink>{jobRadGroupLinkText}</jobRadGroupLink> ' +
      'sowie ausgewählter <partnerLink>{partnerLinkText}</partnerLink> zu informieren. Ich bin auch mit einer ' +
      'Weitergabe meiner Daten innerhalb der JobRad-Gruppe einverstanden.',
    jobRadGroupLinkText: 'JobRad-Gruppe',
    partnerLinkText: 'Partner',
    subscriptionViewBodyPart3:
      'Meine Einwilligung ist jederzeit widerruflich (z.B. über den in jeder E-Mail enthaltenen Abmeldelink.) ' +
      'Weitere Informationen finde ich in der <dataProtectionLink>{dataProtectionLinkText}</dataProtectionLink>.',
    dataProtectionLinkText: 'Datenschutzinformation',
    serverErrorTitle: 'Entschuldigung, hier ging leider etwas schief',
    serverErrorMessage:
      'Unser System konnte Sie leider nicht für den Newsletter anmelden. Falls das Problem beim nächsten Versuch ' +
      'weiterhin besteht, bitte lade die Seite neu oder versuche es in 15 Minuten noch einmal.'
  },
  footer: {
    importantLinks: {
      header: 'Wichtige Links',
      managerFaq: 'Häufige Fragen für Bevollmächtigte',
      employeeFaq: 'Häufige Fragen für Jobradler'
    },
    genericInformationHeader: 'Allgemeine Informationen',
    imprintLinkText: 'Impressum',
    privacyLinkText: 'Datenschutz',
    aboutUsHeader: 'Wir bringen Menschen aufs Rad',
    aboutUsText:
      'JobRad® kommt aus der Fahrradstadt Freiburg. Seit 2008 setzen wir uns mit ganzer Leidenschaft dafür ein, mehr Menschen aufs Rad zu bringen.',
    poweredWithLoveText:
      'Powered with <heartIcon></heartIcon> by <linktarget></linktarget>',
    poweredByJobrad: 'Jobrad',
    copyrightDisclaimer: 'Copyright ©{{year}}',
    newsletterBodyPart1: 'Newsletter gefällig?',
    newsletterBodyPart2:
      'Profitieren Sie von exklusiven Angeboten und Neuigkeiten rund um JobRad!',
    newsletterSubscribeButtonLabel: 'Jetzt abonnieren',
    explanatoryVideos: 'Erklärvideos'
  },
  pages: {
    pageEmployeeAccountHeader: 'Mitarbeiterkonto',
    pageEmployeeAccount: 'Mein Konto',
    pageRegularContractTerminationFaq: 'Mein JobRad Vertragsende',
    pageNotFound: '404: Seite wurde nicht gefunden!',
    pageManagerReport: {
      serverCommunicationErrorContentHeader:
        'Fehler beim Laden des Verlaufs Ihres Überlassungsvertrages'
    },
    pageExplanatoryVideos: {
      employeeTitle: 'Erklärvideos für JobRadler',
      managerTitle: 'Erklärvideos für Arbeitgeber',
      bodyText:
        'Viele Antworten rund um JobRad findest du in unseren Erklärvideos.',
      backLink: 'zurück zur vorherigen Seite',
      videos: {
        employeeIntro: 'Einführung meinJobRad-Portal für JobRadler',
        employeeOrderCreate: 'So bestellen Sie Ihr JobRad',
        managerIntro: 'Einführung ins meinJobRad-Portal für Arbeitgeber',
        managerOrderCreate: 'Anträge für Mitarbeitende erstellen',
        managerOrderEdit: 'Anträge von Mitarbeitenden bearbeiten',
        mangerIvo: 'Überlassungsvertrag bearbeiten'
      },
      videoFrameTitle: 'Video - {{videoTitle}}'
    }
  },
  pageDocumentTitles: {
    pageEmployeeAccountTitle: 'Mitarbeiterkonto - {{companyName}} JobRad Portal'
  },
  features: {
    company: 'Unternehmen',
    employee: 'Mitarbeiter',
    genderLabel: 'Anrede',
    genderRequiredMessage: 'Pflichtfeld. Bitte eine Option auswählen',
    firstAndLastNameLabel: 'Vor- und Nachname',
    firstAndLastNameRequiredMessage:
      'Pflichtfeld. Bitte geben Sie Vor- und Nachnamen an',
    bikedetails: 'Fahrraddetails',
    bikeMarkLabel: 'Fahrradmarke',
    bikeModelYearLabel: 'Modelljahr',
    bikeColorLabel: 'Fahrradfarbe',
    bikeModelLabel: 'Fahrradmodell',
    bikeSizeLabel: 'Fahrradgröße',
    bikeTypeLabel: 'Fahrradtyp',
    bikeTransferLabel: 'Fahrradübernahme',
    submissionDate: 'Antragsdatum',
    state: 'Antragsdatum',
    dateBeforeMaxErrorMessage:
      'Bitte geben Sie ein Datum vor dem {{maxDate}} an.',
    dateAfterMinErrorMessage:
      'Bitte geben Sie ein Datum nach dem {{minDate}} an.',
    dateBetweenMinAndMaxErrorMessage:
      'Bitte geben Sie ein Datum zwischen dem {{minDate}} und dem {{maxDate}} an.',
    dateRangeErrorMessage: 'Das Anfangsdatum muss vor dem Enddatum liegen.',
    startDate: 'Anfangsdatum',
    endDate: 'Enddatum',
    dateAfterMinHelpText: 'Ein Datum ab {{minDate}}',
    dateBeforeMaxHelpText: 'Ein Datum bis zum {{maxDate}}',
    dateBetweenMinAndMaxHelpText:
      'Ein Datum im Zeitraum vom {{minDate}} bis zum {{maxDate}}',
    transferValues: {
      directShipment: 'Direktversand',
      pickUpByCustomer: 'Selbstabholung'
    },
    bikeCategoryLabel: 'Fahrradkategorie',
    leasableAccessoriesLabel: 'Leasingfähiges Zubehör',
    offerPriceHeader: 'Angebotspreis',
    priceTotalLabelIfRrpUsedInCalculation: 'Unverbindliche Preisempfehlung',
    priceTotalHintIfRrpUsedInCalculation: 'inkl. Verpackung und Versand',
    agreedSalePrice: 'Vereinbarter Verkaufspreis',
    emailAdresse: 'E-Mail-Adresse',
    adresse: 'Straße und Hausnummer',
    phoneNumberLabel: 'Telefonnummer',
    phoneNumberRequiredMessage:
      'Pflichtfeld. Bitte geben Sie eine Telefonnummer an',
    personalNrLabel: 'Personalnummer',
    personalNrWithFormatLabel: 'Personalnummer (Korrektes Format: {{value}})',
    hyphen: 'Bindestrich',
    personalNrHint:
      'Änderungen der persönlichen Informationen gelten nur für neu angelegte Anträge, nicht für bereits gestellte.',
    personalNrRequiredMessage:
      'Pflichtfeld. Bitte geben Sie eine Personalnummer an',
    serialNrHint:
      'Ein Beispiel für eine Rahmennummer für {{brandName}} wäre: {{allowedFormats}}',
    privateAddressGroupHeader: 'Private Adresse',
    addEmployeePrivateAddressHeader: 'Private Adresse des Mitarbeitenden',
    streetLabel: 'Straße',
    personalInformationGroupHeader: 'Persönliche Informationen',
    managerPersonalInformationGroupHeader: 'Kontaktdaten des Mitarbeitenden',
    zipCodeLabel: 'Postleitzahl',
    zipCodeRequiredMessage: 'Pflichtfeld. Bitte geben Sie die Postleitzahl an',
    residenceLabel: 'Wohnort',
    residenceRequiredMessage: 'Pflichtfeld. Bitte geben Sie den Wohnort an',
    countryLabel: 'Land',
    countryRequiredMessage: 'Pflichtfeld. Bitte wählen Sie ein Land aus',
    streetAndHouseNumberLabel: 'Straße und Hausnummer',
    streetAndHouseNumberRequiredMessage:
      'Pflichtfeld. Bitte geben Sie Straße und Hausnummer an',
    emailRequiredMessage: 'Pflichtfeld. Bitte geben Sie eine E-Mail-Adresse an',
    emailPatternValidationError:
      'Pflichtfeld. Bitte geben Sie eine gültige E-Mail-Adresse an.',
    offerPrice: 'Angebotspreis',
    fieldValidationRequired: 'Dies ist ein Pflichtfeld',
    managerInformationLabel: 'Mitarbeitendeninformation',
    employeeInformationLabel: 'Kundeninformation',
    employeeinformation: {
      employeeinformationLabel: 'Mitarbeitende Person ',
      employeeinformationPlaceholder: 'Wähle eine Person aus',
      employeeinformationHelpText:
        'Tippe Namen oder  Personalnummer ein, um bereits registrierte Mitarbeitende auszuwählen. Nicht registierte Personen bitte neu anlegen.' +
        '\n\n',
      addNewEmployee: 'Person neu anlegen'
    },
    helpdesk: {
      buttonLabel: 'Support',
      ticketTitleLabel: 'Bitte formulieren Sie einen kurzen Betreff:',
      ticketDescriptionLabel: 'Beschreibung (mindestens 20 Zeichen):',
      ticketDescriptionPlaceholder:
        'Bitte gebe eine möglichst genaue Beschreibung Ihres Problems an. ' +
        'Wenn Sie Probleme während einer JobRad-Bestellung haben, geben Sie auch Ihre Vorgangsnummer mit an.',
      minimalNecessaryCharsInTicketDescriptionHelpText:
        'Pflichtfeld. Bitte beschreiben Sie Ihr Anliegen mit mindestens 20 Zeichen.',
      header: 'Wie können wir unterstützen?',
      sendFormLabel: 'Nachricht abschicken',
      linkToFaqText:
        'Viele Antworten rund um JobRad finden Sie auch in unseren <faqLink>FAQs</faqLink> (häufig gestellte Fragen).',
      requiredFieldsHint: 'Mit * gekennzeichnete Felder sind erforderlich.',
      helpdeskTicketTransmitted: {
        errorVirusMessage:
          'Achtung! Die hochgeladene Datei enthält möglicherweise schädliche Inhalte ' +
          'und wurde gelöscht. Das Ticket wurde dennoch angelegt.',
        header: 'Vielen Dank für Ihre Nachricht',
        errorMessage: 'Achtung es gab leider ein Problem',
        generalError: 'Bitte versuchen sie es erneut.',
        body:
          'Wir freuen uns, dass Sie uns kontaktiert haben ' +
          'und werden uns in Kürze bei Ihnen melden.'
      },
      fileAndScreenshotCapture: {
        attachFile: 'Datei anhängen',
        deleteAttachedFile: 'Datei löschen',
        changeAttachedFile: 'Andere Datei anhängen',
        fileTypeError: 'Ungültiges Format. Gültig sind pdf, jpg und png.',
        fileType: 'Maximum 1 Datei, erlaubt: pdf,  jpg und png',
        fileSizeError: 'Die Datei ist zu groß. Gültig sind max. 5 MB',
        screenShotFileName: 'Bildschirmfoto.png',
        deleteScreenShot: 'Bildschirmfoto löschen',
        createScreenShot: 'Bildschirmfoto aufnehmen',
        replaceScreenShot: 'Bildschirmfoto ersetzen',
        screenShotModal: {
          openScreenShotModal: 'Bildschirmfoto anzeigen',
          download: 'Bildschirmfoto herunterladen'
        }
      }
    },
    leasingProcess: {
      supplierGroupHeader: 'Fahrradinformation',
      supplier: 'Fachhändler',
      supplierLabel: 'Ihr Fachhändler',
      emailLabel: 'E-Mail-Adresse',
      cancelButtonLabel: 'Zurück zum Antrag',
      conversionRateLabel: 'Umwandlungsrate',
      employeePaymentLabel: 'Arbeitnehmerzuzahlung',
      monetaryAdvantageLabel: 'Geldwerter Vorteil',
      leasingRateLabel: 'Leasingrate',
      insuranceRateLabel: 'Versicherungsrate',
      serviceRateLabel: 'Servicerate',
      totalLeasingRateLabel: 'Gesamtnutzungsrate',
      advantageGstLabel: 'MwSt. aus geldwertem Vorteil',
      overview: 'Übersicht',
      leaseableAccessories: 'Leasingfähiges Zubehör',
      salePrice: 'Verkaufspreis',
      plusVat: 'zzgl. MwSt.',
      vatPercent: 'Mehrwertsteuer 19%',
      inclLeasingAccessory: 'inkl. Leasingfähigen Zubehör',
      retailPrice: 'Unverbindliche Preisempfehlung',
      retailPriceWithShipping:
        'Unverbindliche Preisempfehlung inkl. Verpackung und Versand',
      leasingInformationen: 'Leasinginformationen',
      conversionRate: 'Umwandlungsrate',
      employeeCoPayment: 'Arbeitnehmerzuzahlung',
      monetaryAdvantage: 'Geldwerter Vorteil',
      preRateDailyHeader: 'Mögliche tägliche anteilige Umwandlungsrate',
      leasingRate: 'Leasingrate',
      insuranceRate: 'Versicherungsrate',
      serviceRate: 'Servicerate',
      overallUsageRate: 'Gesamtnutzungsrate ',
      vatFromMonetaryBenefit: 'MwSt. aus geldwertem Vorteil',
      createPurchaseOrder: 'Bestellantrag erstellen',
      orderCreateHeading: 'Bestellantrag erstellen {{draftOnly}}',
      modeDraftOnly: '(nur Entwurf)',
      employerSubsidy: 'Arbeitgeberzuschuss',
      accessoryTable: {
        model: 'Zubehörmodell',
        category: 'Zubehörkategorie',
        brand: 'Zubehörmarke'
      },
      detail: {
        discardOrderLabel: 'Antrag verwerfen',
        orderConfirmationView: {
          pageTitle: 'Ihr Antrag wurde erfolgreich abgeschlossen',
          header: 'Ihr Antrag wurde erfolgreich abgeschlossen',
          body: 'Bald geht es weiter! Sobald der Antrag von Ihrem Bevollmächtigten bestätigt wird, setzen wir uns mit Ihnen in Verbindung.'
        },
        documentTitleStates: {
          generic:
            'Antrag {{frontendName}}: {{portalState}} - {{companyName}} JobRad Portal',
          delayed: {
            supplierAndBrand:
              'Antrag {{frontendName}} verzögert: Entwurf - Marke und Fachhändler vorgeschlagen {{companyName}} JobRad Portal',
            supplier:
              'Antrag {{frontendName}} verzögert: Entwurf - Fachhändler vorgeschlagen {{companyName}} JobRad Portal',
            brand:
              'Antrag {{frontendName}} verzögert: Entwurf - Marke vorgeschlagen {{companyName}} JobRad Portal'
          },
          digitalUEVSignatureSigned:
            'Überlassungsvertrag zugestimmt - Antrag {{frontendName}}: Dokumente erforderlich - {{companyName}} Jobrad Portal',
          waitingForConfirmation:
            'Antrag abgeschlossen - Auf Freigabe wartend - {{companyName}} Jobrad Portal',
          closed:
            'Antrag {{frontendName}}: Leasingvertrag aktiv - {{companyName}} JobRad Portal'
        },
        approveOrderNotification:
          'Der Mitarbeiter wird über die Antragsfreigabe per E-Mail informiert.',
        conversionUsed:
          'Der Mitarbeiter hat bereits <bold>{{conversionUsed}}</bold> Euro genutzt.',
        priceAdjustmentCalculatorLink:
          'Klicken Sie <linkText>hier</linkText>, um für eine unverbindliche Berechnung der Nettobelastung zum Vorteilsrechner Ihres Arbeitgebers zu gelangen.',
        preRateHeader: 'Anteilige Umwandlungsrate',
        preRateTakeoverMessage:
          'Gut zu wissen: Die Leasinglaufzeit beginnt immer zum Monatsersten. Damit Sie nicht bis jeweils zum Monatsersten auf Ihr JobRad warten müssen, können Sie es auch früher vom JobRad-Fachhandelspartner übernehmen. In diesem Fall wird im betreffenden Zeitraum pro Tag 1/30 der für das JobRad kalkulierten monatlichen Gesamtrate als anteilige Nutzungsgebühr berechnet.',
        createOrderSteps: {
          conclude: 'Antrag abschließen',
          uploadDocuments: 'Dokumente hochladen',
          approval: 'Freigabe Arbeitgeber',
          ordered: 'JobRad bestellt',
          active: 'Leasingvertrag aktiv'
        },
        leasingInspection: {
          headline: {
            full: 'Full Service',
            ivo: 'Inspektion'
          },
          text: {
            full: 'Mithilfe der Service-ID <strong><format>{{serviceId}}</format></strong> können Sie je eine Inspektion pro Vertragsjahr und Verschleißreparaturen bei Ihrem JobRad-Premiumhändler durchführen lassen.',
            ivo: 'Mithilfe der Service-ID <strong><format>{{serviceId}}</format></strong> können Sie je eine Inspektion pro Vertragsjahr bei Ihrem JobRad-Premiumhändler durchführen lassen.'
          },
          progessSection: {
            full: 'Sie haben noch <strong>{{current}}</strong> von {{max}} Budget verfügbar',
            ivo: 'Sie haben noch <strong>{{current}}</strong> von {{max}} Inspektionen verfügbar'
          },
          link: 'Zu der Premiumhändlersuche',
          headlineTable: 'Durchgeführte Serviceleistungen',
          tableKeys: {
            dateOrder: 'Datum',
            partnerName: 'Fachhändler',
            amountTotal: 'Servicekosten',
            inspection: 'Inspektion',
            service: 'Servicebeleg'
          },
          tableValues: {
            service: 'herunterladen',
            inspection: {
              yes: 'Ja',
              no: 'Nein'
            }
          }
        },
        detailHeaderNavigationText: 'Zurück zu meinen Anträgen und Angeboten',
        orderTitle: 'Antrag ',
        reportInsuranceText: 'Versicherungsfall melden',
        orderStatus: {
          newStatus: 'Neu',
          savedHelpText:
            'Der Bestellantrag ist unvollständig und muss weiter bearbeitet werden.',
          savedStatus: 'Entwurf',
          sentHelpText:
            'Um den Bestellantrag abzuschließen, stimmen Sie dem Überlassungsvertrag zu und laden Sie die fehlenden Dokumente hoch.',
          sentStatus: 'Dokumente erforderlich',
          waitingStatus: 'Auf Freigabe wartend',
          waitingHelpText:
            'Ihr Bestellantrag wurde am {{orderDate}} an Ihren JobRad-Bevollmächtigten gesendet und muss noch freigegeben werden.',
          approvedHelpText:
            'Erfreuliche Nachrichten! Ihr Antrag ist genehmigt und Ihr Fahrrad wird bestellt. \nIhr Fachhändler wird sich in Kürze bei Ihnen melden.',
          approvedStatus: 'Freigegeben',
          orderedStatus: 'Bestellt',
          deliveredStatus: 'Übergeben',
          closedStatus: 'Leasingvertrag aktiv',
          contractEndStatus: 'Vertragsauflösung',
          endedStatus: 'Beendet',
          canceledStatus: 'Abgebrochen',
          checkRatingHelpText:
            'Ihr Bestellantrag wurde von Ihrem Bevollmächtigten freigegeben und muss nun von JobRad genehmigt werden.',
          checkRatingStatus: 'Auf Genehmigung wartend',
          waitingConfirmationStatus: 'Auf Ihre Bestätigung wartend'
        },
        documentUpload: {
          headline: 'Dokumente hochladen',
          upload: 'hochladen',
          uploadSuccess: 'Erfolgreich hochgeladen',
          delete: 'löschen',
          loading: 'Loading',
          extraUpload: {
            required: 'verpflichtend',
            optional: 'optional'
          },
          download: 'herunterladen',
          showNotYetUploaded: 'Noch nicht hochgeladen',
          deletionSuccess:
            'Erfolgreich gelöscht, bitte eine neue Datei hochladen',
          formValidationError:
            'Um den Antrag abschließen zu können, müssen Sie dem Überlassungsvertrag zustimmen und alle notwendigen Dokumente hochladen.',
          errorUploadGeneral:
            'Beim Hochladen des Dokuments ist ein Fehler aufgetreten.',
          errorDeletion:
            'Ein Fehler ist aufgetreten beim Löschen des Dokuments.',
          errorUploadUnprocessableEntity:
            'Leider ist der Upload nicht möglich. Dies kann daran liegen, dass das Dokument ' +
            'bereits an anderer Stelle hochgeladen oder von Ihnen noch nicht signiert wurde.',
          errorUploadInternalSeverError:
            'Es sind nur PDFs erlaubt mit einer maximalen Dateigröße von 5,0 MB.',
          errorUploadUnsupportedFileSize: 'Die Datei ist größer als 5 MB',
          errorUploadUnsupportedFileType:
            'Der Dateityp des Dokumentes stimmt nicht mit dem erlaubten Dateityp PDF überein.',
          deleteConfirmModal: {
            title: 'Dokument  löschen?',
            confirmDeletion: `Möchten Sie das Dokument '{{name}}' endgültig löschen?`,
            confirmButtonLabel: 'Ja, Dokument löschen',
            cancelButtonLabel: 'Nein'
          }
        },
        generalInfo: {
          title: 'Allgemeine Informationen',
          date: 'Antragsdatum',
          leasingStatus: 'Vertragsstatus',
          incidents: {
            type: 'Leistungsfall',
            status: 'Leistungsfall-Status'
          }
        },
        detailManager: {
          orderMessagesBlock: {
            serverCommunicationErrorContent: 'Fehler beim Laden des Verlaufs',
            headline: 'Verlauf',
            savedNote: 'Notiz gespeichert',
            button: {
              primary: {
                label: 'Interne Notiz eingeben'
              }
            }
          },
          orderMessagesForm: {
            textarea: {
              label: 'Interne Notiz',
              helpText: 'Keine Bearbeitung durch JobRad',
              errorMessage: 'Das Notizfeld darf nicht leer sein'
            },
            buttonGroup: {
              secondary: {
                label: 'Eingabe Verwerfen'
              },
              primary: {
                label: 'Notiz speichern'
              }
            }
          },
          subManagerAlert:
            'Erst dann kann der zuständige JobRad-Bestellberechtigte den Antrag des Mitarbeiters bestätigen.',
          notApproveOrderModalDescription:
            'Der Mitarbeiter muss einen neuen Antrag stellen, ' +
            'der Ihnen anschließend zur Prüfung vorliegt. ' +
            'Bitte gebe im untenstehenden Feld den Grund für die Ablehnung an. ' +
            'Der betroffene Mitarbeiter erhält automatisch eine E-Mail mit der Begründung.',
          orderDetailPriceSubtotal: 'Verkaufspreis zzgl. MwSt.',
          orderDetailPriceTax: 'Mehrwertsteuer {{value}}%',
          documentsUploadPrompt:
            'Bitte laden Sie den gegengezeichneten Überlassungsvertrag, ' +
            'vollständig, als eine PDF-Datei, sowie alle weiteren benötigten Dokumente hoch. ' +
            'Es sind nur <highlight>PDFs</highlight> erlaubt ' +
            'mit einer maximalen Dateigröße von <highlight>5,0 MB.</highlight>',
          serviceOption: {
            serviceOptionCompanyPayer:
              'der Arbeitgeber übernimmt die Servicerate',
            serviceOptionEmployeePayer:
              'der Arbeitnehmer übernimmt die Servicerate'
          }
        },
        detailEmployee: {
          header:
            'Überlassungsvertrag wurde erfolgreich erzeugt. Bitte beachten Sie die folgenden Schritte um Ihr Antrag abzuschliessen.',
          defaultAcceptCheckbox:
            'Durch das Anhaken der nebenstehenden Checkbox ' +
            'stimme ich den Regelungen des Nutzungsüberlassungsvertrages zu und erkläre, dass ich den ' +
            'Vertrag hiermit verbindlich abschließen möchte.',
          printUev: 'Überlassungsvertrag drucken',
          printAndSignUev: 'Überlassungsvertrag ausdrucken und unterschreiben',
          scanUev:
            'Bitte scannen Sie den Überlassungsvertrag vollständig (alle Seiten) und gegengezeichnet (Ort, Datum, Unterschrift) als PDF-Datei ein.',
          confirmOrderButtonLabel: 'Antrag abschließen',
          confirmOrderModalSubtitle: `Nach Klicken auf "Ja, Antrag erstellen"
            können Sie keine Dokumente mehr hochladen oder löschen und der Antrag wird
            an Ihren JobRad-Bevollmächtigten übermittelt.`,
          saveOrderButtonLabel: 'Antrag speichern',
          uevDownloadedSuccess:
            'Ich habe den Überlassungsvertrag gelesen und unterschrieben.',
          documentsUpload: {
            noticeForPrintUEVSignatureWorkflow:
              'Bitte laden Sie den gescannten Überlassungsvertrag vollständig ' +
              'sowie alle weiteren benötigten Dokumente hoch. ' +
              'Möglich sind:<br>' +
              '\u2022 <highlight>PDF-Dateien</highlight><br>' +
              '\u2022 <highlight>Dateien mit max. 5,0 MB</highlight>',
            noticeForDigitalUEVSignatureWorkflow:
              'Bitte laden Sie alle benötigten Dokumente hoch. Möglich sind:<br>' +
              '\u2022 <highlight>PDF-Dateien</highlight><br>' +
              '\u2022 <highlight>Dateien mit max. 5,0 MB</highlight>',
            noticeForAllDocumentsAreUploaded:
              'Alle benötigten Dokumente wurden hochgeladen',
            withDifficulties: 'Schwierigkeiten beim Hochladen?',
            byEmail:
              'Wir übernehmen das Hochladen für Sie! Bitte senden Sie uns die erforderlichen Dokumente vollständig unter Angabe Ihrer KAU-Nr. an '
          },
          digitalSignature: {
            acceptUEVHeader: 'Überlassungsvertrag zustimmen',
            uevAccepted:
              'Ich habe den Überlassungsvertrag gelesen und ihm zugestimmt.',
            uevNotAccepted:
              'Bitte stimmen Sie dem Überlassungsvertrag zur Nutzung des Fahrrades zu, um den Leasingvertrag abzuschließen.',
            goToUEVAcceptPage: 'Überlassungsvertrag zustimmen und abschließen'
          },
          digitalSignatureView: {
            pageTitle:
              'Zustimmung des Nutzungsüberlassungsvertrages - {{companyName}} JobRad Portal',
            returnLink: 'Zurück zu den Antragsdetails',
            title: 'Zustimmung des Nutzungsüberlassungsvertrag',
            iframeTitle: '{{frontendName}} Nutzungsüberlassungsvertrag',
            subtitle:
              'Bitte bestätigen Sie am Seitenende Ihr Einverständnis mit den Vertragsbedingungen.',
            requiredErrorMessage:
              'Pflichtfeld. Bitte setzen Sie den Haken, um fortzufahren',
            submitLabel: 'Zustimmen und Vertrag abschließen',
            defaultAcceptCheckbox:
              'Ich stimme den Regelungen des Nutzungsüberlassungsvertrages zu und erkläre, dass ich den Vertrag hiermit verbindlich abschließen möchte.',
            errorTitle:
              'Es gab ein Problem beim Unterzeichnen des Überlassungsvertrags',
            errorModalTitle:
              'Fehler beim Unterzeichnen des Überlassungsvertrags'
          },
          orderDocuments: {
            headlineDocument: 'Dokumente',
            headlineLeasingDocument: 'Leasing Dokumente',
            headlineExtraDocument: 'Extra Dokumente',
            headlineImportantDocument: 'Wichtige Dokumente',
            downloadButton: 'Herunterladen'
          }
        },
        orderRatesLabeledValues: {
          conversionRate: 'Umwandlungsrate',
          employeeSupplement: 'Arbeitnehmerzuzahlung',
          conversionRateHint: 'Betrag wird vom Bruttolohn abgezogen',
          pecuniaryAdvantage: 'Geldwerter Vorteil',
          leasingrate: 'Leasingrate',
          insuranceRate: 'Versicherungsrate',
          serviceRate: 'Servicerate',
          overallUsageRate: 'Gesamtnutzungsrate',
          vatFromMonetaryBenefit: 'MwSt. aus geldwertem Vorteil',
          employerSubsidy: 'Arbeitgeberzuschuss',
          conversionBudgetUsed: 'Verwendetes Umwandlungsbudget',
          leasingBegin: 'Leasingbeginn',
          leasingBeginDetailText:
            'Nach Übernahme des Fahrrades beginnt das Leasing in der Regel am ersten Tag des Folgemonats.',
          leasingEnd: 'Leasingende',
          leasingEndDetailText:
            'Sie werden wenige Wochen vor Leasingende per E-Mail von uns informiert.'
        },
        editButton: 'Antrag Bearbeiten',
        orderRatesCardHeader: 'Leasinginformationen',
        inactive: 'noch nicht aktiv',
        cancelModal: {
          body: 'Bitte beachten Sie, dass der Antrag danach nicht wieder aktiviert werden kann. Möchten Sie ihn endgültig verwerfen?',
          title: 'Antrag final verwerfen',
          cancelLabel: 'Zurück zum Antrag',
          reasonInputLabel:
            'Wir freuen uns über eine kurze Begründung (optional):'
        },
        completeModal: {
          title: 'Antrag abschließen',
          body: 'Schließen Sie ihren Antrag ab. Sobald der Antrag von Ihrem Bevollmächtigten bestätigt wird, setzem wir uns mit Ihnen in Verbindung.'
        }
      },
      deliveryConfirmation: {
        submitButtonLabel: 'Übernahme bestätigen',
        deliveryConfirmationInfo: {
          header:
            'Prüfen Sie Ihr geliefertes Fahrrad und bestätigen Sie die Übernahme',
          body: 'Bitte bestätigen Sie durch die Eingabe des Übernahmecodes, dass:',
          bodyFirstInfo:
            'Das Fahrrad, das Sie bestellt haben, vollständig geliefert wurde, ' +
            'betriebsbereit und mangelfrei ist. Es ist in einem sicheren und funktionsfähigen ' +
            'Zustand (das betrifft auch leasingfähiges Zubehör).',
          bodySecondInfo:
            'Sie das Fahrrad gemäß der Aufbauanleitung des Herstellers aufgebaut haben.',
          bodyThirdInfo:
            'Die Übernahme des Fahrrads im Namen und Auftrag Ihres Arbeitgebers erfolgt ' +
            'ist und das Fahrrad somit den vertraglichen Vereinbarungen entspricht.',
          attentionInfo:
            'Achtung: Wenn Sie in der Übernahmebestätigung falsche Angaben machen oder ' +
            'den Übernahmecode eingeben, bevor Sie das Fahrrad wirklich übernommen haben, ' +
            'verstoßen Sie gegen den Vertrag. Der Leasinggeber kann dann Schadensersatz vom Ihrem Arbeitgeber verlangen.'
        },
        deliveryConfirmationForm: {
          documentTitle:
            'Prüfung und Bestätigung der Übernahme - Jobrad Portal',
          documentTitleError:
            'Fehler - Prüfung und Bestätigung der Übernahme - Jobrad Portal',
          header: 'Ihre Angaben',
          inputsGroupHeader: 'Übernahmeangaben',
          formValidationError:
            'Um die Übernahme zu bestätigen, füllen Sie bitte alle markierten Pflichtfelder korrekt aus.',
          frameNumberLabel: 'Rahmennummer',
          transferCodeLabel: 'Übernahmecode (000000-0)',
          deliveryDateLabel: 'Datum der mängelfreien Lieferung',
          batterySerialNumberLabel: 'Akku-Seriennummer',
          frameNumberRequired:
            'Pflichtfeld. Bitte geben Sie die Rahmennummer an',
          transferCodeRequired:
            'Pflichtfeld. Bitte geben Sie den Übernahmecode aus der E-Mail an',
          deliveryDateRequired:
            'Pflichtfeld. Bitte geben Sie das Lieferdatum an',
          wrongAdoptionCodeValidationError:
            'Dieser Übernahmecode stimmt nicht überein.',
          wrongFormatSerialValidationError:
            'Falsches Rahmennummernformat. Bitte orientieren Sie sich am Beispiel.',
          submitErrorHeadline:
            'Es gab ein Problem beim Bestätigen der vertragsgemäßen Übernahme',
          errorModalTitle:
            'Fehler beim Bestätigen der vertragsgemäßen Übernahme'
        },
        deliveryConfirmationModal: {
          title: 'Bestätigen Sie die Übernahme',
          body:
            'Bitte beachten Sie, dass Sie nach der Bestätigung keine Änderungen ' +
            'mehr vornehmen können. Damit erklären Sie, dass das Fahrrad vertragsgemäß ' +
            'geliefert und von Ihnen übernommen wurde.',
          editButtonLabel: 'Weiter bearbeiten'
        },
        deliveryConfirmationSuccess: {
          documentTitle:
            'Die Übernahme Ihres JobRads wurde erfolgreich bestätigt',
          header: 'Wir wünschen Ihnen viel Spaß beim Jobradeln',
          bodyFirstPart:
            'Vielen Dank, dass Sie die Zustellung geprüft und die Übernahme Ihres Fahrrades bestätigt haben.',
          bodySecondPart: 'Allzeit sichere und gute Fahrt!'
        }
      },
      list: {
        manager: {
          managerOrderListNoResultsHelpText:
            'Klicke hier um einen neuen Antrag für Dich selbst, oder im Namen eines Mitarbeiters zu erstellen.',
          managerOrdersSwitchToDetailIconTitle: 'Zur Detailansicht wechseln',
          managerOrdersSwitchToListIconTitle: 'Zur Listenansicht wechseln',
          managerWelcomeModal: {
            header: 'Willkommen im meinJobRad-Portal!',
            bulletPointOne:
              'Einen ersten Überblick über die Portalfunktionen gibt Ihnen unser <linkText>Einführungstour-Video.</linkText>',
            bulletPointTwo: `Bitte prüfen Sie unter dem Punkt Einstellungen, ob die
                    voreingestellten JobRad-Konditionen Ihren Wünschen entsprechen. <linkText>Hier</linkText> zeigen wir Ihnen, welche Optionen zur Verfügung stehen. Viele
                    weitere Videos, wie Sie z. B. Bestellanträge von Mitarbeiterinnen
                    und Mitarbeitern bearbeiten und freigeben, finden Sie jederzeit
                    über den Assistenten.`,
            bulletPointThree: `Weitere Tipps und Anleitungen geben wir Ihnen in unseren <linkText>Web-Seminare.</linkText> Natürlich kostenfrei.`,
            cancelButton: 'Fenster schließen',
            confirmButton: 'Hilfreiche Videos',
            footerText: `Wir wünschen Ihnen viel Spaß beim (Job)Radeln!<br /><br /> Ihr JobRad-Team <br /><br />PS: Sie haben zwischenzeitlich Fragen?<br /> Über das Briefumschlag-Symbol senden Sie einfach ein Ticket an,
                unseren Support.`
          }
        }
      },
      create: {
        newOrderModal: {
          header: 'Bitte gebe deinen Angebotscode ein',
          offerCodeLabel: 'Angebotscode',
          subtitle:
            'Nach erfolgreicher Überprüfung können Sie das Angebot Ihres Fachhändlers vervollständigen.',
          continueWithoutOfferCodeButtonLabel: 'Weiter ohne Angebotscode',
          continueWithOfferCodeButtonLabel: 'Zur Antragserstellung',
          formValidationError:
            'Bitte wählen oder geben Sie einen Angebotscode an oder klicken Sie “Weiter ohne Angebotscode”'
        },
        pageTitle:
          'Bestellantrag erstellen {{draftOnly}} - {{companyName}} JobRad Portal',
        pageTitleError:
          'Fehler - Bestellantrag erstellen {{draftOnly}} - {{companyName}} JobRad Portal'
      },
      edit: {
        pageTitle: 'Bestellantrag bearbeiten - {{companyName}} JobRad Portal',
        pageTitleError:
          'Fehler - Bestellantrag bearbeiten - {{companyName}} JobRad Portal'
      },
      createAndEditForm: {
        customerInformationen: 'Kundeninformation',
        dynamicFields: { headline: 'Weitere Werte' },
        explanationHeader:
          'Bitte gebe den Grund für die Bearbeitung an. ' +
          'Diese wird ebenfalls per E-Mail an den Mitarbeiter übersandt.',
        subtitle:
          'Wenn Sie auf <highlight>Weiter</highlight> klicken, ' +
          'wird der durch den Mitarbeiter angelegte Antrag storniert. ' +
          'Ein neuer Bestellantrag wird mit von Ihnen getätigten Änderungen ' +
          'erstellt und zur erneuten Prüfung an den Mitarbeiter übersendet.',
        newOrderTitle: 'Beantragen Sie Ihr JobRad',
        moreValues: 'Weitere Werte',
        editOrderTitle: 'Antrag ändern',
        completeOrderInfoText:
          'Geben Sie die fehlenden persönlichen Informationen ein. Tragen Sie alle ' +
          'Details aus dem Angebot Ihres Fachhändlers in die entsprechenden Felder ' +
          'ein und schließen Sie Ihren JobRad-Antrag ab. Wenn der Antrag durch ' +
          'JobRad bestätigt wurde, wird der Fachhändler Sie über den Abholtermin ' +
          'Ihres JobRades informieren.',
        completeOrderInfoTextForEmployee:
          'Bitte füllen Sie den Bestellantrag für Ihr zukünftiges ' +
          'Fahrrad aus. Neben persönlichen Daten brauchen Sie alle Angaben ' +
          'aus dem Angebot des Fachhändlers.\n\n' +
          'Mit * gekennzeichnete Felder sind für den Bestellantrag unbedingt erforderlich.',
        completeOrderInfoTextForManager:
          'Bitte fülle den Bestellantrag für einen Mitarbeitenden aus. Neben persönlichen Daten ' +
          'zum Mitarbeitenden benötigst Du alle Details aus dem Angebot des Fachhändlers, ' +
          'der das Fahrrad zur Verfügung stellt. Sobald der Antrag durch JobRad bestätigt ist, ' +
          'setzten wir uns mit dem Mitarbeitenden in Verbindung \n\n' +
          'Mit * gekennzeichnete Felder sind für den Bestellantrag erforderlich.',
        addEmployeeDataMessage: 'Bitte fülle folgende Mitarbeiterangaben aus',
        priceValidationError: 'Bitte gebe einen gültigen Preis ein.',
        salesPriceExceedsRrpValidationError:
          'Der Verkaufspreis darf nicht höher sein als die unverbindliche Preisempfehlung',
        purchasePriceLabel: 'Vereinbarter Kaufpreis in €',
        retailPriceLabel: 'Unverbindliche Preisempfehlung in €',
        cancelButtonTextInModal: 'Nein, weiter bearbeiten',
        bikePriceGroup: {
          bikePriceLabel: 'Fahrradpreis',
          retailPriceLabel: 'Unverbindliche Preisempfehlung in €',
          retailUvpPriceLabel: 'Unverbindliche Preisempfehlung (UVP) in €',
          priceNotice:
            'Inklusive MwSt. ohne Kosten für Zubehör, Verpackung & Versand sowie Montage, Aufbau und Umbau. ',
          additionalPriceNotice: 'Bitte geben Sie den Kaufpreis centgenau an',
          additionalRrpNotice: 'Bitte geben Sie die UVP centgenau an',
          requiredPurchasePriceErrorMessage:
            'Pflichtfeld. Bitte geben Sie den Kaufpreis centgenau an',
          requiredRetailPriceErrorMessage:
            'Pflichtfeld. Bitte geben Sie die UVP centgenau an',
          rrpUsedInCalculationMessage:
            'Ausschlaggebend für die Kalkulation der Raten ' +
            'ist die unverbindliche Preisempfehlung. Ein verminderter ' +
            'Kaufpreis hat keine Auswirkung auf das Leasing.',
          validationErrors: {
            salesPricePart1:
              'Der vereinbarte Verkaufspreis (inkl. Verpackung & Versand)',
            rrpPart1: 'Der UVP (inkl. Verpackung & Versand)',
            pricePart2: ' muss zwischen {{min}} und {{max}} liegen'
          }
        },
        useTaxDeductionGroup: {
          useTaxDeductionLabel: 'Wandlung der Rate',
          useTaxDeductionHelpText: 'Vorsteuerabzug',
          useTaxDeductionOption1: 'Ja (zzgl. MwSt.)',
          useTaxDeductionOption2: 'Nein (inkl. MwSt.)',
          useTaxDeductionRequiredError:
            'Pflichtfeld. Bitte wählen Sie eine Option aus'
        },
        optionRequiredValidationError:
          'Pflichtfeld. Bitte wählen Sie eine Option aus',
        mappingNotice:
          'Bitte achten Sie auf die korrekte Zuordnung und Deklaration des Zubehörs.',
        reasonMaxCharactersErrorMessage:
          'Der Text darf höchstens 200 Zeichen beinhalten.',
        bikePrice: 'Fahrradpreis',
        shippingCosts: 'Verpackung & Versand',
        newOrderForm: {
          generalValidationError:
            'Einige Angaben fehlen noch. Bitte füllen Sie alle markierten Pflichtfelder korrekt aus.'
        },
        bikeDetailGroup: {
          bikeDetailHeading: 'Fahrraddetails',
          bikeDetailBody:
            'Geben Sie hier die Details aus Ihrem Fachhändlerangebot ein.',
          bikeModelYearLabel: 'Modelljahr',
          bikeModelAccordingToManufacturerLabel: 'Modell laut Hersteller',
          bikeDeliverySelection: {
            pickupOption: 'Abholung beim Fachhändler',
            shipmentOption: 'Versand',
            bikeDeliveryLabel: 'Fahrradübernahme',
            bikeDeliveryError: 'Pflichtfeld. Bitte wählen Sie eine Option aus'
          },
          bikeBrandSelection: {
            bikeBrandLabel: 'Fahrradmarke',
            bikeBrandSelectNoBikeBrandFound:
              'Ihre Fahrradmarke wird nicht aufgelistet? Überprüfen Sie bitte die Eingabe auf Tippfehler. Noch immer nicht gefunden? Kontaktieren Sie uns: support@jobrad.org',
            bikeBrandSelectPlaceholder: 'Wählen Sie eine Fahrradmarke aus',
            newBikeBrandCancel: 'Zurück zum Antrag',
            newBikeBrandWarning:
              'Sie haben eine neue Marke vorgeschlagen, beachten Sie bitte, dass Ihr Antrag erst abgeschickt werden kann, nachdem wir dies überprüft haben.',
            newBikeBrandModalText1:
              'Bitte überprüfen Sie sorgfältig, ob die Marke korrekt eingetragen wurde und nicht in der Datenbank existiert.',
            newBikeBrandModalText2:
              'Nach erfolgter Überprüfung Ihres Markenvorschlags können Sie Ihren Antrag ganz normal abschließen.',
            newBikeBrandModalText3:
              'Anschließend werden Sie über Ihren Bearbeitungsstand per E-Mail benachrichtigt.',
            newBikeBrandModalSuggest: 'Diese Fahrradmarke vorschlagen',
            bikeBrandSelectionError:
              'Pflichtfeld. Bitte wählen Sie die Fahrradmarke aus',
            bikeBrandModalError: 'Geben Sie bitte die Fahrradmarke an',
            bikeBrandIsNotFound: 'Haben Sie die Fahrradmarke nicht gefunden?',
            suggestNewBikeBrandButton: 'Schlagen Sie eine neue Fahrradmarke vor'
          },
          bikeCategorySelection: {
            bikeCategoryLabel: 'Fahrradkategorie',
            bikeCategoryError:
              'Pflichtfeld. Bitte wählen Sie eine Fahrradkategorie aus',
            bikeCategorPlaceholder: 'Wählen Sie eine Fahrradkategorie aus'
          },
          bikeColorSelection: {
            bikeColorLabel: 'Fahrradfarbe'
          },
          bikeModelSelection: {
            bikeModelLabel: 'Fahrradmodell laut Hersteller',
            bikeModelError: 'Pflichtfeld. Bitte geben Sie das Fahrradmodell an',
            bikeModelHelptext:
              'Bitte geben Sie die vollständige Modellbezeichnung an'
          },
          bikeModelYearSelection: {
            bikeModelYearLabel: 'Modelljahr (Format: JJJJ)',
            bikeModelYearRequiredError:
              'Pflichtfeld. Bitte geben Sie das Modelljahr an',
            bikeModelYearFormatError:
              'Die Eingabe muss eine Jahreszahl im Format JJJJ sein'
          },
          bikeSizeSelection: {
            bikeSizeLabel: 'Fahrradgröße',
            bikeSizeHelptext:
              'z.B. in cm oder Zoll oder auch in Größen wie S, M, L, XL'
          },
          bikeTypeSelection: {
            bikeTypeError: 'Pflichtfeld. Bitten wählen Sie den Fahrradtyp aus',
            bikeTypePlaceholder: 'Wählen Sie einen Fahrradtyp aus'
          }
        },
        bikeWorkWay: {
          bikeWorkWayHeading: 'Arbeitsweg',
          bikeWorkWayLabel: 'Arbeitsweg in km',
          bikeWorkWayRequiredError: 'Bitte gebe den Arbeitsweg in km ein'
        },
        bikeAccessoriesGroup: {
          bodyIfNoBikeTypeSelected: 'Um mit der Zubehörauswahl fortzufahren,',
          bikeTypeSelectionLink: 'wählen Sie zuerst den Fahrradtyp aus',
          body: 'Welches Zubehör leasingfähig ist, finden Sie unter dem Link',
          informationSheetLink: 'Leasingfähiges Zubehör für Ihr JobRad',
          accessoriesDisabledFromOffer:
            'Sie können Ihrem Fahrrad individuelles Zubehör hinzufügen, z.B. Lenker, Sattel, Lichter usw. Wenden Sie sich dafür bitte an Ihren Fachhändler, bevor Sie den Bestellantrag erstellen. Ein nachträgliches Hinzufügen ist nicht möglich!',
          addAccessoriesText: 'Zubehör hinzufügen',
          addAdditionalAccessoriesButton: 'Weiteres Zubehör hinzufügen',
          saveAccessoriesText: 'Zubehör speichern',
          accessoriesAddedMessage: 'Leasinfähiges Zubehör hinzugefügt',
          accessoriesDeletedMessage: 'Leasinfähiges Zubehör gelöscht',
          accessoriesEditedMessage: 'Leasinfähiges Zubehör bearbeitet',
          accessoryCategory: 'Zubehörkategorie',
          accessoryBrand: 'Marke',
          accessoryModel: 'Modellbezeichnung',
          accessorySalesPrice: 'Verkaufspreis',
          accessoryRRP: 'UVP',
          actionButton: 'Position hinzufügen',
          editButtonAriaLabel: 'Bearbeiten',
          deleteButtonAriaLabel: 'Löschen',
          newBikeAccessoriesModal: {
            editAccessoryHeader: 'Zubehör bearbeiten',
            addAccessoriesBodyPart1:
              'Fügen Sie das gesondert ausgewiesene leasingfähige Zubehör aus dem Angebot des Fachhändlers hinzu.',
            addAccessoriesBodyPart2:
              'Bitte tragen Sie entsprechend die Angaben zu Marke und Modell des Zubehörteils in das unten stehende Formular ein.',
            formValidationError:
              'Einige Angaben müssen ergänzt oder angepasst werden.  Prüfen Sie bitte alle hervorgehobenen Pflichtfelder.',
            accessoryCategoryPlaceholder:
              'Wählen Sie eine Zubehörkategorie aus',
            accessoryCategoryRequiredError:
              'Pflichtfeld. Bitte wählen Sie eine Zubehörkategorie aus',
            accessoryBrandRequiredError:
              'Pflichtfeld. Bitte geben Sie die Marke des Zubehörs an',
            accessoryModelRequiredError:
              'Pflichtfeld. Bitte geben Sie die Modellbezeichnung an',
            accessoryPriceRequiredError:
              'Pflichtfeld. Bitte geben Sie den vereinbarten Kaufpreis centgenau an',
            accessoryPriceHelpText:
              'Bitte geben Sie den vereinbarten Kaufpreis centgenau an',
            accessoryRrpRequiredError:
              'Pflichtfeld. Bitte geben Sie die UVP centgenau an',
            accessoryRrpHelpText: 'Bitte geben Sie die UVP centgenau an'
          },
          validationErrors: {
            invalidCategory: 'Invalide Kategorie',
            missingInput: 'Bitte fülle alle Felder aus.',
            maxRrp: 'Der UVP darf max. {{value}} € betragen.',
            maxPrice: 'Der Verkaufspreis darf max. {{value}} € betragen.',
            minRrp: 'Der UVP muss min. {{value}} € betragen.',
            maxQtyUpperCategory:
              'Die maximale Anzahl für die Hauptkategorie {{value}} ist erreicht',
            maxQty:
              'Die maximale Anzahl für die Unterkategorie {{value}} ist erreicht.',
            maxQtyUpperCategoryAndMaxQty:
              'Die maximale Anzahl für die Hauptkategorie {{mainValue}} und die Unterkategorie {{value}} ist erreicht'
          },
          bikeAccessoriesTable: {
            title: 'Hinzugefügtes Zubehör',
            invalidAccessoryCategory: 'Invalide Zubehör-Kategorie',
            actionsHeader: 'Aktionen',
            actionsDeleteButton: 'Löschen',
            actionsEditButton: 'Bearbeiten'
          },
          bikeAccessoriesModal: {
            accessoriesModalCancel: 'Nein',
            accessoriesModalConfirm: 'Ja',
            deleteAccessory: 'Zubehör löschen',
            deleteAccessoryText1: 'Möchtest Du das Zubehör',
            deleteAccessoryText2: 'endgültig löschen?'
          }
        },
        createOrderWarningMessages: {
          createOrderWarningMessagesTitle:
            'Es gibt wichtige Informationen zu beachten',
          createOrderWarningMessagesHint:
            'Willst Du den Antrag jedoch trotzdem freigeben?',
          maxOrderCountReachedManager:
            'Der Mitarbeiter hat die maximale Anzahl an erlaubten Bestellungen erreicht.',
          maxOrderCountReachedManagerTitle:
            'Maximale Anzahl der Bestellungen erreicht',
          maxOrderCountReachedEmployee:
            'Sie können neue Anträge nur als Entwurf speichern. Bei Fragen sprechen Sie mit Ihrem Ansprechpartner.',
          maxOrderCountReachedEmployeeTitle:
            'Sie haben die maximale Anzahl an Anträgen erreicht',
          maxOrderCountReachedEmployeeError:
            'Sie haben die maximale Anzahl an Anträgen erreicht. Sie können neue Anträge nur als Entwurf speichern. Bei Fragen sprechen Sie mit Ihrem Ansprechpartner.',
          brandNotPublished:
            'Die von Ihnen gewählte Marke liegt nicht im System vor. Ihre Bestellung wird sich aus diesem Grund etwas verzögern und somit kann dieser Antrag nur als Entwurf gespeichert werden. Wir informieren Sie über den weiteren Verlauf per E-Mail.',
          supplierNotPublished:
            'Ihr Fachhändler ist noch kein JobRad-Partner. Ihre Bestellung wird sich aus diesem Grund etwas verzögern und somit können Sie diesen Antrag nur als Entwurf speichern. Wir informieren Sie über den weiteren Verlauf per E-Mail.',
          orderCreationBlockedManager:
            'Aufgrund eines gemeldeten Versicherungsfalls können Sie aktuell keinen neuen Antrag stellen. Die Prüfung, ob bereits gezahlte Leasingraten angerechnet werden können, dauert eine Weile. Sie erhalten eine E-Mail mit weiteren Informationen, sobald die Bearbeitung abgeschlossen ist.',
          orderCreationBlockedEmployee:
            'Aufgrund eines gemeldeten Versicherungsfalls können Sie aktuell keinen neuen Antrag anlegen. Eventuell haben Sie Anspruch auf die Anrechnung bereits gezahlter Leasingraten. Die Prüfung kann einige Zeit in Anspruch nehmen, wir bitten Sie daher um etwas Geduld. Sobald die Bearbeitung unsererseits abgeschlossen ist, erhalten Sie eine E-Mail mit allen weiteren Informationen.',
          orderCreationSaleBlockedEmployee:
            'Sie können im Moment keine Anträge erstellen. Bei Fragen sprechen Sie mit Ihrem Ansprechpartner.',
          maxTotalConversionRateExceeded: {
            employee:
              'Ihr maximales monatliches Umwandlungsbudget beträgt {{maxTotalConversionRate}}. Ihr derzeit noch verfügbares Umwandlungsbudget beträgt {{totalConversionRateRemaining}}. Sie können keine weiteren Anträge erstellen, sondern diese nur als Entwurf speichern. Bei Fragen zum Umwandlungsbudget wenden Sie sich bitte an Ihrem Ansprechpartner im Unternehmen.',
            employeeTitle:
              'Sie haben Ihr maximales monatliches Umwandlungsbudget erreicht',
            manager:
              'Das maximale monatliche Umwandlungsbudget beträgt {{maxTotalConversionRate}}. Das derzeit noch verfügbare Umwandlungsbudget beträgt {{totalConversionRateRemaining}}.',
            managerTitle:
              'Maximales monatliches Umwandlungsbudget überschritten'
          },
          contractsTotalPriceLimitsExceededMessage: {
            rrpUsedInCalculation:
              'Laut Rahmenvertrag sollte die UVP (inkl. Verpackung & Versand) zwischen {{min}} und {{max}} liegen.',
            salesPriceUsedInCalculation:
              'Laut Rahmenvertrag sollte der vereinbarte Verkaufspreis (inkl. Verpackung & Versand) zwischen {{min}} und {{max}} liegen.',
            contractsTotalPriceLimitsExceededMessageTitle:
              'Verkaufspreis ist nicht korrekt'
          }
        },
        deliveryAndSetupCostGroup: {
          shippingCostsLabel: 'Verpackung & Versand in €',
          modificationCostsLabel: 'Kosten für Montage, Aufbau und Umbau in €',
          bikeDeliveryHelpText:
            'Bitte geben Sie die Kosten für Verpackung und Versand centgenau an. ' +
            'Wenn Ihr Fahrrad kostenlos versendet wird, geben Sie bitte {{zeroAsCurrency}} an',
          modificationCostsHelpText:
            'Arbeitszeit für Montage, Aufbau und Umbau zur Individualisierung, z.B. Ergänzung oder Tausch von Fahrradkomponenten. ' +
            'Bitte geben Sie die Kosten für Montage, Aufbau und Umbau centgenau an',
          maxCostExceededMessage:
            'Die Eingabe muss kleiner oder gleich {{maxCost}} sein.',
          shippingCostsRequiredMessage:
            'Pflichtfeld. Bitte geben Sie die Kosten für Verpackung und Versand centgenau an',
          maxSumOfDeliveryAndSetupCostExceededMessage:
            'Die Summe der Kosten für Versand, Montage, Aufbau und Umbau darf {{maxSumOfDeliveryAndSetupCost}} nicht übersteigen.',
          bikeProvisioningTextParts: {
            deliveryCostAndModificationCost:
              'Kosten für Verpackung und Versand sowie für Montage, Aufbau und Umbau',
            modificationCost: 'Kosten für Montage, Aufbau und Umbau',
            maxCost:
              ' dürfen in Summe {{maxSumOfDeliveryAndSetupCost}} inklusive MwSt. nicht übersteigen.',
            hintDeliveryAndModificationCost:
              ' Höhere Kosten müssen entsprechend von Ihrem Fachhändler angepasst werden.'
          }
        },
        existingEmployeeSelect: {
          textNormal:
            'Bitte gebe den Namen oder Personalnummer des Mitarbeiters in das Feld ein. Sollte der Mitarbeiter nicht verfügbar sein, legen Sie einen neuen.',
          labelEmployee: 'Mitarbeiter',
          placeholder: 'Wählen Sie einen Mitarbeiter aus.',
          createNewEmployee: 'Neuen Mitarbeiter anlegen',
          selectEmployeeNotFound:
            'Der gesuchte Mitarbeiter wurde nicht gefunden. Erstellen Sie einen neuen.'
        },
        grantOption: {
          monthlyGrant: 'Monatlicher Zuschuss Ihres Arbeitgebers',
          maxGrant: 'beschränkt bis {{value}}'
        },
        supplierGroup: {
          supplierSelectTooltip:
            'Bitte gebe den Namen oder die PLZ Ihres Fachhändlers hier an. Stellen Sie sicher, dass Sie die richtige Filiale  ausgewählt haben. Sollte Ihr Fachhändler nicht verfügbar sein, erstellen Sie einen neuen.',
          supplierSelectPlaceholder: 'Geben Sie den Namen und die PLZ an',
          supplierSelectErrorMessage:
            'Pflichtfeld. Bitte wählen Sie einen Fachhändler aus',
          supplierSelectHelperText:
            'Tippen Sie Namen oder Postleitzahl ein und wählen Sie Ihren Fachhändler aus dem Dropdown-Menü',
          supplierSelectNoSupplierFound:
            'Der gesuchte Fachhändler wurde nicht gefunden. Schlagen Sie einen neuen vor.',
          suggestSupplier: 'Schlagen Sie einen neuen Fachhändler vor',
          noSupplierFound: 'Ist Ihr Fachhändler nicht dabei?',
          supplierIsNoJobradPartnerWarning:
            'Warnung! Ihr Fachhändler ist noch kein JobRad-Partner. Ihre Bestellung wird sich aus diesem Grund etwas verzögern. Wir informieren Sie über den weiteren Verlauf per E-Mail',
          partiallyBlockedDealerMessage:
            'Die Zusammenarbeit mit diesem Händler ist derzeit nicht möglich. Bitte einen anderen Händler auswählen.',
          formValidationError:
            'Um den neuen Fachhändler vorzuschlagen, füllen Sie bitte alle erforderlichen Felder korrekt aus.',
          offerReferenceTooltip:
            'Bitte tragen Sie zur einfachen Zuordnung die Angebotsreferenz Ihres vorliegenden Fachhändlerangebots ein.',
          offerReference: 'Angebotsreferenz',
          offerReferenceLabel: 'Fachhändlerangebotsreferenz',
          offerReferenceHint:
            'Bitte tragen Sie zur einfachen Zuordnung die Angebotsreferenz Ihres vorliegenden Fachhändlerangebots ein.',
          offerReferenceRequiredError:
            'Bitte gebe die Fachhändlerangebotsreferenz ein',
          offerReferenceLengthError:
            'Die Fachhändlerangebotsreferenz darf nur maximal 32 Zeichen haben'
        },
        newSupplierModal: {
          header: 'Fachhändler vorschlagen ',
          subtitle:
            'Ihr Fachhändler ist noch kein JobRad-Partner. Ihre Bestellung wird sich aus diesem Grund etwas verzögern. Wir informieren Sie über den weiteren Verlauf per E-Mail.',
          footerButtonCancel: 'Zurück zum Antrag',
          footerButtonSuggest: 'Neuen Fachhändler vorschlagen',
          requiredField:
            'Mit * gekennzeichnete Felder sind für die Registrierung erforderlich.'
        },
        newSupplierModalForm: {
          nameOfTheDealer: 'Name des Fachhändlers',
          contactPerson: 'Name der Kontaktperson',
          website: 'Webseite',
          websitePlaceholder: 'jobrad.org',
          websiteMessage: 'Die Eingabe muss eine gültige URL sein.',
          mail: 'E-Mail-Adresse',
          mailMessage: 'Die Eingabe muss eine gültige E-Mail-Adresse sein',
          adress: 'Straße und Hausnummer',
          adressFieldPlaceholder: 'Straße, Hausnummer',
          postalCode: 'Postleitzahl',
          postalCodePlaceholder: 'PLZ',
          city: 'Wohnort',
          country: 'Land'
        },
        servicesGroup: {
          servicesGroupHeader: 'JobRad Services',
          insuredForSpecialCategory:
            'Für das Leasing ist eine Vollkaskoversicherung verpflichtend. ' +
            'Die JobRad-Vollkaskoversicherung deckt S-Pedelecs leider nicht ab. ' +
            'Daher bist Du für eine Versicherung verantwortlich. ' +
            'Diese kann entweder vom Arbeitgeber oder vom Mitarbeitenden abgeschlossen werden. ' +
            'Die Anforderungen zur Versicherung eines S-Pedelec sind im Überlassungsvertrag beschrieben.',
          insuredBySelfForSpecialCategory:
            'Eine Vollkaskoversicherung ist für das Leasing verpflichtend. Normalerweise bietet ' +
            'Dein Arbeitgeber eine entsprechende Versicherung an. Bitte beachte jedoch, dass dies bei ' +
            'der Versicherung eines S-Pedelecs abweichen kann. Kontaktiere Deinen Arbeitgeber für weitere Informationen.',
          insuranceGroup: {
            insuranceGroupHeader: 'Fahrradversicherung',
            insuranceOptionsLabel: 'Wählen Sie eine Fahrradversicherung aus.',
            insuranceOptionsError:
              'Pflichtfeld. Bitte wählen Sie eine Option aus'
          },
          servicesOptionGroup: {
            servicesOptionGroupHeader: 'Serviceleistungen',
            servicesOptionLabel:
              'Wählen Sie Serviceleistungen zu Ihrem Fahrrad aus.',
            serviesOptionServiceLink:
              'Ausführliche Informationen zu den Serviceleistungen:',
            servicesInspektion: 'JobRad-Inspektion',
            servicesFullService: 'JobRad-FullService',
            servicesNoServiceOption: 'Ohne Serviceleistung',
            servicesOptionCompanyPayer: 'der Arbeitgeber',
            servicesOptionEmployeePayer: 'der Arbeitnehmer',
            servicesOptionServiceLabel:
              '{{serviceOptionName}} für {{serviceOptionPrice}} monatlich, {{serviceOptionPayer}} übernimmt die Servicerate'
          }
        },
        insuranceHelpText: {
          insuredByJobrad: 'Dein Fahrrad wird über JobRad versichert.',
          insuredByEmployer:
            'Dein Fahrrad wird über den Arbeitgeber versichert.',
          insuranceHelpText:
            'Mehr Infos zur Fahrradversicherung finden Sie in dem Dokument',
          insuranceLinkText: 'Vollkaskoversicherung und Mobilitätsgarantie.pdf'
        },
        priceOverview: {
          serverCommunicationError: 'Fehler beim Laden der Preise',
          conversionMeasures: 'Montage, Aufbau und Umbau',
          retailPriceCard: {
            title: 'Unverbindliche Preisempfehlung (UVP)',
            rRPIncludingShippingCosts: 'UVP (inkl. Verpackung & Versand)'
          },
          leasingPriceCard: {
            preRateDaily: 'Mögliche tägliche anteilige',
            leasingPriceInfo:
              'Die angezeigten Raten beziehen sich auf Ihr Bruttogehalt. Die ' +
              'tatsächliche monatliche Nettobelastung ist abhängig von ' +
              'Ihrer Einkommenshöhe und Ihrer Steuerklasse. Für eine ' +
              'unverbindliche Berechnung der Nettobelastung nutzen Sie den',
            leasingPriceInfoLink: 'Vorteilsrechner Ihres Arbeitgebers'
          }
        },
        priceCardsGroup: {
          leasingPriceCardTitle: 'Leasinginformationen',
          preRateCalculationLabelPart1: 'Mögliche tägliche anteilige',
          preRateCalculationLabelPart2: 'Umwandlungsrate'
        },
        dealerOfferRejectModal: {
          buttonLabel: 'Fachhändlerangebot ablehnen und entfernen',
          confirmButtonLabel: 'Ja, Fachhändlerangebot ablehnen',
          title: 'Fachhandlerangebot ablehnen?',
          body: `Der Klick auf "{{value}}" löscht das Angebot. Bitte beachten Sie:
                  Abgelehnte Angebote werden in der Übersicht nicht angezeigt. Wenn Sie die Angebotsdetails
                  noch einmal einsehen möchten, geben Sie bitte den Angebotscode erneut ein.`,
          inputLabel: 'Abbruchsgrund'
        },
        cancelOrderModal: {
          buttonLabel: 'Antrag abbrechen',
          confirmButtonLabel: 'Ja, Antrag abbrechen',
          cancelLabel: 'Nein',
          title: 'Antrag abbrechen?',
          body: `Wenn Sie auf "{{value}}" klicken, wird kein Antrag erstellt.`,
          inputLabel: 'Abbruchsgrund',
          cancelButton: 'Zurück zum Antrag',
          discardTitle: 'Antrag final verwerfen'
        },
        saveDraftModal: {
          buttonLabel: 'Entwurf speichern',
          confirmButtonLabel: 'Ja, Entwurf speichern',
          cancelLabel: 'Nein',
          title: 'Entwurf speichern?',
          body: `Sobald Sie auf "{{value}}" klicken, wird
                    dieser Antrag in unserem System gespeichert, aber noch nicht
                    abgesendet. Sie können den Antrag zu einem späteren
                    Zeitpunkt beliebig bearbeiten und fertigstellen.`
        },
        orderCreateModal: {
          buttonLabel: 'Bestellantrag erstellen',
          confirmButtonLabel: 'Ja, Antrag erstellen',
          title: 'Antrag erstellen?',
          bodyIfEmployee: `Wenn Sie auf "{{value}}" klicken, wird Ihr
                      Bestellantrag final erstellt und Sie können keine
                      Änderungen mehr vornehmen.`,

          bodyIfNotEmployee: `Änderungen können danach nicht mehr vorgenommen werden.
                      Bitte denke im Anschluss daran, den
                      Überlassungsvertrag zu unterschreiben und die notwendigen
                      Dokumente hochzuladen!`
        },
        orderCorrectionModal: {
          buttonLabel: 'Korrektur anfordern',
          confirmButtonLabel: 'Korrektur anfordern',
          title: 'Korrektur anfordern?',
          body: 'Wenn Sie auf {{value}} klicken, wird der durch den {{employee}} angelegte Antrag storniert.\nEin neuer Bestellantrag wird mit von Ihnen getätigten Änderungen erstellt und zur erneuten Prüfung an den Mitarbeiter übersendet.',
          inputLabel:
            'Bitte gebe den Grund für die Bearbeitung an. Dieser wird ebenfalls per E-Mail an den {{employee}} übersandt.'
        }
      },
      regularContractTermination: {
        howToUpdateEmailTooltip:
          'Dies ist nicht Ihre E-Mailadresse? Gerne schicken Sie uns diese an folgende Adresse: vertragsende@jobrad.org',
        rvaConfirmAddressModal: {
          header: 'Kontaktdaten ändern',
          subtitle:
            "Bitte gebe hier Ihre aktuellen Adressdaten und Telefonnummer ein und klicken anschließend auf 'Bestätigen'.",
          submitButtonLabel: 'Bestätigen',
          formValidationError:
            'Um den Bestellantrag zu erstellen, füllen Sie bitte alle erforderlichen Felder korrekt aus'
        }
      },
      contractTerminationProductTeam: {
        generic: {
          contractTerminationOptions: {
            returnBikeModal: {
              title: 'Fahrrad zurückgeben',
              cost: 'Rückgabekosten',
              address: 'Adresse für die Abholung',
              checkbox1:
                'Hiermit bestätige ich die Rückgabe des Fahrrads und verzichte auf die Möglichkeit das Fahrrad zu erwerben.',
              checkbox2:
                'Hiermit bestätige ich, dass meine Kontaktdaten an einen Transportdienstleister zwecks Abholung übermittelt werden dürfen.',
              confirm: 'Fahrrad verbindlich zurückgeben'
            },
            takeOverBike: 'Fahrrad übernehmen',
            inclTax: 'inkl. MwSt.',
            returnPrice: 'Rückgabekosten',
            noPriceAvailable: 'Kein Preis verfügbar',
            returnBike: 'Fahrrad zurückgeben',
            pleaseLeavePhoneNumberFirst:
              'Bitte gebe für die Abholung zuerst eine Telefonnummer an. Sie können diese bei Ihren Kontaktdaten ergänzen',
            genericError: {
              text: 'Bei der Verarbeitung trat ein Fehler auf. Bitte probieren Sie es nochmal oder schreiben Sie uns an <linktarget />.',
              supportMail: 'support@jobrad.org'
            }
          },
          keepBikeModal: {
            title: 'Fahrrad übernehmen',
            price: 'Übernahmepreis (inkl. MwSt.) ',
            checkbox:
              'Hiermit stimme ich dem Kauf verbindlich zu und akzeptiere die Allgemeinen Verkaufsbedingungen der JobRad GmbH.',
            agb: 'Allgemeine Verkaufsbedingungen der JobRad GmbH',
            confirm: 'Fahrrad verbindlich übernehmen'
          }
        },
        prematureContractTermination: {
          decisionPage: {
            headline: 'JobRad-Kaufangebot zum vorzeitigen Vertragsende',
            contractTerminationOptions: {
              returnBike: {
                modal: {
                  bodyText:
                    'Mit dieser Entscheidung wird der Rückgabeprozess veranlasst. Unser Partner Bravobike kümmert sich um eine unkomplizierte und kostenlose Abholung bei Ihnen Zuhause.'
                },
                bodyTextCard:
                  'Kaufangebot ablehnen und Rückgabeprozess starten.'
              },
              keepBike: {
                bodyTextCard: 'Ablösesumme bezahlen und Fahrrad behalten.',
                priceLabel: 'Ablösesumme (inkl. MwSt.)',
                modal: {
                  bodyText:
                    'Mit der Annahme des Kaufangebots verpflichte ich mich zur fristgerechten Zahlung der Ablösesumme per Überweisung. Mir ist bewusst, dass eine Zahlungsfristverlängerung nicht möglich ist.'
                }
              }
            },
            leasingDetails: {
              headline: 'Vertragsinformationen',
              requestedContractOfContractDate: 'Beantragtes Vertragsende',
              regularContractOfContractDate: 'Reguläres Vertragsende',
              outstandingInstallments: 'Auszugleichende Raten',
              offerValidUntil: 'Ablöseangebot gültig bis'
            },
            paymentInformation: {
              headline: 'Zahlungsinformationen',
              receiver: 'Empfänger',
              purpose: 'Verwendungszweck',
              iban: 'IBAN',
              bic: 'BIC',
              amount: 'Betrag',
              dueDate: 'Zahlungsfrist',
              message:
                'Geht die Zahlung nicht fristgerecht zum {{dueDate}} bei uns ein, kann der Vertrag nicht vorzeitig beendet werden und es müssen weitere Leasingraten gezahlt werden. Eine Zahlungserinnerung wird nicht erfolgen.',
              leasingRates: 'Leasingraten'
            },
            decisionConfirmation: {
              bikeTakenOver: {
                bodyText:
                  'Informationen zur Zahlung finden Sie auf dieser Seite sowie in einer weiteren E-Mail. Sobald der Leasingvertrag beendet wurde, erhalten Sie den Eigentumsnachweis für Ihr Fahrrad von uns per E-Mail.'
              },
              bikeRejected: {
                bodyText:
                  'In Ihrem Postfach finden Sie eine E-Mail, mit der wir Ihre Rückgabe bestätigen. Nach Vertragsende ({{endDate}}) meldet sich unser Partner Bravobike bei Ihnen, um einen Termin für die Rückgabe zu vereinbaren.'
              }
            }
          }
        },
        regularContractTermination: {
          decisionPage: {
            contractTerminationOptions: {
              returnBike: {
                modal: {
                  bodyText:
                    'Mit dieser Entscheidung wird der Rückgabeprozess veranlasst. Unser Partner Bravobike kümmert sich um ' +
                    'eine unkomplizierte und kostenlose Abholung bei Ihnen Zuhause. In Einzelfällen meldet sich Ihr ' +
                    'Fachhändler bezüglich der Rückgabe.'
                },
                weCollectYourBike:
                  'Wir kümmern uns um eine unkomplizierte Abholung bei Ihnen Zuhause.'
              },
              keepBike: {
                payForBikeAndKeepIt:
                  'Übernahmepreis bezahlen und Fahrrad behalten.',
                priceInclTax: 'Übernahmepreis (inkl. MwSt.)',
                modal: {
                  bodyText:
                    'Mit der Annahme des Kaufangebots verpflichte ich mich zur fristgerechten\n' +
                    '        Zahlung des Übernahmepreises per Überweisung. Eine Ratenzahlung oder\n' +
                    '        eine Zahlungsfristverlängerung sind leider nicht möglich.'
                }
              }
            }
          }
        },
        documentTitle: 'JobRad abholen lassen oder kaufen',
        headline: 'Mein JobRad-Vertragsende',
        bikeDetails: {
          bike: 'Fahrrad',
          contractNumber: 'Angebotsnummer',
          accessories: 'Zubehör',
          myBike: 'Meine Fahrraddaten',
          type: 'Fahrradtyp',
          size: 'Fahrradgröße',
          color: 'Fahrradfarbe',
          modelYear: 'Modelljahr',
          extended: 'Erweiterte Fahrraddaten anzeigen'
        },
        emailAddress: {
          show: {
            headline: 'Meine E-Mail-Adresse',
            email: 'E-Mail-Adresse',
            changeEmail: 'E-Mail-Adresse ändern',
            wasChanged: 'E-Mail-Adresse aktualisiert'
          },
          change: {
            headline: 'E-Mail-Adresse ändern',
            headline2: 'Geben Sie Ihre neue E-Mail-Adresse ein.',
            emailInputLabel: 'Neue E-Mail-Adresse',
            confirmEmailInputLabel: 'Neue E-Mail-Adresse bestätigen',
            hint: 'Wenn Sie Ihre E-Mail-Adresse anpassen, ändern Sie damit auch die E-Mail-Adresse für den Login im <linkToJobRadPortal />.',
            jobradPortal: 'meinJobRad-Portal',
            password: 'Passwort',
            cancel: 'Abbrechen',
            proceed: 'Weiter',
            showPassword: 'Passwort anzeigen',
            forgotPasswordLinkText: 'Ich habe mein Passwort vergessen',
            errors: {
              passwordValidationError: 'Das Passwort ist falsch.',
              emailPatternValidationError:
                'Bitte geben Sie eine gültige E-Mail-Adresse an.',
              emailsDoNotEqual: 'E-Mail-Adressen stimmen nicht überein.'
            }
          },
          emailChangeWasRequested: {
            headline: 'Schauen Sie in Ihr Postfach',
            text: 'Wir haben Ihnen gerade eine E-Mail an {{newEmail}} geschickt. Klicken Sie innerhalb der nächsten zwei Stunden auf den Link in der E-Mail, um Ihre Änderung zu bestätigen.',
            button: 'E-Mail nochmal senden'
          },
          emailConfirmationRequested: {
            errorExpired: {
              headline: 'Der Bestätigungslink ist leider nicht mehr gültig.',
              text: 'Dieser Link ist aus Sicherheitsgründen nur 2 Stunden gültig und darum bereits abgelaufen. Bitte probieren Sie es nochmal.'
            },
            errorInvalid: {
              headline: 'Hoppla, da ist etwas schief gelaufen.',
              text: 'Dieser Link ist leider nicht gültig.'
            },
            errorCanceled: {
              headline: 'Hoppla, da ist etwas schief gelaufen.',
              text: 'Sie haben bereits eine weitere Anfrage gestellt, um Ihre E-Mail-Adresse zu ändern. Daher ist dieser Link ungültig. Bitte verwenden Sie den aktuellen Bestätigungslink.'
            },
            errorEmailAlreadyInUse: {
              headline: 'Hoppla, das hat nicht geklappt.',
              text: 'Diese E-Mail-Adresse wurde bereits einem anderen JobRadler zugeteilt.'
            },
            errorUnexpected: {
              headline: 'Hoppla, da ist etwas schief gelaufen.',
              text: 'Bei der Verarbeitung trat ein Fehler auf. Bitte probieren Sie es nochmal oder schreiben Sie uns an <linktarget />.',
              supportMail: 'support@jobrad.org'
            },
            waiting: 'Bitte warten Sie einen kurzen Moment.'
          }
        },
        address: {
          title: 'Meine Adresse',
          text: 'Möchten Sie das Fahrrad zurückgeben, dann geben Sie hier den gewünschten Ort für die Abholung an.',
          changeAddress: 'Kontaktdaten bearbeiten',
          insertAddress:
            "Bitte gebe hier Ihre aktuellen Adressdaten und Telefonnummer ein und klicken anschließend auf 'Bestätigen'.",
          confirm: 'Bestätigen',
          countryLabel: 'Land',
          residenceLabel: 'Wohnort',
          zipCodeLabel: 'Postleitzahl',
          formValidationError:
            'Um die Adresse zu ändern, füllen Sie bitte alle erforderlichen Felder aus.',
          firstAndLastName: 'Vor- und Nachname',
          street: 'Straße',
          zipAndResidence: 'Wohnort'
        },
        leasingDetails: {
          headline: 'Vertragsinformationen',
          end: 'Vertragsende',
          infoTextFullServiceAndInsurance:
            'Sämtliche im Rahmen des Vertrages erworbenen Ansprüche, wie FullService und Versicherungsschutz, erlöschen mit dem Vertragsende und können nicht verrechnet werden. Ihre bis zum {{endLeasingDate}} gültige Service-ID lautet: {{serviceId}}.',
          infoTextInspectionAndInsurance:
            'Sämtliche im Rahmen des Vertrages erworbenen Ansprüche, wie Inspektion und Versicherungsschutz, erlöschen mit dem Vertragsende und können nicht verrechnet werden. Ihre bis zum {{endLeasingDate}} gültige Service-ID lautet: {{serviceId}}.',
          infoTextFullServiceAndNoInsurance:
            'Das im Rahmen des Vertrages erworbenene Budget für den FullService erlischt mit dem Vertragsende und kann nicht verrechnet werden. Ihre bis zum {{endLeasingDate}} gültige Service-ID lautet: {{serviceId}}.',
          infoTextInspectionAndNoInsurance:
            'Das im Rahmen des Vertrages erworbenene Budget für die Inspektion erlischt mit dem Vertragsende und kann nicht verrechnet werden. Ihre bis zum {{endLeasingDate}} gültige Service-ID lautet: {{serviceId}}.',
          infoTextInsuranceOnly:
            'Der Versicherungsschutz ist an den Leasingvertrag gekoppelt und erlischt mit dem Vertragsende.',
          serviceIdText:
            'Die bis zum {{endLeasingDate}} gültige Service-ID lautet: {{serviceId}}.'
        },
        requestCompletedSuccessfully: {
          bikeTakenOver: {
            documentTitle:
              'Kaufbestätigung: Sie haben sich entschieden das Fahrrad zu kaufen.',
            headline:
              'Vielen Dank! Sie haben sich entschieden, das Fahrrad zu übernehmen.',
            body:
              'In Ihrem Postfach finden Sie eine E-Mail, mit der wir Ihren Kauf bestätigen. ' +
              'Die Rechnung erhalten Sie ungefähr zwei Wochen nach dem Vertragsende ({{leasingEndDate}}). ' +
              'Sobald diese beglichen ist, gehört das Fahrrad Ihnen.',
            priceHeader: 'Übernahmepreis (inkl. MwSt.)',
            paymentHeader: 'Zahlungsart',
            paymentText: 'Überweisung nach Erhalt der Rechnung'
          },
          bikeReturned: {
            documentTitle:
              'Rückgabebestätigung: Sie haben sich entschieden das Fahrrad zurückzugeben.',
            headline:
              'Vielen Dank! Sie haben sich entschieden, das Fahrrad zurückzugeben.',
            body:
              'In Ihrem Postfach finden Sie eine E-Mail, mit der wir Ihre Rückgabe bestätigen. ' +
              'Nach Vertragsende ({{leasingEndDate}}) melden sich unser Partner Bravobike oder Ihr Fahrradhändler ' +
              'bei Ihnen, um einen Termin für die Rückgabe zu vereinbaren.',
            priceHeader: 'Kosten'
          }
        },
        expiredOffer: {
          headline: 'Es liegt kein Angebot vor.',
          text1:
            'Der Fall zum {{bikeCategory}} <bold>{{bikeName}}</bold> wird von uns geprüft. Möglicherweise liegt ein ' +
            'Versicherungsfall vor oder der Vertrag wurde bereits beendet.',
          text2: 'Haben Sie noch weitere Fragen?',
          text3: 'Sie erreichen uns unter <emailAddress />.'
        },
        expiredOfferContactMail: {
          pageTitle: 'Hier ging etwas schief.',
          headline: 'Hoppla, hier ging etwas schief.',
          text1:
            'Das Angebot für das {{bikeCategory}} <bold>{{bikeName}}</bold> ist nicht mehr verfügbar.',
          text2:
            'Bitte setzen Sie sich mit uns in Verbindung. Sie erreichen uns unter <emailAddress />.'
        },
        notYetAvailableOffer: {
          pageTitle: 'Die Seite gibt’s noch gar nicht.',
          headline: 'Hoppla, die Seite gibt’s noch gar nicht.',
          text1:
            'Diese Seite ist noch nicht verfügbar. Ab dem {{firstDayOfLastLeasingMonth}} finden Sie hier weitere Informationen ' +
            'zu Ihrem {{bikeCategory}} <bold>{{bikeName}}</bold>. ',
          text1FallbackDate: 'ersten Tag des Monats in dem Ihr Leasing endet',
          text2: 'Noch Fragen? Weitere Infos finden Sie unter der Seite ',
          faq: 'Häufige Fragen und Antworten zum Vertragsende.'
        },
        offerTransferredToEmployer: {
          pageTitle: 'Informationen zum Angebot',
          headline: 'Infos zum Angebot',
          text1:
            'Ihr Arbeitgeber hat uns informiert, dass er den Kauf oder die Rückgabe des {{bikeCategory}} <bold>{{bikeName}}</bold> vornehmen wird. ' +
            'Bei Fragen setzen sie sich mit Ihrem Arbeitgeber in Verbindung.',
          text2: 'Haben Sie noch Fragen? ',
          text3: 'Sie erreichen uns unter <emailAddress />.'
        },
        offerNotFound: {
          documentTitle: 'Angebot nicht vorhanden',
          headline: 'Es liegt kein Angebot vor.',
          message: {
            paragraph1:
              'Das Angebot zum Fahrrad <bold>{{bikeName}}</bold> ist leider nicht mehr verfügbar.',
            paragraph2:
              'Bitte setzen Sie sich mit Ihrem JobRad-Ansprechpartner in Ihrem Unternehmen in Verbindung.'
          },
          contact: 'Haben Sie noch weitere Fragen?',
          contactDetails: 'Sie erreichen uns unter <emailAddress />.'
        },
        offerExpired: {
          documentTitle: 'Angebot abgelaufen',
          headline: 'Dieses Angebot ist bereits abgelaufen.',
          message: {
            paragraph1:
              'Das Angebot zur vorzeitigen Vertragsbeendigung zum Monat {{endMonth}} für Ihr Fahrrad <bold>{{bikeName}}</bold> ist bereits abgelaufen.',
            paragraph2:
              'Bitte setzen Sie sich mit Ihrem JobRad-Ansprechpartner in Ihrem Unternehmen in Verbindung, um eine erneute Vertragsbeendigung zu beantragen.'
          },
          contact: 'Haben Sie noch weitere Fragen?',
          contactDetails: 'Sie erreichen uns unter <emailAddress />.'
        },
        offerAcceptedButPaymentMissed: {
          documentTitle: 'Kein Zahlungseingang',
          headline: 'Es wurde kein Zahlungseingang festgestellt.',
          message: {
            paragraph1:
              'Das Ablösebetrag für das Fahrrad <bold>{{bikeName}}</bold> ist nicht eingegangen. Darum konnte der Leasingvertrag nicht beendet werden.',
            paragraph2:
              'Bitte setzen Sie sich mit Ihrem JobRad-Ansprechpartner in Ihrem Unternehmen in Verbindung, um eine erneute Vertragsbeendigung zu beantragen.'
          },
          contact: 'Haben Sie noch weitere Fragen?',
          contactDetails: 'Sie erreichen uns unter <emailAddress />.'
        },
        componentsOnOtherPages: {
          offerLink: {
            offerLink: 'Angebot zum Vertragsende',
            offerLinkWhenAccepted: 'Kaufbestätigung öffnen',
            offerLinkWhenRejected: 'Rückgabebestätigung öffnen'
          }
        }
      },
      regularContractTerminationFaq: {
        headline: 'Mein JobRad Vertragsende',
        question1: 'Mein JobRad-Vertrag endet bald! Was kommt auf mich zu?',
        answer1p1:
          'Etwa vier Wochen vor dem Vertragsende melden wir uns mit einer E-Mail bei Ihnen. Wir beabsichtigen, Ihnen ' +
          'zu diesem Zeitpunkt ein Kauf- und ein Rückgabeangebot zu machen.',
        answer1p2:
          '<b>Entscheiden Sie sich das Fahrrad zu kaufen,</b> ' +
          'erhalten Sie von uns eine Kaufbestätigung. Die Rechnung erhalten Sie wenige Tage nach Vertragsende. ' +
          'Sobald Sie diese Rechnung per Überweisung begleichen, gehört das Fahrrad Ihnen und Sie können fröhlich weiterradeln.</p>',
        answer1p3:
          '<b>Entscheiden Sie sich das Fahrrad zurückzugeben,</b> kümmern sich unser Partner Bravobike oder Ihr Fachhändler ' +
          'um eine kostenlose Abholung bei Ihnen Zuhause. Im Sinne der nachhaltigen Kreislaufwirtschaft wird das ' +
          'Gebrauchtrad eine:n neue:n Besitzer:in glücklich machen.</p>',
        question2: 'Wie berechnet sich der Übernahmepreis?',
        answer2Part1ForRrpBaseContract:
          'Zum Vertragsende kalkulieren wir in unserem <linktarget>Vorteilsrechner</linktarget> mit einem erwarteten <b>Übernahmepreis ' +
          'von</b>',
        answer2Part1ForNonRrpBaseContract:
          'Zum Vertragsende kalkulieren wir in der Regel mit einem erwarteten <b>Übernahmepreis ' +
          'von</b>',
        calculator: 'Vorteilsrechner',
        answer2Part2ForRrpBaseContract:
          ' <b>{{percentage}} der Unverbindlichen Preisempfehlung</b> (brutto, inkl. Zubehör, Montage- und Versandkosten).',
        answer2Part2ForNonRrpBaseContract:
          ' <b>{{percentage}}</b> des ursprünglichen Netto-Kaufpreises zuzüglich der aktuell gültigen Mehrwertsteuer (derzeit 19%). ',
        answer2Part3ForNonRrpBaseContract:
          'Der <b>Netto-Kaufpreis</b> ist der zum Zeitpunkt des ursprünglichen Erhalts des Fahrrads vereinbarte Kaufpreis' +
          ' abzüglich der damals gültigen Mehrwertsteuer (16% oder 19%).',
        answer2Part4:
          ' Es kommen keine weiteren Kosten auf Sie zu, denn die Versteuerung des geldwerten Vorteils übernimmt JobRad für Sie.',
        question3: 'Ist es möglich, den Übernahmepreis in Raten zu bezahlen?',
        answer3:
          'Eine Ratenzahlung der Angebotssumme ist leider nicht möglich.\n' +
          'Wir arbeiten aber gerade daran, dies für zukünftige JobRadler:innen möglich zu machen. Unser Tipp: ' +
          'Für Unterstützung wenden Sie sich an bestehende Anbieter, zum Beispiel an Ihre Hausbank. ',
        question4: 'Wie kann ich meine E-Mail-Adresse ändern?',
        answer4:
          'Hat sich Ihre E-Mailadresse geändert? Möchten Sie unsere E-Mails an eine andere E-Mail-Adresse erhalten? ' +
          'Sie sind im nächsten Monat im Urlaub und können nicht auf Ihr Postfach zugreifen? In Ihrem <linktarget1 /> ' +
          'können Sie Ihre Kontaktdaten aktualisieren. Bei Problemen schreiben Sie uns ' +
          'mit dem Betreff “Kontaktadresse” an <linktarget2 />.',
        personalAreaInPortal: 'persönlichen Bereich im meinJobRad-Portal',
        leasingEndMail: 'leasingende@jobrad.org'
      },
      myOrdersAndOffers: {
        documentTitle:
          '{{notification}}Anträge und Angebote - {{companyName}} Jobrad Portal',
        documentTitleNotifications: {
          canceledOrder: 'Antrag abgebrochen - ',
          rejectedOffer: 'Angebot abgelehnt - ',
          savedOrderDraft: 'Entwurf gespeichert - '
        },
        header: 'Meine Anträge und Angebote',
        body: 'Verwalten Sie Ihre Anträge oder erstellen Sie einen neuen.',
        orders: {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          header_zero: 'Meine Anträge',
          header: 'Meine Anträge ({{count}})',
          toggleLabelText: 'Abgebrochene Anträge anzeigen',
          toggleLabelOn: 'Ein',
          toggleLabelOff: 'Aus',
          noOrdersYet:
            'Es liegen noch keine Anträge für Ihr Konto vor. Falls Sie ein Angebot haben, beantragen Sie es in der nächsten Sektion.',
          ordersTableHeader: {
            orderNumber: 'Antragsnummer',
            orderBike: 'Fahrrad',
            orderPriceBeforeTax: 'Bruttobetrag',
            orderDate: 'Antragsdatum',
            orderStatus: 'Status',
            orderActions: 'Verfügbare Aktionen'
          },
          ordersTableActions: {
            orderEdit: 'Antrag bearbeiten',
            uploadDocuments: 'Dokumente hochladen',
            confirmOrder: 'Antrag bestätigen',
            reportInsurance: 'Versicherungsfall melden'
          }
        },
        offers: {
          header: 'Meine Angebote',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          offer_one: 'Fachhändlerangebot',
          // eslint-disable-next-line @typescript-eslint/naming-convention
          offer_other: 'Fachhändlerangebote',
          bodyWithOffers:
            'Wir haben in unserem System <strong>{{amountOffers}} {{offer}}</strong> für Dich gefunden.',
          body: 'Sie haben zwei Optionen ein Fachhändlerangebot hinzuzufügen',
          optionList: [
            'Falls Sie keinen Angebotscode von Ihrem Händler erhalten haben, wählen Sie bitte die Option ‘Ohne Angebotscode’ aus',
            'Wählen Sie ‘Angebotscode eingeben’ aus und geben den vom Fachhändler direkt erhaltenen Angebotscode ein'
          ],
          accordion: {
            title: 'Informationen zum Angebotscode',
            body: "Der Angebotscode ist eine individuelle Kennung, die vom Fachhändler dem JobRad Leasingangebot zugewiesen wird. Dieser Code ermöglicht es, das Leasingangebot einem bestimmten Mitarbeiter zuzuordnen. Der Angebotscode wird üblicherweise zusammen mit dem Leasingangebot bereitgestellt und spielt eine wichtige Rolle beim Start des Bestellvorgangs. Er dient dazu, eine klare Identifizierung sicherzustellen und die reibungslose Abwicklung von JobRad Leasingverträgen zu gewährleisten. Bitte geben Sie den erhaltenen Angebotscode im Format '123456-01' in das entsprechende Feld unten ein."
          },
          errorBox: {
            message:
              'Um ein Fahrrad zu beantragen, wählen Sie bitte eine Option aus.'
          },
          linkTextOffers: 'Angebot ansehen',
          radioButtonWithoutCode: {
            text: 'Fahrraddaten selbst im nächsten Schritt eintragen',
            label: 'Ohne Angebotscode'
          },
          radioButtonWithCode: {
            label: 'Angebotscode eingeben'
          },
          inputField: {
            label: 'Angebotscode (Korrektes Format: 123456-01)',
            validationFailedMessage:
              'Der Angebotscode konnte nicht erfolgreich validiert werden. Bitte überprüfe Deine Internetverbindung',
            ariaLabel: 'Suche für Angebotscodes',
            svgTitle: {
              loadingSpinner: 'Angebotscode wird gesucht.',
              checkMark: 'Der Angebotscode hat das richtige Format.'
            }
          },
          linkTextToOffer: 'Angebot ansehen',
          buttonLabel: 'Weiter zur Beantragung'
        }
      },
      prematureTerminationForm: {
        dateBody: 'Austrittsdatum',
        yearSelectLabel: 'Austrittsjahr',
        monthSelectLabel: 'Austrittsmonat',
        yearSelectPlaceholder: 'Wählen Sie das Austrittsjahr aus',
        monthSelectPlaceholder: 'Wählen Sie den Austrittsmonat aus'
      }
    },
    personalInformation: {
      discardChangesModalBody:
        'Möchten Sie Ihre Änderungen wirklich verwerfen?',
      employeeAccountPersonalInfo: {
        editButtonLabel: 'Persönliche Informationen ändern',
        editModal: {
          header: 'Bearbeiten Sie Ihre persönlichen Daten',
          submitErrorHeadline: 'Fehler beim Speichern der Änderungen',
          discardChangesDiscard: 'Bearbeitung fortsetzen',
          validationErrors: {
            formValidationError:
              'Um die Änderungen zu speichern, fülle bitte alle erforderlichen Felder korrekt aus',
            genderRequired: 'Pflichtfeld. Bitte wählen Sie eine Option aus',
            nameRequired: 'Pflichtfeld. Bitte geben Sie Vor- und Nachnamen an',
            companyRequired:
              'Pflichtfeld. Bitte wählen Sie ein Unternehmen aus',
            phoneRequired: 'Pflichtfeld. Bitte geben Sie eine Telefonnummer an',
            personalNumberRequired:
              'Pflichtfeld. Bitte geben Sie eine Personalnummer an',
            addressRequired:
              'Pflichtfeld. Bitte geben Sie Straße und Hausnummer an',
            zipCodeRequired: 'Pflichtfeld. Bitte geben Sie die Postleitzahl an',
            cityRequired: 'Pflichtfeld. Bitte geben Sie den Wohnort an',
            countryRequired: 'Pflichtfeld. Bitte wählen Sie ein Land aus'
          }
        },
        editPersonalInfoModal: {
          sectionPersonalInformation: 'Persönliche Informationen',
          headingPersonalInformation: 'Persönliche Informationen bearbeiten',
          personalNumberInformation:
            'Änderungen der persönlichen Informationen gelten nur für neu angelegte Anträge, nicht für bereits gestellte.'
        },
        editEmailModal: {
          heading: 'E-Mail-Adresse bearbeiten',
          header: 'Ändern Sie Ihre E-Mail-Adresse',
          currentEmailAddressLabel: 'Deine aktuelle E-Mail-Adresse lautet',
          newEmailAddressLabel: 'Neue E-Mail-Adresse',
          newEmailAddressConfirmLabel: 'Neue E-Mail-Adresse bestätigen',
          saveNewEmailAddressLabel: 'Neue E-Mail-Adresse speichern',
          requestError:
            'Fehler beim Ändern der E-Mail-Adresse. Bitte probieren Sie es erneut.',
          submitButtonLabel: 'E-Mail-Adresse ändern',
          subtitle:
            'Ihre aktuelle E-Mail-Adresse lautet <bold>{{currentEmail}}</bold>.<br />' +
            'Mit dieser E-Mail-Adresse loggen Sie sich zukünftig auch in das ' +
            'meinJobRad-Portal ein.',
          validationErrors: {
            required: 'Pflichtfeld. Bitte geben Sie eine E-Mail-Adresse an',
            emailPattern: 'Bitte geben Sie eine gültige E-Mail-Adresse an',
            equalTo: 'E-Mail-Adressen stimmen nicht überein.'
          }
        },
        editPasswordModal: {
          sectionName: 'Passwort',
          heading: 'Passwort ändern',
          header: 'Ändern Sie Ihr Passwort',
          currentPasswordLabel: 'Aktuelles Passwort',
          requestError:
            'Fehler beim Ändern des Passworts. Bitte probiere es erneut.',
          submitButtonLabel: 'Passwort ändern',
          checkPasswordLabel: 'Überprüfe mein Passwort',
          confirmNewPasswordLabel: 'Neues Passwort bestätigen',
          saveNewPasswordLabel: 'Neues Passwort speichern',
          newPasswordLabel: 'Neues Passwort',
          passwordPolicies: {
            passwordPoliciesHeading:
              'Ihr neues Passwort muss mindestens beinhalten:',
            passwordCharacter: 'Zeichen',
            passwordUpperCaseLetter: 'Großbuchstabe',
            passwordLowerCaseLetter: 'Kleinbuchstabe',
            passwordDigit: 'Ziffer',
            passwordSpecialCharacter: 'Sonderzeichen',
            passwordAtLeast: 'mindestens'
          },
          validationErrors: {
            required: 'Bitte gebe dein aktuelles Passwort ein.',
            currentPassRequired:
              'Pflichtfeld. Bitte geben Sie das aktuelle Passwort an',
            newPassRequired:
              'Pflichtfeld. Bitte geben Sie das neue Passwort an',
            passwordsNotEqual: 'Passwörter stimmen nicht überein.',
            passwordDoesNotFulfillRequirements:
              'Das neue Passwort erfüllt nicht alle Passwortanforderungen',
            currentPassErrorFalse:
              'Das eingegebene Passwort stimmt nicht mit dem aktuellen Passwort überein.',
            newPassErrorMinLength:
              'Passwörter müssen mindestens 8 Zeichen haben, das eingegebene Passwort hat {{newPasswordLength}} Zeichen.',
            newPassErrorDatenleck:
              'Dieses Passwort wurde durch ein Datenleck in einem System, das nicht mit JobRad in Verbindung steht, aufgedeckt. Es besteht ein hohes Risiko, dass es missbraucht wird. Dieses Passwort wird daher hier nicht akzeptiert, Sie müssen ein anderes erstellen.'
          },
          passwordStrength: {
            passwordWeak: 'Schwach',
            passwordWeakHint: 'Sofort geknackt',
            passwordModerate: 'Gut genug',
            passwordModerateHint: 'Geknackt in 4 Monaten',
            passwordStrong: 'Stark',
            passwordStrongHint: 'In Jahrhunderten geknackt'
          },
          showPasswordLabel: 'Passwort anzeigen'
        }
      }
    },
    transferContract: {
      pageWarningBox:
        'Der Vertragseditor steht derzeit leider nicht zur Verfügung.'
    },
    reporting: {
      managerReportsList: 'Verfügbare Vorlagen',
      managerReportForm: 'Exportoptionen',
      managerReportDraft: 'Ungespeicherter Eintrag',
      managerReportsText:
        'Wählen Sie die Daten aus, die Sie exportieren möchten oder exportieren Sie alle Daten:',
      serverCommunicationErrorContentHeader:
        'Fehler beim Laden des Verlaufs Ihres Überlassungsvertrages',
      serverFieldsError: 'Some fields has errors.',
      serverDataError:
        'Für diesen Zeitraum ergab die Suche keine Ergebnisse. Bitte erweitern Sie den Zeitraum',
      serverDataSucces: 'Ihre Konfiguration wurde erfolgreich gespeichert.',
      noColumnsSelectedErrorMessage:
        'Es muss mindestens ein Spalte ausgewählt werden.',
      noStatusSelectedErrorMessage:
        'Es muss mindestens ein Status ausgewählt werden.',
      missingFieldsError: 'Es sind keine Pflichtfelder vorhanden.',
      dateRangeLabel: 'Zeitspanne',
      dateRangeHeader: 'Benutzerdefinierter Zeitraum',
      dateRangeOption6Months: 'Letzten 6 Monate',
      dateRangeOption1Month: 'Letzter Monat',
      dateRangeOptionCustom: 'Benutzerdefinierter Zeitraum',
      dateRangeOptionNoRange: 'Gesamter Zeitraum',
      orderStatusSelectionLabel: 'Status der Leasingaufträge',
      orderStatusSelectionPlaceholder: 'Wählen',
      orderStatusSelectionPillsHeader: 'Ausgewählte Status',
      reportColumnSelectionHeader: 'Exportoptionen',
      reportColumnSelectionBody:
        'In diesem Bereich können Sie die Exportdaten anpassen und neu anordnen.',
      reportColumnSelectionAllColumns: 'Alle Daten ohne Anpassung exportieren',
      reportColumnSelectionColumns: 'Verfügbare Datenfelder',
      reportColumnSelectionSearchPlaceholder: 'Suchfelder',
      reportEditModalTitle: 'Bearbeiten',
      reportDeleteModalTitle: 'Löschen',
      reportDeleteModalConfirmationMessage:
        'Möchten Sie den aktuellen Bericht wirklich löschen?',
      formExport: 'Export',
      formSave: 'Speichern',
      formConfirm: 'Bestätigen',
      formCancel: 'Abbrechen'
    }
  },
  uiLibrary: {
    select: {
      noOptions: 'Keine Übereinstimmungen gefunden.',
      loadingMessage: 'Auswahl',
      fetchingFailed:
        'Hier ging was schief, die Luft ist raus, das tut uns leid!',
      ariaLiveMessages: {
        ariaOnChange: {
          initialInputFocus: 'Option {{item}} ist aktuell ausgewählt.',
          newValue:
            'Bitte mindestens noch {{chars}} Zeichen eingeben, um die aktuelle Option zu ändern.',
          selectedOption: 'Option {{item}} wurde ausgewählt.'
        },
        ariaOnFilter: {
          resultsMessage: '{{resultsMessage}} für Sucheingabe {{inputValue}}'
        },
        ariaOnFocus: {
          focusedWithGroup:
            'Option {{item}} as der Gruppe {{group}} ist ausgewählt.',
          focused: 'Option {{item}} ist ausgewählt,',
          result: '{{actual}} von {{max}}.'
        },
        ariaOnGuidance: {
          chars: 'Bitte mindestens noch {{chars}} Zeichen eingeben.',
          searchResults:
            'Insgesamt sind {{searchResults}} Ergebnisse verfügbar.'
        }
      }
    },
    link: {
      newTabScreenReaderText: 'Öffnet in neuen Tab'
    },
    portal: {
      connectivityTroubleshootingMessage: {
        message:
          'Bitte überprüfen Sie Ihre Internetverbindung. Falls das Problem weiterhin besteht, versuchen Sie es bitte in 15 Minuten erneut.'
      },
      confirmModalWithWarningsAndErrorSupport: {
        titleForMultipleWarnings: 'Es gibt wichtige Informationen zu beachten'
      },
      monthlyLeasingRateSuffix: 'von monatl. Leasingrate'
    }
  },
  uiBuildingBlocks: {
    feedback: {
      actionStatusMessage: {
        feOrderCan:
          'Bei der Änderung des Mitarbeiterantrages ist ein Fehler aufgetreten. ' +
          'Bitte wenden Sie sich an einen JobRad Mitarbeiter.',
        feOrderEdit:
          'Beim Versuch den Antrag zu bearbeiten, ist ein Fehler aufgetreten. ' +
          'Bitte wenden Sie sich an einen JobRad Mitarbeiter.',
        fsOrderEdit:
          'Der Antrag wurde erfolgreich geändert. ' +
          'Der Mitarbeiter wurde per E-Mail darüber informiert ' +
          'und muss nun die notwendigen Dokumente hochladen.',
        fsOrderEditManger: 'Der Antrag wurde erfolgreich gespeichert.',
        fsOrderCreate:
          'Ihr Überlassungsvertrag wurde erfolgreich erzeugt. Sie müssen jetzt die notwendigen Dokumente hochladen.',
        fsOrderCancel: 'Der Antrag wurde erfolgreich abgebrochen.',
        fsSettingsEdit:
          'Die Portaleinstellungen wurden erfolgreich übernommen.',
        fsVvaTicket: 'Ihr Antrag wurde erfolgreich übermittelt.',
        feVvaTicket:
          'Die Anfrage wurde nicht erfolgreich abgesendet, bitte wenden Sie sich an unser Support Team'
      },
      error: {
        connectivityTroubleshootingMessage:
          'Bitte überprüfen Sie Ihre Internetverbindung. Falls das Problem weiterhin besteht, versuchen Sie es bitte in 15 Minuten erneut.',
        formValidationErrorBar: {
          additionalValidationError:
            'Einige Angaben müssen noch ergänzt oder angepasst werden:'
        }
      }
    },
    overlays: {
      jobradModal: {
        availableCharacters: 'Verfügbare Zeichen:'
      }
    }
  }
}

export default german
export type DeTranslations = typeof german
