import React from 'react'

import { Body } from '@uiLibrary/typography/Body/Body'

import { useI18n } from '../../../../i18n'

const JobradInfoFooterContent: React.FunctionComponent = () => {
  const { t } = useI18n()
  return (
    <div>
      <Body strong>{t('footer.aboutUsHeader')}</Body>
      <Body small>{t('footer.aboutUsText')}</Body>
    </div>
  )
}

export default JobradInfoFooterContent
