import type { Except } from 'type-fest'

import { config } from '../config'

import { Schemas } from './index'

export const orderFormConfigEndpoint = `${config.apiUrl}v1/order-form-config`
export type OrderFormConfig = Schemas['OrderFormConfig']
// TODO: Shouldn't this be Schemas['ContractServiceOption']??
export type ServiceOptionConfig = Schemas['ServiceOption']

export type DynamicFieldTemplate = Schemas['DynamicFieldTemplate']

// todo: the block below shares many similarities with the accessory category normalization. Should be merged
// or generalized later on
export type BikeTypeCategory = Schemas['Category']
type BikeTypeCategoryBase = Except<BikeTypeCategory, 'sub_categories'> & {
  order: number
}
export type BikeType = BikeTypeCategoryBase & {
  bikeCategoryGroups: number[]
}
export type BikeCategoryGroup = BikeTypeCategoryBase & {
  parent: number
  bikeCategories: number[]
}
export type BikeCategory = BikeTypeCategoryBase & {
  parent: number
}
export type BikeTypesAndCategories = {
  types: Partial<Record<number, BikeType>>
  categoryGroups: Partial<Record<number, BikeCategoryGroup>>
  categories: Partial<Record<number, BikeCategory>>
}

// normalizes the accessory category into a structure that allows lookup by id, distinguishes
// between types, categoryGroups and categories (=> categories of level 1, 2, 3 ), and references them by id (only down, not up)
// The complexity O(N), where N is the total amount of categories (level 1, 2, 3)
// todo: decide whether to put this into the reducer, the apiAsyncThunk, portal-backend or leave it here
export const normalizeBikeTypesAndCategories = (
  bikeTypes: BikeTypeCategory[]
): BikeTypesAndCategories => {
  const normalized: BikeTypesAndCategories = {
    types: {},
    categoryGroups: {},
    categories: {}
  }

  for (const [order, bikeType] of bikeTypes.entries()) {
    // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
    const { sub_categories: _, ...rest } = bikeType
    const bikeTypeWithIds: BikeType = {
      ...rest,
      order,
      bikeCategoryGroups: []
    }

    // populate categoryGroups
    for (const [order, group] of bikeType.sub_categories?.entries() || []) {
      bikeTypeWithIds.bikeCategoryGroups.push(group.id)

      // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
      const { sub_categories: _, ...rest } = group
      const groupWithId: BikeCategoryGroup = {
        ...rest,
        order,
        parent: bikeType.id,
        bikeCategories: []
      }
      // populate bikeCategories
      for (const [order, category] of group.sub_categories?.entries() || []) {
        groupWithId.bikeCategories.push(category.id)

        // eslint-disable-next-line @typescript-eslint/naming-convention,@typescript-eslint/no-unused-vars
        const { sub_categories: _, ...categoryNew } = category
        normalized.categories[category.id] = {
          ...categoryNew,
          parent: group.id,
          order
        }
      }

      normalized.categoryGroups[groupWithId.id] = groupWithId
    }

    normalized.types[bikeTypeWithIds.id] = bikeTypeWithIds
  }

  return normalized
}
