import React, { ReactNode, useRef, useState, useEffect } from 'react'
import className from 'classnames'
import { OverlayTrigger, OverlayTriggerProps, Popover } from 'react-bootstrap'

import styles from './Tooltip.module.scss'

type TooltipProps = Omit<OverlayTriggerProps, 'overlay'> & {
  id: string
  children: ReactNode
  theme?: 'dark' | 'light'
  tooltip: ReactNode
  classNameOverride?: string
}

const Tooltip: React.FunctionComponent<TooltipProps> = ({
  id,
  children,
  theme,
  tooltip,
  classNameOverride
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const triggerEl = useRef<HTMLButtonElement | null>(null)
  let timeoutId: number | null = null
  const timeoutInMilliseconds = 800

  const closeTooltipAfterTimeout = (): void => {
    timeoutId = window.setTimeout(() => {
      setIsOpen(false)
    }, timeoutInMilliseconds)
  }

  const tooltipOpen = (): void => {
    if (isOpen) return
    timeoutId !== null && window.clearTimeout(timeoutId)
    setIsOpen(true)
  }

  const tooltipClose = (): void => {
    timeoutId !== null && window.clearTimeout(timeoutId)
    closeTooltipAfterTimeout()
  }

  const handleEscapeKey = (event: KeyboardEvent): void => {
    if (event.key === 'Escape') {
      setIsOpen(false)
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', handleEscapeKey)

    return () => {
      document.removeEventListener('keydown', handleEscapeKey)
    }
  }, [])

  return (
    <OverlayTrigger
      trigger={[]}
      show={isOpen}
      placement="top"
      overlay={
        <Popover
          id={id}
          bsPrefix="popover jobRadPopOver"
          onMouseEnter={() => {
            timeoutId !== null && window.clearTimeout(timeoutId)
          }}
          onMouseLeave={closeTooltipAfterTimeout}
          className={className(
            classNameOverride,
            styles.jobRadPopOver,
            theme === 'light'
              ? styles['jobRadPopOver--light']
              : styles['jobRadPopOver--dark']
          )}
        >
          <Popover.Content>{tooltip}</Popover.Content>
        </Popover>
      }
    >
      {/* Wrapping button needed to allow :disabled elements to open the tooltip */}
      <button
        ref={triggerEl}
        className={className('d-inline-block', styles.tooltipButton)}
        onClick={() => setIsOpen(!isOpen)}
        onMouseLeave={closeTooltipAfterTimeout}
        onMouseEnter={tooltipOpen}
        onFocus={tooltipOpen}
        onBlur={tooltipClose}
      >
        {children}
      </button>
    </OverlayTrigger>
  )
}

export default Tooltip
