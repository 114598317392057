import { useState, useCallback, useEffect, RefObject } from 'react'

import { NavigationItem } from '../NavItem'

import { useResizeObserver } from './useResizeObserver'

export type PriorityNavParams = {
  navigationItems?: NavigationItem[]
  visibleLinkListRef: RefObject<HTMLElement>
  imageRef: RefObject<HTMLElement>
  leftItemsRef: RefObject<HTMLElement>
}

// Reference: https://github.com/0xycvv/react-priority-navigation/blob/master/src/PriorityNav.tsx
export function usePriorityNavigation({
  navigationItems,
  visibleLinkListRef,
  imageRef,
  leftItemsRef
}: PriorityNavParams): {
  navItems?: NavigationItem[]
  overflowItems: NavigationItem[]
} {
  const [navItems, setNavItems] = useState(navigationItems)
  const [overflowItems, setOverflowItems] = useState<NavigationItem[]>([])
  const [visibleLinkListWidths, setVisibleLinkListWidths] = useState<number[]>(
    []
  )

  const leftItemWidth = useResizeObserver(leftItemsRef)

  const shrinkHeader = useCallback((): void => {
    if (!navItems) return
    setOverflowItems((removedItems) => [
      {
        ...navItems[navItems.length - 1]
      },
      ...removedItems
    ])
    setNavItems((value) => value?.slice(0, value.length - 1))
  }, [navItems])

  const growHeader = useCallback((): void => {
    setVisibleLinkListWidths((value) => value?.slice(0, value.length - 1))
    setNavItems((items) => {
      if (!items) return [...overflowItems.slice(0, 1)]
      return [...items, ...overflowItems.slice(0, 1)]
    })
    setOverflowItems((value) => value.slice(1))
  }, [overflowItems])

  const updateNav = useCallback((): void => {
    if (
      !leftItemsRef.current ||
      !imageRef.current ||
      !visibleLinkListRef.current
    ) {
      return
    }

    const availableSpace =
      leftItemsRef.current.clientWidth - imageRef.current.clientWidth

    if (visibleLinkListRef.current.clientWidth > availableSpace) {
      setVisibleLinkListWidths((widths) => [
        ...widths,
        visibleLinkListRef.current?.clientWidth || 0
      ])

      shrinkHeader()
    } else if (
      availableSpace > visibleLinkListWidths[visibleLinkListWidths.length - 1]
    ) {
      growHeader()
    }
  }, [
    growHeader,
    imageRef,
    leftItemsRef,
    shrinkHeader,
    visibleLinkListRef,
    visibleLinkListWidths
  ])

  useEffect(() => {
    updateNav()
  }, [updateNav, leftItemWidth, overflowItems, navItems])

  useEffect(() => {
    // Reset when navigation items change (e.g. switching from manager to employee)
    setOverflowItems([])
    setNavItems(navigationItems)
  }, [navigationItems])

  return { navItems, overflowItems }
}
