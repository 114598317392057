import * as Sentry from '@sentry/react'
import { shallowEqual } from 'react-redux'

import { config } from './config'
import { deriveHostingEnvironmentFromHostname } from './misc/environment'
import { selectSentryUserData } from './redux/slices/session'
import { subscribeToStoreChange } from './redux/store'

export const setupSentry = (): void => {
  // Sentry
  // configure sentry replay
  const replay = Sentry.replayIntegration({
    maskAllText: true,
    blockAllMedia: true
  })

  Sentry.init({
    dsn: 'https://faafc84dfaff4ec7bfc9b843a556acb2@sentry.dev.jobrad.org/2',
    sampleRate: 1,
    replaysOnErrorSampleRate: 0.2,
    autoSessionTracking: false,
    tunnel: `${config.apiUrl}v1/bugs`,
    environment: deriveHostingEnvironmentFromHostname(window.location.hostname),
    release: config.version,
    integrations: [replay],
    ignoreErrors: [
      'ResizeObserver loop completed with undelivered notifications.'
    ]
  })

  subscribeToStoreChange(selectSentryUserData, Sentry.setUser, shallowEqual)
}
