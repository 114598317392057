import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

import styles from './Body.module.scss'

type Props = PropsWithChildren<{
  small?: boolean
  strong?: boolean
  inline?: boolean
  id?: string
  className?: string
  lang?: string
}>

export const Body: React.FunctionComponent<Props> = ({
  children,
  small,
  strong,
  inline,
  id,
  className,
  lang
}) => {
  const internalClassName =
    small && strong
      ? styles['body-small-strong']
      : strong
      ? styles['body-strong']
      : small
      ? styles['body-small']
      : styles['body']

  const classNames = classnames(styles.body__base, internalClassName, className)
  const Tag = inline ? (strong ? 'strong' : 'span') : 'p'

  return (
    <Tag className={classNames} id={id} lang={lang}>
      {!inline && strong ? <strong>{children}</strong> : children}
    </Tag>
  )
}
