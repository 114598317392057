import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  OrdersSlice,
  updateOrderSliceWithNewOrderDetailStatus
} from '../slices/orders'
import {
  OrderDocument,
  orderDocumentsEndpoint,
  OrderDocumentUploadData,
  OrderDocumentUploadResponse
} from '../../api/documents'
import { buildRejectValue, createApiAsyncThunk } from '../apiAsyncThunk'

export const uploadOrderDocument = createApiAsyncThunk<
  OrderDocumentUploadResponse,
  OrderDocumentUploadData
>('orders/uploadOrderDocument', async (arg, thunkApi) => {
  const formData = new FormData()
  for (const [key, value] of Object.entries(arg.document)) {
    formData.append(key, value)
  }
  const response = await fetch(`${orderDocumentsEndpoint(arg.orderId)}`, {
    method: 'POST',
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      accept: 'application/json',
      'x-csrf-token': selectCsrfToken(thunkApi.getState())
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    body: formData
  })

  const responseBody = await response.json()

  if (!response.ok) {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return responseBody
})

export const addUploadOrderDocumentsFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(uploadOrderDocument.fulfilled, (state, action) => {
    const getUpdatedDocuments = (): OrderDocument[] => {
      const orderDocumentState = state.documents[action.meta.arg.orderId]
      if (
        orderDocumentState === undefined ||
        orderDocumentState.data === undefined
      ) {
        return [action.payload]
      } else {
        return [...orderDocumentState.data, action.payload]
      }
    }

    const afterDocumentAddition = {
      ...state,
      documents: {
        ...state.documents,
        [action.meta.arg.orderId]: {
          pending: false,
          data: getUpdatedDocuments()
        }
      }
    }

    return updateOrderSliceWithNewOrderDetailStatus(
      afterDocumentAddition,
      action.meta.arg.orderId,
      action.payload.order_portal_state
    )
  })
}
