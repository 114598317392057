import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgServiceEmpty: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.1776 1.70715C14.1539 1.7312 14.1292 1.7539 14.1037 1.7753L14.107 1.77865L12.6905 3.21266C12.3 3.608 12.3 4.24898 12.6905 4.64432L14.1047 6.07599C14.4952 6.47133 15.1284 6.47133 15.5189 6.07599L16.9354 4.64199L16.9387 4.64533C16.9599 4.61951 16.9823 4.59453 17.006 4.57048C17.5438 4.02612 18.4495 4.19398 18.5895 4.95068C18.9416 6.85456 18.3901 8.89598 16.935 10.369C15.3923 11.9308 13.2188 12.4631 11.2487 11.9704C11.1971 11.9575 11.1545 11.9597 11.1287 11.9656C11.1148 11.9688 11.1033 11.9777 11.0932 11.9879L5.62132 17.5274C4.44975 18.7134 2.55025 18.7134 1.37868 17.5274C0.207107 16.3413 0.207107 14.4184 1.37868 13.2324L6.85058 7.69294C6.86066 7.68273 6.86946 7.67108 6.87263 7.65701C6.87851 7.63093 6.88065 7.5878 6.8679 7.53558C6.38123 5.54117 6.907 3.34083 8.44975 1.77905C9.90485 0.305989 11.9214 -0.252296 13.8021 0.104193C14.5495 0.245881 14.7153 1.16279 14.1776 1.70715ZM8.80954 7.05001C8.48387 5.71541 8.83685 4.25049 9.86396 3.21071C10.1266 2.94484 10.4167 2.72367 10.7249 2.5472C10.1492 3.68832 10.3329 5.12102 11.2763 6.07599L12.6905 7.50765C13.6338 8.46261 15.049 8.64866 16.1762 8.06581C16.0019 8.37788 15.7834 8.67151 15.5208 8.93737C14.4937 9.97716 13.0466 10.3345 11.7283 10.0048C11.0755 9.84155 10.2731 9.9548 9.69544 10.5396L4.20711 16.0957C3.81658 16.491 3.18342 16.491 2.79289 16.0957C2.40237 15.7004 2.40237 15.0594 2.79289 14.664L8.28123 9.10797C8.85893 8.52314 8.9708 7.71088 8.80954 7.05001Z"
      fill="currentColor"
    />
    <path
      d="M15.2109 14.2225C14.8204 13.8271 14.1872 13.8271 13.7967 14.2225C13.4062 14.6178 13.4062 15.2588 13.7967 15.6541L15.0896 16.963L13.7967 18.2718C13.4062 18.6672 13.4062 19.3082 13.7967 19.7035C14.1872 20.0988 14.8204 20.0988 15.2109 19.7035L16.5038 18.3946L17.7967 19.7035C18.1872 20.0988 18.8204 20.0988 19.2109 19.7035C19.6015 19.3082 19.6015 18.6672 19.2109 18.2718L17.918 16.963L19.2109 15.6541C19.6015 15.2588 19.6015 14.6178 19.2109 14.2225C18.8204 13.8271 18.1872 13.8271 17.7967 14.2225L16.5038 15.5313L15.2109 14.2225Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgServiceEmpty
