import React, { forwardRef } from 'react'
import classNames from 'classnames'

import Icon from '@uiLibrary/Icon'

import styles from './Button.module.scss'

export type ButtonProps = {
  variant: 'primary' | 'secondary' | 'tertiary'
  label: string
  isLoading?: boolean
  isSubmit?: boolean
  onClick?: React.MouseEventHandler
  onMouseDown?: React.MouseEventHandler
  onBlur?: React.FocusEventHandler
  id?: string
  tabIndex?: number
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      label,
      isLoading,
      variant,
      isSubmit,
      onBlur,
      onClick,
      onMouseDown,
      id,
      tabIndex
    },
    ref
  ) => (
    <button
      ref={ref}
      id={id}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onBlur={onBlur}
      type={isSubmit ? 'submit' : 'button'}
      className={classNames(
        styles.button,
        styles[variant],
        label === '' ? styles.empty : undefined
      )}
      tabIndex={tabIndex}
    >
      {isLoading && (
        <span className={styles.icon}>
          <Icon
            variant="normal"
            iconName="buttonLoader"
            color={variant === 'primary' ? 'white' : 'green'}
          />
        </span>
      )}
      {label}
    </button>
  )
)
Button.displayName = 'Button'
