import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

import styles from './Hint.module.scss'

type Props = PropsWithChildren<{
  className?: string
}>

export const Hint: React.FunctionComponent<Props> = ({
  children,
  className
}) => {
  const classNames = classnames(styles['hint'], className)

  return <span className={classNames}>{children}</span>
}
