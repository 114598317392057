import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgDelete: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M7 1C6.31681 1 5.81855 1.4479 5.64758 2H2C1.44772 2 1 2.44772 1 3C1 3.55228 1.44772 4 2 4H12C12.5523 4 13 3.55228 13 3C13 2.44772 12.5523 2 12 2H8.35242C8.18145 1.4479 7.68319 1 7 1Z"
      fill="currentColor"
    />
    <path
      d="M3.99654 5.91701C3.95068 5.36663 3.46733 4.95764 2.91695 5.00351C2.36657 5.04937 1.95758 5.53272 2.00345 6.0831L2.42704 11.1661C2.51342 12.2027 3.37995 13.0001 4.42013 13.0001H9.57986C10.62 13.0001 11.4866 12.2027 11.573 11.1661L11.9965 6.0831C12.0424 5.53272 11.6334 5.04937 11.083 5.00351C10.5327 4.95764 10.0493 5.36663 10.0034 5.91701L9.57986 11.0001H4.42013L3.99654 5.91701Z"
      fill="currentColor"
    />
    <path
      d="M7.99999 7.00006C7.99999 6.44777 7.55228 6.00006 6.99999 6.00006C6.44771 6.00006 5.99999 6.44777 5.99999 7.00006V9.00006C5.99999 9.55234 6.44771 10.0001 6.99999 10.0001C7.55228 10.0001 7.99999 9.55234 7.99999 9.00006V7.00006Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgDelete
