import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgCalendar: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path d="M7 7V9H5V7H7Z" fill="currentColor" />
    <path d="M7 10V12H5V10H7Z" fill="currentColor" />
    <path d="M5 13V15H7V13H5Z" fill="currentColor" />
    <path d="M9 7H11V9H9V7Z" fill="currentColor" />
    <path d="M11 10H9V12H11V10Z" fill="currentColor" />
    <path d="M9 13H11V15H9V13Z" fill="currentColor" />
    <path d="M15 9V7H13V9H15Z" fill="currentColor" />
    <path d="M13 12V10H15V12H13Z" fill="currentColor" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5 2C5 1.44772 5.44772 1 6 1C6.55228 1 7 1.44772 7 2V3H13V2C13 1.44772 13.4477 1 14 1C14.5523 1 15 1.44772 15 2V3H17C18.1046 3 19 3.89543 19 5V17C19 18.1046 18.1046 19 17 19H3C1.89543 19 1 18.1046 1 17V5C1 3.89543 1.89543 3 3 3H5V2ZM3 5L3 17H17V5H3Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgCalendar
