import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgLocate: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 4.25C5.48122 4.25 4.25 5.48122 4.25 7C4.25 8.51878 5.48122 9.75 7 9.75C8.51878 9.75 9.75 8.51878 9.75 7C9.75 5.48122 8.51878 4.25 7 4.25ZM5.75 7C5.75 6.30964 6.30964 5.75 7 5.75C7.69036 5.75 8.25 6.30964 8.25 7C8.25 7.69036 7.69036 8.25 7 8.25C6.30964 8.25 5.75 7.69036 5.75 7Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 0C7.41421 0 7.75 0.335786 7.75 0.75V1.63488C10.1414 1.96611 12.0339 3.85857 12.3652 6.25L13.25 6.2551C13.6642 6.2551 14 6.59089 14 7.0051C14 7.41931 13.6642 7.7551 13.25 7.7551L12.3652 7.75C12.034 10.1415 10.1415 12.034 7.75 12.3652L7.76027 13.25C7.76027 13.6642 7.42448 14 7.01027 14C6.59606 14 6.26027 13.6642 6.26027 13.25L6.25 12.3652C3.85854 12.034 1.96606 10.1415 1.63484 7.75L0.75 7.74491C0.335786 7.74491 0 7.40912 0 6.99491C0 6.5807 0.335786 6.24491 0.75 6.24491L1.63485 6.25C1.9661 3.85857 3.85857 1.96612 6.25 1.63488V0.75C6.25 0.335786 6.58579 0 7 0ZM7.00001 3.08337C9.16141 3.08337 10.9139 4.83415 10.9167 6.99491L10.9167 7L10.9167 7.0051C10.9139 9.16247 9.16698 10.9112 7.01027 10.9167L7 10.9166L6.98973 10.9167C4.83134 10.9112 3.08334 9.15973 3.08334 7.00004C3.08334 4.83693 4.83689 3.08337 7.00001 3.08337Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgLocate
