import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgError: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <circle cx="7" cy="7" r="7" fill="currentColor" />
    <path d="M8 2H6V8H8V2Z" fill="white" />
    <path d="M8 10H6V12H8V10Z" fill="white" />
  </svg>
)
export default SvgError
