import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { subscribeToAllNewslettersEndpoint } from '@api/newsletterSettings'
import { NewsletterSettingsSlice } from '@redux/slices/newsletterSettings'

import { selectCsrfToken } from '../slices/session'
import { buildRejectValue, createApiAsyncThunk } from '../apiAsyncThunk'

export const subscribeToAllNewsletters = createApiAsyncThunk<void>(
  'subscribeToAllNewsletters',
  async (_, thunkApi) => {
    const response = await fetch(subscribeToAllNewslettersEndpoint, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      },
      method: 'POST'
    })

    if (!response.ok) {
      const responseBody = await response.json()
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }
  }
)

export const addSubscribeToAllNewslettersFulfilledCase = (
  builder: ActionReducerMapBuilder<NewsletterSettingsSlice>
): void => {
  builder.addCase(subscribeToAllNewsletters.fulfilled, () => ({
    error: undefined,
    pending: false,
    data: {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      subscribed_to_all_newsletters: true
    }
  }))
}
