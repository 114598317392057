import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgArrowDown: FunctionComponent<SVGProps<SVGSVGElement>> = (
  props: SVGProps<SVGSVGElement>
) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M8 2C8 1.44772 7.55228 1 7 1C6.44772 1 6 1.44772 6 2V9.58579L3.70711 7.29289C3.31658 6.90237 2.68342 6.90237 2.29289 7.29289C1.90237 7.68342 1.90237 8.31658 2.29289 8.70711L6.29289 12.7071C6.68342 13.0976 7.31658 13.0976 7.70711 12.7071L11.7071 8.70711C12.0976 8.31658 12.0976 7.68342 11.7071 7.29289C11.3166 6.90237 10.6834 6.90237 10.2929 7.29289L8 9.58579V2Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgArrowDown
