import { config } from '../config'

import { Schemas } from './index'

export type Session = Schemas['Session']
export type AccessRights = Schemas['AccessRights']
export type SessionEdit = Schemas['SessionEdit']
export type SessionUserEdit = Schemas['SessionUserEdit']
export type MaintenanceValues = Schemas['MaintenanceValues']
export type PriceAdjustmentInfo = Schemas['PriceAdjustmentInfo']
export type OrderCreation = Schemas['OrderCreation']

export const sessionFetchEndpoint = `${config.apiUrl}v1/session`
