import React, { PropsWithChildren } from 'react'
import classnames from 'classnames'

import styles from './FormFieldLabel.module.scss'

type Props = {
  label: string
  asLegend: boolean
  ariaDescribedby?: string
  required: boolean | undefined
  controlId: string
  className?: string
  labelId?: string
}

const FormFieldLabel: React.FunctionComponent<PropsWithChildren<Props>> = ({
  asLegend,
  required,
  ariaDescribedby,
  label,
  controlId,
  className,
  labelId,
  children
}) => {
  const requiredIcon = required ? (
    <span aria-hidden className={styles.requiredIcon}>
      *
    </span>
  ) : null

  if (asLegend)
    return (
      <legend className={styles.legend}>
        {label}
        {requiredIcon}
      </legend>
    )
  return (
    <>
      <label
        aria-describedby={ariaDescribedby}
        htmlFor={controlId}
        className={classnames(styles.label, className)}
        id={labelId}
      >
        {children}
        {label}
      </label>
      {requiredIcon}
    </>
  )
}

export default FormFieldLabel
