import { SlugAndAccessCode } from '@context/useAccessInfo'
import { Session } from '@api/session'
import { composeLogoutUri } from '@misc/uri'

import i18n from '../../i18n'

import { ItemGroup } from './Dropdown'
import { NavigationItem } from './NavItem'

export const getEmployeeNavigationItems = (): NavigationItem[] => {
  const employeeNavigationItems: NavigationItem[] = []
  employeeNavigationItems.push({
    label: 'Meine Anträge und Angebote',
    navigationTarget: {
      internal: true,
      pageName: 'My Orders And Offers',
      context: { event: null }
    }
  })

  employeeNavigationItems.push({
    label: `${i18n.t('navigation.account')}`,
    navigationTarget: {
      internal: true,
      pageName: 'Employee Account'
    }
  })

  return employeeNavigationItems
}

export const getEmployeeSectionForDropdownItems = (
  session: Session
): NavigationItem[] => {
  const employeeSectionItems: NavigationItem[] = getEmployeeNavigationItems()

  session.contract.employee_faq_url &&
    employeeSectionItems.push({
      label: `${i18n.t('navigation.employeeFaq')}`,
      navigationTarget: {
        internal: false,
        location: session.contract.employee_faq_url
      },
      withExternalIndicatorIcon: true
    })

  return employeeSectionItems
}

export const getEmployeeDropdownItems = (
  accessInfo: SlugAndAccessCode,
  session: Session
): ItemGroup[] => {
  return [
    {
      items: getEmployeeSectionForDropdownItems(session)
    },
    {
      items: [
        {
          label: `${i18n.t('navigation.logout')}`,
          navigationTarget: {
            internal: false,
            location: composeLogoutUri(
              accessInfo,
              session.contract.portal_settings.ext_portal_url
            )
          }
        }
      ]
    }
  ]
}
