import React, { ReactNode } from 'react'

import { Icon } from '@uiLibrary/Icon/Icon'

import Tooltip from '../Tooltip/Tooltip'

import styles from './IconTooltip.module.scss'

export type IconTooltipProps = {
  id: string
  children: ReactNode
}

const IconTooltip: React.FC<IconTooltipProps> = ({ children, id }) => (
  <Tooltip tooltip={children} placement="top" id={id} theme="light" rootClose>
    <span className={styles.iconContainer}>
      <Icon color="default" iconName="info" variant="normal" />
    </span>
  </Tooltip>
)

export default IconTooltip
