import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { ReportVersion, reportUEVVersionsEndpoint } from '../../api/report'
import { ManagerReportVersionsSlice } from '../slices/reports'

export const fetchReportVersions = createApiAsyncThunk<ReportVersion[], string>(
  'report/versions',
  async (args, thunkApi) => {
    const response = await fetch(reportUEVVersionsEndpoint, {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchReportVersionsPendingCase = (
  builder: ActionReducerMapBuilder<ManagerReportVersionsSlice>
): void => {
  builder.addCase(fetchReportVersions.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      pending: true
    }
  }))
}

export const addFetchReportVersionsFulfilledCase = (
  builder: ActionReducerMapBuilder<ManagerReportVersionsSlice>
): void => {
  builder.addCase(fetchReportVersions.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchReportVersionsRejectedCase = (
  builder: ActionReducerMapBuilder<ManagerReportVersionsSlice>
): void => {
  builder.addCase(fetchReportVersions.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
