import type { Action, AnyAction } from 'redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import {
  type TypedUseSelectorHook,
  useDispatch,
  useSelector
} from 'react-redux'

import { FetchError } from './apiAsyncThunk'
import type { RootState } from './reducers'
export type { RootState } from './reducers'

export { default as store } from './store'

type OptionalThunkArgument = undefined

// based on https://github.com/reduxjs/redux-thunk/issues/231#issuecomment-528028398
export type AsyncAction<R = void> = ThunkAction<
  R,
  RootState,
  OptionalThunkArgument,
  AnyAction
>
export type DispatchAction<T extends AnyAction = Action> = ThunkDispatch<
  RootState,
  OptionalThunkArgument,
  T
>

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector
export const useTypedDispatch: () => DispatchAction = useDispatch

export type ErrorAndPendingAwareState<T> = {
  pending: boolean
  error?: FetchError
  data?: T
}
