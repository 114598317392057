import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { OrderFormConfig } from '@api/orderFormConfig'

import { OrderDocument } from '../../api/documents'
import {
  Order,
  OrderMessages,
  OrderDetail,
  OrderListFilters
} from '../../api/order'
import { FetchError } from '../apiAsyncThunk'
import {
  addFetchOrderDocumentsFulFilledCase,
  addFetchOrderDocumentsPendingCase,
  addFetchOrderDocumentsRejectedCase
} from '../api/fetchOrderDocuments'
import { addUploadOrderDocumentsFulfilledCase } from '../api/uploadOrderDocument'
import { addDeleteOrderDocumentFulfilledCase } from '../api/deleteOrderDocument'
import {
  addFetchOrdersFulfilledCase,
  addFetchOrdersPendingCase,
  addFetchOrdersRejectedCase,
  orderListUpdateOptionsChanged
} from '../api/fetchOrders'
import {
  addFetchOrderDetailFulfilledCase,
  addFetchOrderDetailPendingCase,
  addFetchOrderDetailRejectedCase
} from '../api/fetchOrderDetail'
import {
  addFetchOrderMessagesFulfilledCase,
  addFetchOrderMessagesPendingCase,
  addFetchOrderMessagesRejectedCase
} from '../api/fetchOrderMessages'
import {
  addPatchOrderDetailFulfilledCase,
  addPatchOrderDetailPendingCase,
  addPatchOrderDetailRejectedCase
} from '../api/patchOrderDetail'
import {
  addPutOrderDetailFulfilledCase,
  addPutOrderDetailPendingCase,
  addPutOrderDetailRejectedCase
} from '../api/putOrderDetail'
import { RootState, ErrorAndPendingAwareState } from '../index'
import {
  addFetchOrderFormConfigFulfilledCase,
  addFetchOrderFormConfigPendingCase,
  addFetchOrderFormConfigRejectedCase
} from '../api/fetchOrderFormConfig'

export type OrderListData = {
  page: Order[]
  total: number
}

export type OrderListSorting = {
  column: string
  order: 'asc' | 'desc'
}

type OrderListPageOffset = number

export type OrderListUpdateOptions = Partial<
  Pick<OrderList, 'pageOffset' | 'filters' | 'sorting'>
>

export type OrderList = {
  error?: FetchError
  loading: boolean
  data?: OrderListData
  pageOffset: OrderListPageOffset
  filters: OrderListFilters
  sorting?: OrderListSorting
}

export type OrdersSlice = {
  list: OrderList
  details: Partial<Record<number, ErrorAndPendingAwareState<OrderDetail>>>
  messages: Partial<Record<number, ErrorAndPendingAwareState<OrderMessages>>>
  documents: Partial<Record<number, ErrorAndPendingAwareState<OrderDocument[]>>>
  orderFormConfig: ErrorAndPendingAwareState<OrderFormConfig>
}

export const initialState: OrdersSlice = {
  list: {
    loading: false, // TODO rename "loading" to "pending" for consistency
    pageOffset: 0,
    filters: []
  },
  details: {},
  messages: {},
  documents: {},
  orderFormConfig: {
    pending: false
  }
}

function getOrderListUpdateOptions(
  action: PayloadAction<OrderListUpdateOptions>
): OrderListUpdateOptions {
  if (!action.payload.filters) return action.payload
  return {
    ...action.payload,
    pageOffset: 0
  }
}

const ordersSlice = createSlice({
  name: 'orders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(orderListUpdateOptionsChanged, (state, action) => ({
      ...state,
      list: {
        ...state.list,
        ...getOrderListUpdateOptions(action)
      }
    }))

    addFetchOrdersPendingCase(builder)
    addFetchOrdersFulfilledCase(builder)
    addFetchOrdersRejectedCase(builder)

    addFetchOrderDetailPendingCase(builder)
    addFetchOrderDetailFulfilledCase(builder)
    addFetchOrderDetailRejectedCase(builder)

    addPatchOrderDetailPendingCase(builder)
    addPatchOrderDetailFulfilledCase(builder)
    addPatchOrderDetailRejectedCase(builder)

    addPutOrderDetailPendingCase(builder)
    addPutOrderDetailFulfilledCase(builder)
    addPutOrderDetailRejectedCase(builder)

    addFetchOrderDocumentsPendingCase(builder)
    addFetchOrderDocumentsFulFilledCase(builder)
    addFetchOrderDocumentsRejectedCase(builder)

    addUploadOrderDocumentsFulfilledCase(builder)

    addDeleteOrderDocumentFulfilledCase(builder)

    addFetchOrderMessagesPendingCase(builder)
    addFetchOrderMessagesFulfilledCase(builder)
    addFetchOrderMessagesRejectedCase(builder)

    addFetchOrderFormConfigPendingCase(builder)
    addFetchOrderFormConfigFulfilledCase(builder)
    addFetchOrderFormConfigRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareOrderListData = (
  state: RootState
): ErrorAndPendingAwareState<OrderListData> => {
  return {
    data: state.orders.list.data,
    pending: state.orders.list.loading,
    error: state.orders.list.error
  }
}
export const selectOrderListUpdateOptions = (
  state: RootState
): OrderListUpdateOptions => ({
  pageOffset: state.orders.list.pageOffset,
  filters: state.orders.list.filters,
  sorting: state.orders.list.sorting
})
export const selectOrderReadByManager = (
  state: RootState,
  orderId: number
): boolean | undefined => {
  // FIXME potential bug: index = -1 is not handled correctly.
  const orderListData = selectErrorAndPendingAwareOrderListData(state).data
  if (!orderListData) return undefined
  const index = orderListData.page.findIndex((x) => x.id === orderId)
  const orderDetail = orderListData.page[index]
  return orderDetail && orderDetail.read_by_manager
}
export const selectErrorAndPendingAwareOrderDetail = (
  state: RootState,
  orderId: number
): ErrorAndPendingAwareState<OrderDetail> => {
  const errorAndPendingAwareOrderDetail = state.orders.details[orderId]
  if (errorAndPendingAwareOrderDetail === undefined)
    return {
      error: undefined,
      pending: false,
      data: undefined
    }
  return errorAndPendingAwareOrderDetail
}
export const selectErrorAndPendingAwareOrderDocuments = (
  state: RootState,
  orderId: number
): ErrorAndPendingAwareState<OrderDocument[]> => {
  const errorAndPendingAwareOrderDocuments = state.orders.documents[orderId]
  if (errorAndPendingAwareOrderDocuments === undefined)
    return {
      error: undefined,
      pending: false,
      data: undefined
    }
  return errorAndPendingAwareOrderDocuments
}

export const selectErrorAndPendingAwareOrderMessages = (
  state: RootState,
  orderId: number
): ErrorAndPendingAwareState<OrderMessages> => {
  const errorAndPendingAwareOrderMessages = state.orders.messages[orderId]
  if (errorAndPendingAwareOrderMessages === undefined)
    return {
      error: undefined,
      pending: false,
      data: undefined
    }
  return errorAndPendingAwareOrderMessages
}
export const selectErrorAndPendingAwareOrderFormConfig = (
  state: RootState
): ErrorAndPendingAwareState<OrderFormConfig> => state.orders.orderFormConfig

/**
 * Since the upload or deletion of order documents can have side effects, the document upload endpoint can return a
 * new status for the corresponding order. This function returns a new order detail slice, in which the order details
 * of the associated order contain the new order status.
 */
export const updateOrderSliceWithNewOrderDetailStatus = (
  orderSlice: OrdersSlice,
  orderId: number,
  newPortalState: OrderDetail['portal_state'] | undefined
): OrdersSlice => {
  if (newPortalState === undefined) return orderSlice

  // check we have the corresponding order details in the slice and whether it has a different status
  const orderDetails = orderSlice.details[orderId]
  if (orderDetails === undefined) return orderSlice
  if (orderDetails.data === undefined) return orderSlice
  if (orderDetails.data.portal_state === newPortalState) return orderSlice

  // create a new order slice which contains order details with the new status
  return {
    ...orderSlice,
    details: {
      [orderId]: {
        ...orderDetails,
        data: {
          ...orderDetails.data,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          portal_state: newPortalState
        }
      }
    }
  }
}

export default ordersSlice.reducer
