import React, { Suspense } from 'react'
import { Outlet, useOutletContext } from 'react-router-dom'

import LoadingSpinner from '@uiLibrary/LoadingSpinner/LoadingSpinner'
import { useSession } from '@context/useSession'
import PageRedirect from '@pages/PageRedirect/PageRedirect'
import { useAccessInfo } from '@context/useAccessInfo'
import Helpdesk from '@features/helpdesk'

import Header from '../../Header'
import Main from '../../Main'
import Footer from '../../Footer/Footer'
import JobradErrorBoundary from '../../JobradErrorBoundary/JobradErrorBoundary'

import InvalidSessionModal from './InvalidSessionModal'

type Props = {
  portal: 'employee' | 'manager'
}

export type ContextType = {
  // todo: maybe add an indicator here whether it's the employee or the manager portal (replacing the usePortal hook)
}

/**
 * This component implements the layout for the portal pages. It is intended to be used as a parent route
 * for the actual pages. It supports both manager and employee layout.
 *
 * It provides state, which is shared among the portal pages, to its child routes via an OutletContext (https://reactrouter.com/en/main/hooks/use-outlet-context).
 */
export const PortalLayout: React.FunctionComponent<Props> = ({ portal }) => {
  const session = useSession()
  const accessInfo = useAccessInfo()

  const { portal_manager: isManager } = session.access_rights

  // only allow manager routes if permissions exist
  if (portal === 'manager' && !isManager) {
    return <PageRedirect accessInfo={accessInfo} />
  }

  return (
    <>
      <Header accessInfo={accessInfo} portal={portal} session={session} />
      <Main>
        <>
          {/* The purpose of this error boundary is the following. If an exception
        occurs when rendering the component tree below this error boundary,
        we want the user to be able to report the error using the helpdesk.
        If this error boundary did not exist, the exception would propagate
        to the global error boundary (defined in App.tsx), and consequently, the
        helpdesk would not get rendered. */}
          <JobradErrorBoundary>
            <InvalidSessionModal />
            <Suspense fallback={<LoadingSpinner />}>
              <Outlet />
            </Suspense>
          </JobradErrorBoundary>
        </>
      </Main>
      <Helpdesk />
      <Footer portal={portal} session={session} />
    </>
  )
}

export const usePortalLayout = (): ContextType => {
  return useOutletContext<ContextType>()
}
