import React from 'react'
import { NavLink } from 'react-router-dom'

import { getLocationContextEvent, TypedLocation } from '@hooks/useTypedLocation'
import { useAccessInfo } from '@context/useAccessInfo'
import { getUrlPathWithQueryAndHash } from '@hooks/useTypedNavigate'

import styles from './NavItem.module.scss'

export type NavigationItem = {
  navigationTarget: TypedLocation
  withExternalIndicatorIcon?: boolean
  label: string
}

export const NavItem: React.FunctionComponent<NavigationItem> = ({
  navigationTarget,
  label
}) => {
  const { slug, accessCode } = useAccessInfo()
  const to = navigationTarget.internal
    ? getUrlPathWithQueryAndHash(navigationTarget, slug, accessCode)
    : navigationTarget.location
  const event = navigationTarget.internal
    ? getLocationContextEvent(navigationTarget)
    : undefined
  const state = { event: event }
  return (
    <li className={styles['nav-item']}>
      <NavLink
        className={({ isActive }) => {
          return isActive ? styles.active : ''
        }}
        to={to}
        state={state}
        reloadDocument={!navigationTarget.internal}
      >
        {label}
      </NavLink>
    </li>
  )
}
