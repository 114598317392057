import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { Country, countriesEndpoint } from '../../api/country'
import { CountriesSlice } from '../slices/countries'

export const fetchCountries = createApiAsyncThunk<Country[]>(
  'fetchCountries',
  async (args, thunkApi) => {
    const response = await fetch(`${countriesEndpoint}`, {
      headers: { accept: 'application/json' }
    })
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchCountriesPendingCase = (
  builder: ActionReducerMapBuilder<CountriesSlice>
): void => {
  builder.addCase(fetchCountries.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      pending: true
    }
  }))
}

export const addFetchCountriesFulfilledCase = (
  builder: ActionReducerMapBuilder<CountriesSlice>
): void => {
  builder.addCase(fetchCountries.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchCountriesRejectedCase = (
  builder: ActionReducerMapBuilder<CountriesSlice>
): void => {
  builder.addCase(fetchCountries.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
