export type HostingEnvironment =
  | 'production'
  | 'integration'
  | 'staging'
  | 'sandbox'
  | 'unknown'

export const deriveHostingEnvironmentFromHostname = (
  hostname: string
): HostingEnvironment => {
  // list of domains can be found in https://wiki.dev.jobrad.org/display/LEASERAD/Domains
  if (/^portal\.jobrad.org$/i.test(hostname)) return 'production'
  if (/^portal\.staging\.jobrad.org$/i.test(hostname)) return 'staging'
  if (/^portal\.integration\.jobrad.org$/i.test(hostname)) return 'integration'
  if (/^portal\.[^.]+\.dev\.jobrad\.org$/i.test(hostname)) return 'sandbox'

  return 'unknown'
}
