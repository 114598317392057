import React, { useState } from 'react'

import { useTypedDispatch } from '@redux'
import { subscribeToAllNewsletters } from '@redux/api/subscribeToNewsletters'
import NewsletterSubscriptionFailedPane from '@uiLibrary/portal/NewsletterSubscriptionFailedPane'
import NewsletterSubscriptionPane from '@uiLibrary/portal/NewsletterSubscriptionPane'
import { FetchError } from '@redux/apiAsyncThunk'
import NewsletterSubscribedNotification from '@uiLibrary/portal/NewsletterSubscribedNotification'
import Modal from '@uiLibrary/Modal'
import ModalButtons from '@uiLibrary/Modal/ModalButtons'

import { useI18n } from '../../../../i18n'

import styles from './NewsletterModal.module.scss'

type ViewState =
  | { view: 'already subscribed' }
  | { view: 'subscription failed'; error: FetchError }
  | { view: 'not subscribed yet' }

type Props = {
  open: boolean
  onClose: () => void
}
const NewsletterModal: React.FunctionComponent<Props> = ({ open, onClose }) => {
  const { t } = useI18n()
  const [viewState, setViewState] = useState<ViewState>({
    view: 'not subscribed yet'
  })
  const dispatch = useTypedDispatch()
  const [backendResponseIsPending, setBackendResponseIsPending] =
    useState(false)

  const subscribe = async (): Promise<void> => {
    setBackendResponseIsPending(true)
    const resultAction = await dispatch(subscribeToAllNewsletters())
    if (subscribeToAllNewsletters.rejected.match(resultAction)) {
      setViewState({
        view: 'subscription failed',
        error: {
          apiError: resultAction.payload,
          indefiniteError: resultAction.error
        }
      })
    } else if (subscribeToAllNewsletters.fulfilled.match(resultAction)) {
      setViewState({ view: 'already subscribed' })
    }
    setBackendResponseIsPending(false)
  }

  const modalBody = (
    <div className={styles.newsletterModalBody} aria-live="polite">
      {viewState.view === 'already subscribed' && (
        <NewsletterSubscribedNotification />
      )}
      {viewState.view === 'subscription failed' && (
        <NewsletterSubscriptionFailedPane fetchError={viewState.error} />
      )}
      {viewState.view === 'not subscribed yet' && (
        <NewsletterSubscriptionPane />
      )}
    </div>
  )

  const modalButtons = ['already subscribed', 'subscription failed'].includes(
    viewState.view
  ) ? (
    <ModalButtons onSecondaryClick={onClose} secondaryLabel={t('closeLabel')} />
  ) : (
    <ModalButtons
      onPrimaryClick={subscribe}
      primaryIsLoading={backendResponseIsPending}
      primaryLabel={t('newsletterSettings.subscribeButtonLabel')}
      onSecondaryClick={onClose}
      secondaryLabel={t('closeLabel')}
    />
  )

  return (
    <Modal
      open={open}
      title={t('newsletterSettings.subscriptionHeader')}
      onClose={onClose}
    >
      {modalBody}
      {modalButtons}
    </Modal>
  )
}

export default NewsletterModal
