import React, { Suspense } from 'react'
import { Outlet } from 'react-router-dom'

import LoadingSpinner from '@uiLibrary/LoadingSpinner/LoadingSpinner'

import Header from '../../Header'
import Footer from '../../Footer/Footer'
import Main from '../../Main'

/**
 * This component implements the Layout for the public pages. It is intended to be used as a parent route for the
 * actual routes of the pages.
 */
export const PublicLayout: React.FunctionComponent = () => (
  <>
    <Header />
    <Main>
      <Suspense fallback={<LoadingSpinner />}>
        <Outlet />
      </Suspense>
    </Main>
    <Footer />
  </>
)
