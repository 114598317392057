import React, { ChangeEvent, useRef, useState } from 'react'
import classNames from 'classnames'

import SButton from '@uiLibrary/SButton'
import Body from '@uiLibrary/typography/Body'
import IconButton from '@uiLibrary/IconButton'
import Icon from '@uiLibrary/Icon'
import {
  fileToBase64,
  isFileSizeSupported,
  isFileTypeSupported
} from '@misc/file'

import { useI18n } from '../../../../../../../i18n'

import styles from './HelpdeskFileUpload.module.scss'

type HelpdeskFileUploadProps = {
  uploadedFile: string
  setUploadedFile: (uploadedFile: string) => void
}

const validFileTypes: Array<[string, string]> = [
  ['.jpg', 'image/jpeg'],
  ['.jpeg', 'image/jpeg'],
  ['.png', 'image/png'],
  ['.pdf', 'application/pdf']
]

const HelpdeskFileUpload: React.FunctionComponent<HelpdeskFileUploadProps> = ({
  uploadedFile,
  setUploadedFile
}) => {
  const { t } = useI18n()
  const fileUploadRef = useRef<HTMLInputElement>(null)

  const [fileSizeTooLarge, setFileSizeTooLarge] = useState(false)
  const [fileTypeError, setFileTypeError] = useState(false)

  const [uploadFileName, setUploadFileName] = useState<string>('')

  const onUploadFileClick = (): void => {
    if (!fileUploadRef.current) return
    fileUploadRef.current.click()
  }

  const onDeleteFileClick = (): void => {
    setUploadedFile('')
  }

  const onFileUploadChange = async (
    e: ChangeEvent<HTMLInputElement>
  ): Promise<void> => {
    const fileInput = e.currentTarget
    if (!fileInput.files || !fileInput.files.length) return

    const file = fileInput.files[0]
    if (isFileTypeSupported(file, validFileTypes)) {
      // Check if File is larger than 5mb
      if (!isFileSizeSupported(file, 5242880)) {
        setFileSizeTooLarge(true)
        return
      }

      const content = await fileToBase64(file)
      setUploadedFile(content)
      setUploadFileName(file.name)
    } else {
      setFileTypeError(true)
      setUploadedFile('')
    }
  }

  return (
    <>
      <input
        accept={validFileTypes.map((x) => x[0]).join(',')}
        id="helpdesk_attachment"
        onChange={onFileUploadChange}
        type="file"
        ref={fileUploadRef}
        data-testid="helpdesk_file_input"
        className={styles.fileUploadInput}
      />

      {!uploadedFile && !fileSizeTooLarge && (
        <div className={styles.uploadFileButton}>
          <SButton
            iconName="upload"
            onClick={onUploadFileClick}
            label={t('features.helpdesk.fileAndScreenshotCapture.attachFile')}
          />
          {fileTypeError ? (
            <div
              id="filetype-error"
              className={classNames(
                styles.uploadButtonSubText,
                styles.uploadButtonErrorMessage
              )}
            >
              <Icon color="red" iconName="error" variant="normal" />
              <span className="sr-only">Fehler:\u00A0</span>
              <Body small strong inline className={classNames(styles.fileType)}>
                {t('features.helpdesk.fileAndScreenshotCapture.fileTypeError')}
              </Body>
            </div>
          ) : (
            <Body small className={styles.uploadButtonSubText}>
              {t('features.helpdesk.fileAndScreenshotCapture.fileType')}
            </Body>
          )}
        </div>
      )}

      {!uploadedFile && fileSizeTooLarge && (
        <>
          <div
            id="filesize-error"
            className={classNames(
              styles.uploadButtonSubText,
              styles.uploadButtonErrorMessage,
              styles.fileSizeError
            )}
          >
            <Icon color="red" iconName="error" variant="normal" />
            <span className="sr-only">Fehler:\u00A0</span>
            <Body small strong inline className={classNames(styles.fileSize)}>
              {t('features.helpdesk.fileAndScreenshotCapture.fileSizeError')}
            </Body>
          </div>
          <div>
            <SButton
              iconName="upload"
              onClick={onUploadFileClick}
              label={t(
                'features.helpdesk.fileAndScreenshotCapture.changeAttachedFile'
              )}
            />
          </div>
        </>
      )}

      {uploadedFile && (
        <div id="upload_file" className={styles.uploadedFile}>
          <Body inline>{uploadFileName}</Body>
          <IconButton
            iconName="delete"
            onClick={onDeleteFileClick}
            variant="primary"
            ariaLabel={t(
              'features.helpdesk.fileAndScreenshotCapture.deleteAttachedFile'
            )}
          />
        </div>
      )}
    </>
  )
}

export default HelpdeskFileUpload
