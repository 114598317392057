import { config } from '../config'

import { Schemas } from './index'

export type Country = Schemas['Country']

export const countriesEndpoint = `${config.apiUrl}v1/countries`

const DEFAULT_COUNTRY_CODE = 'DE'
export const getDefaultCountryId = (countries: Country[]): number | null => {
  const defaultCountry =
    countries &&
    countries.find((country) => country.code === DEFAULT_COUNTRY_CODE)
  return defaultCountry ? defaultCountry.id : null
}
