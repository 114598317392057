import React from 'react'
import { I18nextProvider } from 'react-i18next'
import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Cookies from 'js-cookie'

import { useTypedSelector } from '@redux'
import { LoadingSpinner } from '@uiLibrary/LoadingSpinner'

import i18n from '../i18n'

import JobradErrorBoundary from './JobradErrorBoundary/JobradErrorBoundary'
import './App.scss'
import routes from './routes/'
import SkipLink from './SkipLink'

const UIBlocker: React.FunctionComponent = () => {
  const blockUI = useTypedSelector((state) => state.app.blockUIOperations)
  return blockUI && blockUI > 0 ? <LoadingSpinner /> : null
}

const router = createBrowserRouter(routes)

const App: React.FunctionComponent = () => {
  // set cookie frontend
  Cookies.set('frontend', 'react', { path: '/' })

  return (
    <JobradErrorBoundary>
      <I18nextProvider i18n={i18n}>
        <UIBlocker />
        <SkipLink />
        <RouterProvider router={router} />
      </I18nextProvider>
    </JobradErrorBoundary>
  )
}

export default App
