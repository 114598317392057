import React from 'react'
import classnames from 'classnames'

import styles from '../Heading.module.scss'
import { HeadingProps } from '../Heading'

export const Heading2 = React.forwardRef<HTMLHeadingElement, HeadingProps>(
  ({ children, id, className, headingStyle = 'h2' }, ref) => {
    const classNames = classnames(styles[headingStyle], className)

    return (
      <h2 className={classNames} ref={ref} id={id}>
        {children}
      </h2>
    )
  }
)

Heading2.displayName = 'Heading2'
