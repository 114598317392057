import React from 'react'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Link from '@uiLibrary/portal/Link'
import Heading1 from '@uiLibrary/typography/Heading1'

import useDocumentTitle from '../../hooks/documentTitle'
import { useI18n } from '../../i18n'

import styles from './PageNotFound.module.scss'

const PageNotFound: React.FunctionComponent = () => {
  useDocumentTitle('Seite nicht gefunden')
  const { t } = useI18n()
  return (
    <Container className={styles.pageNotFound}>
      <Row>
        <Heading1 className={styles.mt32}>
          404: Seite wurde nicht gefunden!
        </Heading1>
        <p>
          Die Seite, nach der Sie suchen, konnte nicht gefunden werden,
          möglicherweise haben Sie die Adresse falsch eingegeben oder Sie wurde
          durch eine Reorganisation der Website entfernt.
        </p>
        <p>
          Vielleicht haben Sie bereits nach einer dieser populären Seiten
          gesucht ?
        </p>
        <ul>
          <li>
            <Link
              linkTarget={{ internal: false, location: '/' }}
              linkText={t('homepage')}
            />
          </li>
          <li>
            <Link
              linkTarget={{ internal: false, location: '/contactus' }}
              linkText={t('contact')}
            />
          </li>
        </ul>
      </Row>
    </Container>
  )
}

export default PageNotFound
