import React from 'react'
import classnames from 'classnames'

import styles from '../Heading.module.scss'
import { HeadingProps } from '../Heading'

export const Heading5: React.FunctionComponent<HeadingProps> = ({
  children,
  id,
  className,
  headingStyle = 'h5'
}) => {
  const classNames = classnames(styles[headingStyle], className)

  return (
    <h5 className={classNames} id={id}>
      {children}
    </h5>
  )
}
