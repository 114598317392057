import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { Brand, brandsEndpoint } from '../../api/brand'
import { BrandsSlice } from '../slices/brands'

export const fetchBrands = createApiAsyncThunk<Brand[], string>(
  'fetchBrands',
  async (query, thunkApi) => {
    const response = await fetch(
      `${brandsEndpoint}?text=${encodeURIComponent(query)}`,
      {
        headers: {
          /* eslint-disable @typescript-eslint/naming-convention */
          accept: 'application/json',
          'x-csrf-token': selectCsrfToken(thunkApi.getState())
          /* eslint-enable @typescript-eslint/naming-convention */
        }
      }
    )
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchBrandsPendingCase = (
  builder: ActionReducerMapBuilder<BrandsSlice>
): void => {
  builder.addCase(fetchBrands.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      pending: true
    }
  }))
}

export const addFetchBrandsFulfilledCase = (
  builder: ActionReducerMapBuilder<BrandsSlice>
): void => {
  builder.addCase(fetchBrands.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      pending: false,
      data: action.payload
    }
  }))
}

export const addFetchBrandsRejectedCase = (
  builder: ActionReducerMapBuilder<BrandsSlice>
): void => {
  builder.addCase(fetchBrands.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      pending: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
