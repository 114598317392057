import React, { useId } from 'react'
import classNames from 'classnames'

import styles from './Icon.module.scss'
import { iconMap } from './iconMap'

export type IconProps = {
  variant: 'normal' | 'big'
  iconName: keyof typeof iconMap
  color?: 'default' | 'green' | 'red' | 'white'
  title?: string
  alt?: string
}
export const Icon: React.FunctionComponent<IconProps> = ({
  variant,
  iconName,
  color = 'default',
  title,
  alt
}) => {
  const id = useId()
  const ariaLabelledBy = `${iconName?.toLowerCase()}-${id}`
  const IconTag = iconMap[iconName]
  const className = classNames(
    styles[color],
    variant === 'big' ? styles['iconBig'] : styles['iconNormal']
  )

  if (title) {
    return (
      <IconTag
        className={className}
        role="img"
        aria-labelledby={ariaLabelledBy}
      >
        <title id={ariaLabelledBy}>{title}</title>
      </IconTag>
    )
  }

  return (
    <IconTag
      className={className}
      role="presentation"
      aria-hidden={alt ? undefined : true}
      focusable="false"
      aria-label={alt}
    />
  )
}
