import React, { type PropsWithChildren } from 'react'

type Props = {
  className: string
  renderAsLegend: boolean
  invalid: boolean
  messageId: string | undefined
}

export const FormFieldContainer: React.FunctionComponent<
  PropsWithChildren<Props>
> = ({ renderAsLegend, children, invalid, messageId, className }) => {
  if (renderAsLegend)
    return (
      <fieldset
        aria-invalid={invalid}
        aria-describedby={messageId}
        className={className}
      >
        {children}
      </fieldset>
    )

  return <div className={className}>{children}</div>
}
