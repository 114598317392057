import React from 'react'
import { Modal, Button } from 'react-bootstrap'

import { useTypedSelector } from '@redux'
import { useAccessInfo } from '@context/useAccessInfo'
import { composeLogoutUri } from '@misc/uri'
import { useSession } from '@context/useSession'
const InvalidSessionModal: React.FunctionComponent = () => {
  const accessInfo = useAccessInfo()
  const session = useSession()
  const sessionIsInvalid = useTypedSelector(
    (state) => state.app.authState === 'UNAUTHORIZED'
  )
  const onLogout = (): void => {
    window.location.href = composeLogoutUri(
      accessInfo,
      session.contract.portal_settings.ext_portal_url
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={sessionIsInvalid}
      onHide={() => false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Sitzung abgelaufen
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Ihre Sitzung ist abgelaufen. Bitte melden Sie sich erneut an.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={onLogout}>Schließen</Button>
      </Modal.Footer>
    </Modal>
  )
}

export default InvalidSessionModal
