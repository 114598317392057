import React from 'react'

import { tokenizeText } from './tokenize/tokenizeText'

type Props = {
  text: string
}

/**
 * Renders a string with support for <b>...</b> (text in bold) and <i>...</i> (text in italic) tags.
 * All other tags and markup will be rendered as plaintext, meaning exactly as passed.
 * @param text: the text to render
 */
const TextWithBoldAndItalic: React.FunctionComponent<Props> = ({ text }) => {
  const tokenizedText = tokenizeText(text)
  return (
    <>
      {tokenizedText.map((x, i) =>
        x.type === 'normal' ? (
          x.text
        ) : x.type === 'bold' ? (
          <b key={i}>{x.text}</b>
        ) : (
          <i key={i}>{x.text}</i>
        )
      )}
    </>
  )
}

export default React.memo(TextWithBoldAndItalic)
