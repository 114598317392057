import React from 'react'

import {
  explanatoryVideosList,
  VideoIds
} from '@pages/PageExplanatoryVideos/ExplanatoryVideo/ExplanatoryVideo'
import Link from '@uiLibrary/portal/Link'
import { usePortal } from '@hooks/portal'

import { useI18n } from '../../i18n'

const FooterExplanatoryVideos: React.FunctionComponent = () => {
  const { t } = useI18n()
  const portal = usePortal()
  const employeeVideos: VideoIds[] = ['employee_intro', 'employee_order_create']
  const managerVideos: VideoIds[] = [
    'manager_intro',
    'manager_order_create',
    'manager_order_edit' /*, 'manager_ivo' Disabled as Requested in A11Y-893*/
  ]

  return (
    <>
      <h2 id="explanatory-videos-in-footer">{t('footer.explanatoryVideos')}</h2>
      <ul aria-describedby="explanatory-videos-in-footer">
        {portal === 'employee' &&
          employeeVideos.map((videoId) => {
            const videoData = explanatoryVideosList[videoId]
            return (
              <li key={videoId}>
                <Link
                  small
                  reverse
                  iconName="arrowRight"
                  linkText={t(videoData.label)}
                  openInNewTab
                  linkTarget={{
                    internal: true,
                    pageName: 'Employee Explanatory Videos',
                    context: { videoId: videoData.id }
                  }}
                />
              </li>
            )
          })}

        {portal === 'manager' &&
          managerVideos.map((videoId) => {
            const videoData = explanatoryVideosList[videoId]
            return (
              <li key={videoId}>
                <Link
                  small
                  reverse
                  iconName="arrowRight"
                  openInNewTab
                  linkText={t(videoData.label)}
                  linkTarget={{
                    internal: true,
                    pageName: 'Manager Explanatory Videos',
                    context: { videoId: videoData.id }
                  }}
                />
              </li>
            )
          })}
      </ul>
    </>
  )
}

export default FooterExplanatoryVideos
