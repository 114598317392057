import React from 'react'

import styles from './Main.module.scss'

type Props = {
  children: React.ReactElement
}

export const Main: React.FunctionComponent<Props> = ({ children }) => (
  <main id="maincontent" className={styles.main}>
    {children}
  </main>
)
