import React from 'react'

import { ExternalLocation } from '@hooks/useTypedLocation'

import type { LinkIconName } from '../Link'
import LinkInner from '../LinkInner'

type Props = {
  linkText: string
  linkTarget: ExternalLocation
  id: string | undefined
  small: boolean | undefined
  reverse: boolean | undefined
  openInNewTab: boolean | undefined
  iconName: LinkIconName | undefined
}
const ExternalLink: React.FunctionComponent<Props> = ({
  linkText,
  linkTarget,
  id,
  small,
  reverse,
  iconName,
  openInNewTab
}) => (
  <LinkInner
    linkText={linkText}
    to={linkTarget.location}
    state={null}
    id={id}
    small={small}
    reverse={reverse}
    openInNewTab={openInNewTab}
    iconName={iconName}
    reloadDocument={true}
  />
)

export default ExternalLink
