import React, { useRef } from 'react'
import { Menu } from '@headlessui/react'
import { NavLink } from 'react-router-dom'
import classNames from 'classnames'

import Icon from '@uiLibrary/Icon'
import { getLocationContextEvent, TypedLocation } from '@hooks/useTypedLocation'
import { getUrlPathWithQueryAndHash } from '@hooks/useTypedNavigate'
import { useAccessInfo } from '@context/useAccessInfo'

import styles from './MenuItem.module.scss'

type MenuItemProps = {
  selected?: boolean
  navigationTarget: TypedLocation
  label: string
  withExternalIndicatorIcon?: boolean
}

export const MenuItem: React.FunctionComponent<MenuItemProps> = ({
  navigationTarget,
  label,
  withExternalIndicatorIcon = false
}) => {
  const linkRef = useRef<HTMLAnchorElement>(null)
  const onClick = (): void => {
    linkRef.current?.click()
  }
  const { slug, accessCode } = useAccessInfo()
  const to = navigationTarget.internal
    ? getUrlPathWithQueryAndHash(navigationTarget, slug, accessCode)
    : navigationTarget.location
  const event = navigationTarget.internal
    ? getLocationContextEvent(navigationTarget)
    : undefined
  const state = { event: event }
  return (
    <Menu.Item as="li" onClick={onClick}>
      {({ active, close }) => (
        <NavLink
          className={({ isActive }) =>
            classNames(
              styles['menu-item'],
              isActive && styles.selected,
              active && styles.active
            )
          }
          to={to}
          state={state}
          ref={linkRef}
          reloadDocument={!navigationTarget.internal}
          onClick={close}
        >
          {withExternalIndicatorIcon && (
            <span className={styles.icon}>
              <Icon variant="normal" iconName={'externalLink'} color="green" />
            </span>
          )}
          {label}
        </NavLink>
      )}
    </Menu.Item>
  )
}
