import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  OrdersSlice,
  updateOrderSliceWithNewOrderDetailStatus
} from '../slices/orders'
import { buildRejectValue, createApiAsyncThunk } from '../apiAsyncThunk'
import {
  OrderDocumentDeleteResponse,
  orderDocumentEndpoint
} from '../../api/documents'

type DeleteOrderDocumentArgument = {
  orderId: number
  documentId: number
}
export const deleteOrderDocument = createApiAsyncThunk<
  OrderDocumentDeleteResponse,
  DeleteOrderDocumentArgument
>('orders/deleteOrderDocument', async (arg, thunkApi) => {
  const response = await fetch(
    orderDocumentEndpoint(arg.orderId, arg.documentId),
    {
      method: 'DELETE',
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    }
  )
  const responseBody = await response.json()

  if (!response.ok) {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return responseBody
})

export const addDeleteOrderDocumentFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(deleteOrderDocument.fulfilled, (state, action) => {
    const getStateAfterDocumentDeletion = (): OrdersSlice => {
      const orderId = action.meta.arg.orderId
      const orderDocumentState = state.documents[orderId]
      if (!orderDocumentState || !orderDocumentState.data) return state
      const documents = orderDocumentState.data
      const index = documents.findIndex(
        (x) => x.id === action.meta.arg.documentId
      )
      if (index === -1) return state
      return {
        ...state,
        documents: {
          ...state.documents,
          [orderId]: {
            error: undefined,
            pending: false,
            data: [...documents.slice(0, index), ...documents.slice(index + 1)]
          }
        }
      }
    }

    return updateOrderSliceWithNewOrderDetailStatus(
      getStateAfterDocumentDeletion(),
      action.meta.arg.orderId,
      action.payload.order_portal_state
    )
  })
}
