import React from 'react'

import SButton from '@uiLibrary/SButton'
import { Body } from '@uiLibrary/typography/Body/Body'

import { useI18n } from '../../../../i18n'

type Props = {
  onButtonClick: () => void
}
const NewsletterFooterContent: React.FunctionComponent<Props> = ({
  onButtonClick
}) => {
  const { t } = useI18n()

  return (
    <div>
      <Body small strong>
        {t('footer.newsletterBodyPart1')}
      </Body>
      <Body small>{t('footer.newsletterBodyPart2')}</Body>
      <SButton
        label={t('footer.newsletterSubscribeButtonLabel')}
        iconName="arrowRight"
        onClick={onButtonClick}
      />
    </div>
  )
}

export default NewsletterFooterContent
