import React, { forwardRef, ForwardedRef } from 'react'
import { FieldError } from 'react-hook-form'

import FormField from '../FormField/FormField'

import styles from './InputFieldMultiLine.module.scss'

type Props = {
  id: string
  label: string
  placeholder?: string
  value?: string
  required?: boolean
  helpText?: string
  error: FieldError | undefined
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  tooltip?: string
  maxLength?: number
}

export const InputFieldMultiLine = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  Props
>(
  (
    {
      id,
      placeholder,
      value,
      helpText,
      label,
      required,
      error,
      name,
      onChange,
      onBlur,
      tooltip,
      maxLength
    },
    ref
  ) => {
    return (
      <FormField
        id={id}
        label={label}
        required={required}
        helpText={helpText}
        error={error}
        tooltip={tooltip}
        render={({ props: formFieldRenderProps, required, invalid }) => {
          return (
            <textarea
              ref={ref as ForwardedRef<HTMLTextAreaElement>}
              id={formFieldRenderProps.id}
              aria-describedby={formFieldRenderProps['aria-describedby']}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              aria-invalid={invalid}
              placeholder={placeholder}
              required={required}
              className={styles.input}
              maxLength={maxLength}
            />
          )
        }}
      />
    )
  }
)
InputFieldMultiLine.displayName = 'InputFieldMultiLine'
