import { combineReducers } from '@reduxjs/toolkit'

import appReducer from './slices/app'
import ordersReducer from './slices/orders'
import sessionReducer from './slices/session'
import newsletterSettingsReducer from './slices/newsletterSettings'
import offersReducer from './slices/offers'
import ordersEmployeeReducer from './slices/ordersEmployee'
import brandsReducers from './slices/brands'
import reportsReducer from './slices/reports'
import countriesReducer from './slices/countries'
import managerReportsReducer from './slices/managerReports'

const rootReducer = combineReducers({
  app: appReducer,
  orders: ordersReducer,
  session: sessionReducer,
  newsletterSettings: newsletterSettingsReducer,
  offers: offersReducer,
  reports: reportsReducer,
  ordersEmployee: ordersEmployeeReducer,
  brands: brandsReducers,
  countries: countriesReducer,
  managerReports: managerReportsReducer
})

export default rootReducer

export type RootState = ReturnType<typeof rootReducer>
