import React, { useEffect } from 'react'

import store from '@redux/store'
import { startUIBlockingOperation } from '@redux/slices/app'
import { SlugAndAccessCode } from '@context/useAccessInfo'

import styles from './PageRedirect.module.scss'

type Props = {
  accessInfo: SlugAndAccessCode
}
/**
 * This Page is used to redirect the user to his login page.
 *
 * It shows a notice and triggers the redirect.
 */
const PageRedirect: React.FunctionComponent<Props> = ({ accessInfo }) => {
  useEffect(() => {
    const loginPageURL = `/${accessInfo.slug}/login/${accessInfo.accessCode}`
    store.dispatch(startUIBlockingOperation())
    window.location.assign(loginPageURL)
  }, [accessInfo])

  return (
    <div className={styles.redirectPage}>
      Sie werden umgeleitet, bitte warten...
    </div>
  )
}

export default PageRedirect
