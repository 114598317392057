import { RefObject, useEffect, useState } from 'react'

export function useResizeObserver(ref: RefObject<HTMLElement>): number {
  const [width, setWidth] = useState(
    ref.current ? ref.current.clientWidth : window ? window.innerWidth : 0
  )

  useEffect(() => {
    function handleWidth(): void {
      if (ref.current) {
        setWidth(ref.current.clientWidth)
      }
    }

    const currentRef = ref.current

    const resizeObserver = new ResizeObserver(handleWidth)
    if (ref.current) {
      resizeObserver.observe(ref.current)
    }
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef)
      }
    }
  }, [ref])

  return width
}
