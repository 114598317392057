import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgView: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M7.00002 9C8.13143 9 9.04863 8.10457 9.04863 7C9.04863 5.89543 8.13143 5 7.00002 5C5.8686 5 4.95141 5.89543 4.95141 7C4.95141 8.10457 5.8686 9 7.00002 9Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.8031 6.21028C14.0624 6.65723 14.0654 7.1892 13.8131 7.63996C13.0298 9.03966 10.9108 12 7.00003 12C3.0973 12 0.978963 9.0966 0.191755 7.71472C-0.0639181 7.26591 -0.0639184 6.7341 0.191755 6.28528C0.978963 4.9034 3.0973 2 7.00003 2C10.8943 2 13.0119 4.8462 13.8031 6.21028ZM7.00003 4C9.54991 4 11.1012 5.73103 11.8648 6.93886C11.096 8.20275 9.53877 10 7.00003 10C4.45553 10 2.9013 8.23573 2.13514 7C2.9013 5.76427 4.45553 4 7.00003 4Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgView
