import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import { OrdersSlice } from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { OrderDocument, orderDocumentsEndpoint } from '../../api/documents'

export const fetchOrderDocuments = createApiAsyncThunk<OrderDocument[], number>(
  'orders/fetchOrderDocuments',
  async (orderId, thunkApi) => {
    const response = await fetch(orderDocumentsEndpoint(orderId), {
      headers: {
        /* eslint-disable @typescript-eslint/naming-convention */
        accept: 'application/json',
        'x-csrf-token': selectCsrfToken(thunkApi.getState())
        /* eslint-enable @typescript-eslint/naming-convention */
      }
    })
    const responseBody = await response.json()

    if (!response.ok) {
      return thunkApi.rejectWithValue(buildRejectValue(responseBody))
    }

    return responseBody
  }
)

export const addFetchOrderDocumentsFulFilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDocuments.fulfilled, (state, action) => ({
    ...state,
    documents: {
      ...state.documents,
      [action.meta.arg]: {
        error: undefined,
        pending: false,
        data: action.payload
      }
    }
  }))
}

export const addFetchOrderDocumentsRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDocuments.rejected, (state, action) => ({
    ...state,
    documents: {
      ...state.documents,
      [action.meta.arg]: {
        data: undefined,
        error: buildFetchError(action),
        pending: false
      }
    }
  }))
}

export const addFetchOrderDocumentsPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrderDocuments.pending, (state, action) => ({
    ...state,
    documents: {
      ...state.documents,
      [action.meta.arg]: {
        ...state.documents[action.meta.arg],
        pending: true
      }
    }
  }))
}
