import React from 'react'

import { TypedLocation } from '@hooks/useTypedLocation'
import ExternalLink from '@uiLibrary/portal/Link/ExternalLink'
import InternalLink from '@uiLibrary/portal/Link/InternalLink'

export type LinkIconName =
  | 'arrowLeft'
  | 'arrowRight'
  | 'download'
  | 'externalLink'

type Props = {
  linkText: string
  linkTarget: TypedLocation
  id?: string
  small?: boolean
  reverse?: boolean
  openInNewTab?: boolean
  iconName?: LinkIconName
}
export const Link: React.FunctionComponent<Props> = ({
  linkText,
  linkTarget,
  id,
  small,
  reverse,
  iconName,
  openInNewTab
}) => {
  return linkTarget.internal ? (
    <InternalLink
      linkText={linkText}
      linkTarget={linkTarget}
      id={id}
      small={small}
      reverse={reverse}
      iconName={iconName}
      openInNewTab={openInNewTab}
    />
  ) : (
    <ExternalLink
      linkText={linkText}
      linkTarget={linkTarget}
      id={id}
      small={small}
      reverse={reverse}
      iconName={iconName}
      openInNewTab={openInNewTab}
    />
  )
}
