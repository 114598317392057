import { Session } from '@api/session'
import { getArrayFromUnionType } from '@misc/tsHelpers'

import { config } from '../config'

import { SupplierDetail } from './suppliers'

import { Schemas } from './index'

export type Order = Schemas['Order']
export type OrderStatus = Order['portal_state']
export type OrderInspection = NonNullable<Order['inspection']>
export type OrderExternalReference = Schemas['OrderExternalReference']
export type OrderWithSource = Order & Required<Pick<Order, 'source_order'>>

/* eslint-disable @typescript-eslint/naming-convention */
export const ORDER_STATUS_CODES_STRINGS: Record<OrderStatus, string> = {
  new: 'Neu',
  saved: 'Entwurf',
  sent: 'Dokumente erforderlich',
  waiting: 'Auf Freigabe wartend',
  waiting2: 'Auf 2. Freigabe wartend',
  approved: 'Freigegeben',
  ordered: 'Bestellt',
  delivered: 'Übergeben',
  closed: 'Aktiv',
  contract_end: 'Vertragsauflösung',
  ended: 'Beendet',
  canceled: 'Abgebrochen',
  check_rating: 'Genehmigung prüfen',
  waiting_confirmation: 'Auf Ihre Bestätigung wartend'
}

export const ORDER_INSPECTION_CODES_STRINGS: Record<OrderInspection, string> = {
  inspec_necessary: 'Inspektion notwendig',
  inspec_not_necessary: 'Inspektion nicht notwendig'
}
/* eslint-enable @typescript-eslint/naming-convention*/

// specify values for use for filters. since we can't get them
// from the types and since the order is important, we redeclare
// them here. maybe we ca find a better solution later.
export const ORDER_STATUS_CODES = getArrayFromUnionType<OrderStatus>()([
  'new',
  'saved',
  'sent',
  'waiting',
  'waiting2',
  'approved',
  'ordered',
  'delivered',
  'closed',
  'contract_end',
  'ended',
  'canceled',
  'check_rating',
  'waiting_confirmation'
] as const)
export const ORDER_INSPECTION_CODES = getArrayFromUnionType<OrderInspection>()([
  'inspec_necessary',
  'inspec_not_necessary'
] as const)

export const ordersEndpoint = `${config.apiUrl}v1/orders`
export const endingOrdersEndpoint = (rvaKauCode: string): string =>
  `${config.apiUrl}v1/ending-orders/${rvaKauCode}`
export const endingOrdersPricesEndpoint = `${config.apiUrl}v1/ending-orders/prices`

// order creation
// discussion on why this is as of typescript 4.1 the best approaches: https://stackoverflow.com/a/61129291.
// Todo: write an helper for this pattern
export type BikeDeliveryTypes = OrderType['code']
export const bikeDeliveryTypes = getArrayFromUnionType<BikeDeliveryTypes>()([
  'normal',
  'online'
] as const)
export type OrderCreateOrEdit = Schemas['OrderCreateOrEdit']
export type OrderShopType = NonNullable<SupplierDetail['shop_type']>
export type DynamicFieldInstance = Schemas['DynamicFieldInstance']
export type OrderAccessory = Schemas['AccessoryCreate']

// order cancellation
export type OrderCancellationReason = Schemas['CancellationReason']
export type OrderCancellationReasons = OrderCancellationReason[]

// order detail
export type OrderDetail = Schemas['OrderDetail']
export type OrderType = Schemas['OrderType']
export const orderDetailEndpoint = (orderId: number): string =>
  `${ordersEndpoint}/${orderId}`

export type EmployeeSchemaWithGender = Schemas['EmployeeSchemaWithGender']
export type OrderDetailBrand = Schemas['Brand']
export type OrderDetailSupplier = Schemas['SupplierDetail']
export type SourceOrder = Schemas['SourceOrder']
export type InsuranceOption = Schemas['InsuranceOption']
export type ServiceOption = Schemas['ServiceOption']
export type OrderService = Schemas['OrderService']

// order messages
export type OrderMessage = Schemas['Message']
export type OrderMessages = OrderMessage[]
export type OrderMessageCreate = Schemas['CreateMessage']
export const orderMessagesEndpoint = (orderId: number): string =>
  `${ordersEndpoint}/${orderId}/messages`

export type PartnerAddress = Schemas['PartnerAddress']

export type OrderServicePurchase = Schemas['Purchase']
export type OrderServicePurchaseInspection = Schemas['PurchaseInspection']

// change email
export type ChangeEmailConfirmSuccess = Schemas['ChangeEmailConfirmSuccess']

// change order
export type OrderEdit = Schemas['OrderEdit']
export type OrderEditPortalState = NonNullable<OrderEdit['portal_state']>
export type OrderEditNewState = OrderEditPortalState['new_state']

export type EndingOrderPricesQuery = Schemas['EndingOrderPricesQuery']
export type EndingOrderPricesResult = Schemas['EndingOrderPricesResult']

// ending order
export type EndingOrderEdit = Schemas['EndingOrderEdit']
export type EndingOrderEmployee = Schemas['EndingOrderEmployee']

export type OrderListFilterType =
  | 'application'
  | 'employee'
  | 'employeeNumber'
  | 'confirmedBy'
  | 'status'
  | 'inspection'
  | 'company'
  | 'incidents'
export type OrderListFilter = {
  type: OrderListFilterType
  value: string
}
export type OrderListFilters = OrderListFilter[]
export const mapFilterToParams = (filters: OrderListFilters): string => {
  const filterTypeMap: Partial<Record<OrderListFilterType, string>> = {
    application: 'sale_order',
    confirmedBy: 'manager',
    employee: 'employee',
    employeeNumber: 'personal_number',
    status: 'portal_state',
    inspection: 'inspection_needed',
    company: 'company_id',
    incidents: 'with_incidents'
  }

  const filterValueMap: Partial<
    Record<keyof typeof filterTypeMap, (value: string) => string>
  > = {
    inspection: (value) => (value === ORDER_INSPECTION_CODES[1] ? '0' : '1'),
    incidents: (value) => (value === 'with_incidents' ? '1' : '0')
  }
  const id = (x: string): string => x

  const supportedFilters = filters.filter((x) => filterTypeMap[x.type])
  return supportedFilters
    .map(
      (x) =>
        `${filterTypeMap[x.type]}=${encodeURIComponent(
          (filterValueMap[x.type] || id)(x.value)
        )}`
    )
    .join('&')
}

export const getAvailableStatusCodes = (
  session: Session
): Readonly<OrderStatus[]> => {
  // TODO Explain or reference business case
  //  (e.g., why do we filter out status code 'waiting_confirmation'?)
  const filteredStatusCodes: Readonly<OrderStatus[]> =
    ORDER_STATUS_CODES.filter(
      (x) =>
        !['check_rating', 'saved', 'sent', 'waiting_confirmation'].includes(x)
    )
  return !session.contract.enable_second_approval
    ? filteredStatusCodes.filter((x) => x !== 'waiting2')
    : filteredStatusCodes
}
