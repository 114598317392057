import React from 'react'

import AppInfoModal from '../../AppInfoModal/AppInfoModal'
import { useI18n } from '../../../../i18n'

type PreviewModeModalProps = {
  open: boolean
  onClose: () => void
}

const PreviewModeModal: React.FunctionComponent<PreviewModeModalProps> = ({
  open,
  onClose
}) => {
  const { t } = useI18n()

  return (
    <AppInfoModal
      title={t('previewMode.modal.title')}
      open={open}
      onClose={onClose}
      body={{
        headline1: t('previewMode.modal.headline1'),
        listItems1: t('previewMode.modal.listItems1', { returnObjects: true }),
        headline2: t('previewMode.modal.headline2'),
        listItems2: t('previewMode.modal.listItems2', { returnObjects: true })
      }}
    />
  )
}

export default PreviewModeModal
