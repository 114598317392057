import {
  deriveHostingEnvironmentFromHostname,
  HostingEnvironment
} from './misc/environment'

const stringToBool = (configValue: unknown): boolean => {
  // We use webpack EnvironmentPLugin with default values.
  // Webpack pipes the default value without converting it to a string.
  // Therefore, configValue might be of a type other than string
  if (typeof configValue === 'boolean') {
    return configValue
  }

  if (
    !configValue ||
    typeof configValue !== 'string' ||
    (configValue.toLowerCase() !== 'true' &&
      configValue.toLowerCase() !== 'false')
  ) {
    throw Error(
      `Invalid boolean value "${configValue}" given for environment variable. Please use "true" or "false" only.`
    )
  }

  // At this point, configString can only be of string value 'true' or 'false'
  return !!configValue && configValue.toLowerCase() === 'true'
}

const hostingEnvironment = deriveHostingEnvironmentFromHostname(
  window.location.hostname
)

const hostingEnvironmentIsStagingProductionOrIntegration = (
  environment: HostingEnvironment
): boolean => {
  return (
    environment === 'production' ||
    environment === 'staging' ||
    environment === 'integration'
  )
}

export const config = {
  apiUrl: process.env.API_URL,
  debug: stringToBool(process.env.DEBUG ?? false),
  isRobotTest: process.env.IS_ROBOT_TEST ?? false,
  version: process.env.VERSION || 'unknown',
  sentryShouldBeEnabled:
    hostingEnvironmentIsStagingProductionOrIntegration(hostingEnvironment),
  passwordPolicy: {
    minLength: 8,
    minNumberUpperCase: 1,
    minNumberLowerCase: 1,
    minNumberDigits: 1,
    minNumberSpecialChar: 1
  }
}
