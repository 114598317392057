import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgPlus: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M6 12C6 12.5523 6.44772 13 7 13C7.55228 13 8 12.5523 8 12L8 8H12C12.5523 8 13 7.55228 13 7C13 6.44771 12.5523 6 12 6H8L8 2C8 1.44771 7.55228 1 7 1C6.44771 1 6 1.44771 6 2L6 6H2C1.44772 6 1 6.44772 1 7C1 7.55228 1.44772 8 2 8H6L6 12Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgPlus
