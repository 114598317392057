import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit'

import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { Session, sessionFetchEndpoint } from '../../api/session'
import { SessionSlice } from '../slices/session'
import { config } from '../../config'

export const updateCsrfToken = createAction<string>('session/updateCsrfToken')

export const fetchPortalSession = createApiAsyncThunk<Session>(
  'session/fetchSession',
  async (args, thunkApi) => {
    const response = await fetch(sessionFetchEndpoint, {
      headers: {
        accept: 'application/json',
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'x-portal-version': config.version
      }
    })

    const responseBody = await response.json()

    if (!response.ok) {
      const expectedResponseCodes = [401]
      return thunkApi.rejectWithValue(
        buildRejectValue(responseBody, expectedResponseCodes)
      )
    }

    const csrfToken = response.headers.get('X-CSRF-TOKEN')
    if (csrfToken !== null) {
      thunkApi.dispatch(updateCsrfToken(csrfToken))
    }

    return responseBody
  }
)

export const addUpdateCsrfTokenCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(updateCsrfToken, (state, action) => ({
    ...state,
    csrfToken: action.payload
  }))
}

export const addFetchPortalSessionFulfilledCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(fetchPortalSession.fulfilled, (state, action) => ({
    ...state,
    dataError: undefined,
    data: action.payload,
    pending: false
  }))
}

export const addFetchPortalSessionPendingCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(fetchPortalSession.pending, (state) => ({
    ...state,
    pending: true
  }))
}

export const addFetchPortalSessionRejectedCase = (
  builder: ActionReducerMapBuilder<SessionSlice>
): void => {
  builder.addCase(fetchPortalSession.rejected, (state, action) => ({
    ...state,
    pending: false,
    dataError: buildFetchError(action)
  }))
}
