import { ActionReducerMapBuilder } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import { OrdersSlice } from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import {
  OrderDetail,
  orderDetailEndpoint,
  OrderCreateOrEdit
} from '../../api/order'

import { fetchOrderMessages } from './fetchOrderMessages'

export type PutOrderDetailArgument = {
  orderId: number
  data: OrderCreateOrEdit
}

export const putOrderDetail = createApiAsyncThunk<
  OrderDetail,
  PutOrderDetailArgument
>('orders/putOrderDetail', async (arg, thunkApi) => {
  const response = await fetch(orderDetailEndpoint(arg.orderId), {
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      accept: 'application/json',
      'content-type': 'application/json',
      'x-csrf-token': selectCsrfToken(thunkApi.getState())
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    method: 'PUT',
    body: JSON.stringify(arg.data)
  })

  const responseBody = await response.json()

  if (response.ok) {
    await thunkApi.dispatch(fetchOrderMessages(arg.orderId))
  } else {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return responseBody
})

export const addPutOrderDetailFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(putOrderDetail.fulfilled, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        pending: false,
        error: undefined,
        data: action.payload
      }
    }
  }))
}

export const addPutOrderDetailPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(putOrderDetail.pending, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        ...state.details[action.meta.arg.orderId],
        pending: true
      }
    }
  }))
}

export const addPutOrderDetailRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(putOrderDetail.rejected, (state, action) => ({
    ...state,
    details: {
      ...state.details,
      [action.meta.arg.orderId]: {
        data: undefined,
        pending: false,
        error: buildFetchError(action)
      }
    }
  }))
}
