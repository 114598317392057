import React from 'react'

import wheelImage from './wheel.svg'
import styles from './Wheel.module.scss'

const Wheel: React.FunctionComponent = () => (
  <img className={styles.loadingSpinnerWheel} src={wheelImage} />
)

export default Wheel
