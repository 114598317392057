import { Menu } from '@headlessui/react'
import React from 'react'
import classNames from 'classnames'

import Icon from '@uiLibrary/Icon'

import { useI18n } from '../../../../i18n'

import styles from './MenuButton.module.scss'

type DropdownButtonProps = {
  open: boolean
  selected: boolean
  label?: string
  variant: 'overflow' | 'menu'
}

export const MenuButton: React.FunctionComponent<DropdownButtonProps> = ({
  open,
  selected,
  label,
  variant
}) => {
  const { t } = useI18n()

  if (variant === 'overflow') {
    return (
      <Menu.Button
        className={classNames(
          styles.menuButton,
          open ? styles.openOverflow : undefined,
          selected ? styles.selected : undefined
        )}
      >
        <span>{t('navigation.overflowButton')}</span>
        <Icon
          variant="normal"
          iconName={open ? 'chevronUp' : 'chevronDown'}
        ></Icon>
      </Menu.Button>
    )
  }

  if (variant === 'menu') {
    return (
      <Menu.Button
        className={classNames(
          styles.menuButton,
          open ? styles.openMenu : undefined
        )}
      >
        {/*Desktop | visibility handled in css*/}
        <span>{label ? label : t('navigation.menuButton')}</span>
        <Icon
          variant="normal"
          iconName={open ? 'chevronUp' : 'chevronDown'}
        ></Icon>

        {/*Mobile | visibility handled in css*/}
        <span>{t('navigation.menuButton')}</span>
        <Icon variant="normal" iconName={open ? 'close' : 'menu'}></Icon>
      </Menu.Button>
    )
  }

  return null
}
