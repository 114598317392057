import React, { forwardRef, ComponentProps, ForwardedRef } from 'react'
import { FieldError } from 'react-hook-form'
import classnames from 'classnames'

import Icon from '@uiLibrary/Icon'

import FormField from '../FormField'

import styles from './InputField.module.scss'

type Props = {
  id: string
  label: string
  placeholder?: string
  autoComplete?: string
  value?: string
  required?: boolean
  helpText?: string
  error: FieldError | undefined
  name?: string
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  tooltip?: string
  type?: 'text' | 'password' | 'tel' | 'search'
  icon?: {
    title: string
    name: ComponentProps<typeof Icon>['iconName']
  } | null
  ariaLabel?: string
}

export const InputField = forwardRef<
  HTMLInputElement | HTMLTextAreaElement,
  Props
>(
  (
    {
      id,
      placeholder,
      autoComplete,
      value,
      helpText,
      label,
      required,
      error,
      name,
      onChange,
      onBlur,
      tooltip,
      type,
      ariaLabel,
      icon
    },
    ref
  ) => {
    return (
      <FormField
        id={id}
        label={label}
        required={required}
        helpText={helpText}
        error={error}
        tooltip={tooltip}
        render={({ props: formFieldRenderProps, required, invalid }) => {
          const inputEl = (
            <input
              ref={ref as ForwardedRef<HTMLInputElement>}
              id={formFieldRenderProps.id}
              aria-describedby={formFieldRenderProps['aria-describedby']}
              aria-labelledby={formFieldRenderProps['aria-labelledby']}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              aria-invalid={invalid}
              placeholder={placeholder}
              autoComplete={autoComplete}
              required={required}
              className={classnames(styles.input, styles.singleLine)}
              type={type}
              aria-label={ariaLabel}
            />
          )
          if (icon === undefined) return inputEl
          return (
            <div className={styles.inputWithIconWrapper}>
              {inputEl}
              {icon && (
                <Icon
                  variant="big"
                  iconName={icon.name}
                  title={icon.title}
                  color="green"
                />
              )}
            </div>
          )
        }}
      />
    )
  }
)
InputField.displayName = 'InputField'
