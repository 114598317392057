import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgChevronRight: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.29289 2.29289C3.90237 2.68342 3.90237 3.31658 4.29289 3.70711L7.58579 7L4.29289 10.2929C3.90237 10.6834 3.90237 11.3166 4.29289 11.7071C4.68342 12.0976 5.31658 12.0976 5.70711 11.7071L9.70711 7.70711C10.0976 7.31658 10.0976 6.68342 9.70711 6.29289L5.70711 2.29289C5.31658 1.90237 4.68342 1.90237 4.29289 2.29289Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgChevronRight
