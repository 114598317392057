import React from 'react'
import classnames from 'classnames'

import styles from '../Heading.module.scss'
import { HeadingProps } from '../Heading'

export const Heading4: React.FunctionComponent<HeadingProps> = ({
  children,
  id,
  className,
  headingStyle = 'h4'
}) => {
  const classNames = classnames(styles[headingStyle], className)

  return (
    <h4 className={classNames} id={id}>
      {children}
    </h4>
  )
}
