import { createSlice } from '@reduxjs/toolkit'
import { StatusCodes } from 'http-status-codes'

import { Session } from '../../api/session'
import { Managers } from '../../api/managers'
import { Employees } from '../../api/employees'
import { FetchError, failedWithStatus } from '../apiAsyncThunk'
import { RootState } from '../index'
import {
  addFetchPortalSessionPendingCase,
  addFetchPortalSessionFulfilledCase,
  addFetchPortalSessionRejectedCase,
  addUpdateCsrfTokenCase
} from '../api/fetchPortalSession'
import {
  addPatchSessionFulfilledCase,
  addPatchSessionPendingCase,
  addPatchSessionRejectedCase
} from '../api/patchSession'

export type SessionSlice = {
  dataError?: FetchError
  data?: Session
  pending?: boolean
  managers?: Managers
  employees?: Employees
  csrfToken?: string
}

export const initialState: SessionSlice = {}

const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchPortalSessionPendingCase(builder)
    addFetchPortalSessionFulfilledCase(builder)
    addFetchPortalSessionRejectedCase(builder)

    addPatchSessionFulfilledCase(builder)
    addPatchSessionPendingCase(builder)
    addPatchSessionRejectedCase(builder)

    addUpdateCsrfTokenCase(builder)
  }
})

export const selectSession = (state: RootState): Session | undefined =>
  state.session.data
export const selectSessionPending = (state: RootState): boolean | undefined =>
  state.session.pending
export const selectFetchSessionError = (
  state: RootState
): FetchError | undefined => state.session.dataError
export const selectNonUnauthorizedFetchSessionError = (
  state: RootState
): FetchError | undefined => {
  const { data, dataError } = state.session

  if (
    !data &&
    dataError &&
    !failedWithStatus(dataError, StatusCodes.UNAUTHORIZED)
  ) {
    return dataError
  }

  return undefined
}

export const selectCsrfToken = (state: RootState): string => {
  return state.session.csrfToken ?? 'NO_TOKEN'
}

type SentryUser = { id: string; email: string }
export const selectSentryUserData = (state: RootState): SentryUser | null => {
  if (state.session.data === undefined) return null
  return {
    id: state.session.data.id.toString(),
    email: state.session.data.email
  }
}

export default sessionSlice.reducer
