import React from 'react'
import { Trans } from 'react-i18next'

import Body from '@uiLibrary/typography/Body'
import Link from '@uiLibrary/portal/Link'

import { useI18n } from '../../../i18n'

import styles from './NewsletterSubscriptionPane.module.scss'

type Props = {
  buttonGroup?: JSX.Element
}
const NewsletterSubscriptionPane: React.FunctionComponent<Props> = ({
  buttonGroup
}) => {
  const { t } = useI18n()
  return (
    <div className={styles.newsletterSubscriptionPane}>
      <Body>{t('newsletterSettings.subscriptionViewBodyPart1')}</Body>
      <Body>
        <Trans
          i18nKey={'newsletterSettings.subscriptionViewBodyPart2'}
          components={{
            jobRadGroupLink: (
              <Link
                linkText={t('newsletterSettings.jobRadGroupLinkText')}
                linkTarget={{
                  internal: false,
                  location: 'https://www.jobrad.org/das-unternehmen.html'
                }}
                iconName={'externalLink'}
              />
            ),
            partnerLink: (
              <Link
                linkText={t('newsletterSettings.partnerLinkText')}
                linkTarget={{
                  internal: false,
                  location: 'https://www.jobrad.org/newsletter/ewe-partner.html'
                }}
                iconName={'externalLink'}
              />
            )
          }}
        />
      </Body>
      <Body>
        <Trans
          i18nKey={'newsletterSettings.subscriptionViewBodyPart3'}
          components={{
            dataProtectionLink: (
              <Link
                linkText={t('newsletterSettings.dataProtectionLinkText')}
                linkTarget={{
                  internal: false,
                  location: 'https://www.jobrad.org/datenschutz.html'
                }}
                iconName={'externalLink'}
              />
            )
          }}
        />
      </Body>
      {buttonGroup && <div className={styles.buttonGroup}>{buttonGroup}</div>}
    </div>
  )
}

export default NewsletterSubscriptionPane
