import React, { FunctionComponent } from 'react'

import Icon from '@uiLibrary/Icon'
import Body from '@uiLibrary/typography/Body'
import SButton from '@uiLibrary/SButton'

import { useI18n } from '../../../i18n'

import styles from './AppInfoAlert.module.scss'

type AppInfoAlertProps = {
  infoText: string
  handleOpenModal: () => void
}

const AppInfoAlert: FunctionComponent<AppInfoAlertProps> = ({
  infoText,
  handleOpenModal
}) => {
  const { t } = useI18n()

  return (
    <>
      <div className={styles['banner']}>
        <Icon color="default" iconName="info" variant="big" />
        <div className={styles['alertcontent']}>
          <Body>{infoText}</Body>
          <SButton
            iconName="arrowRight"
            label={t('appInfo.alertButtonLabel')}
            onClick={handleOpenModal}
          />
        </div>
      </div>
      <div className={styles['border']} aria-hidden></div>
    </>
  )
}

export default AppInfoAlert
