import React, { PropsWithChildren } from 'react'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'

import styles from './Modal.module.scss'

type JobradModalProps = {
  titleId?: string
  className?: string
  displayDivider?: boolean
  footer?: string | React.ReactNode
  header?: string | React.ReactNode
  onFormSubmit?: (event: React.FormEvent) => void
  onHide?: () => void
  show: boolean
  subtitle?: string | React.ReactNode
  enforceFocus?: boolean
  dataTestId?: string
}

/**
 * @deprecated Use `@uiLibrary/Modal` instead.
 */
const JobradModal: React.FunctionComponent<
  PropsWithChildren<JobradModalProps>
> = ({
  titleId,
  children,
  className,
  displayDivider = true,
  footer,
  header,
  onFormSubmit,
  onHide,
  show,
  subtitle,
  enforceFocus = true,
  dataTestId
}) => {
  const renderModalContent = (): React.ReactNode => {
    return (
      <>
        <Modal.Header className={classNames(styles.lr_modal_header)}>
          <Modal.Title id={titleId} as="h2">
            {header}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classNames(styles.lr_modal_body)}>
          {/* TODO: Convert this divider from HTML tag to CSS */}
          {displayDivider && <div className={classNames(styles.lr_divider)} />}
          {subtitle && <p className={classNames('lr_subtitle')}>{subtitle}</p>}
          {children}
        </Modal.Body>
        <Modal.Footer className={styles.lr_modal_footer}>{footer}</Modal.Footer>
      </>
    )
  }

  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show}
      onHide={onHide}
      data-testid={dataTestId}
      className={`${classNames(styles.lr_modal)} ${className}`}
      enforceFocus={enforceFocus}
    >
      {onFormSubmit ? (
        <form onSubmit={onFormSubmit}>{renderModalContent()}</form>
      ) : (
        renderModalContent()
      )}
    </Modal>
  )
}

export default JobradModal
