import { config } from '../config'

import { Schemas } from './index'

export type ReportDetail = Schemas['ReportDetail']
export type ReportVersion = Schemas['ReportVersion']

export const reportUEVVersionsEndpoint = `${config.apiUrl}v1/contract-reports/uev/versions`

export type ManagerReport = Schemas['ManagerReport']
export type ManagerReportField = Schemas['ManagerReportField']
export type ManagerReportFile = Schemas['ManagerReportFile']
export type ManagerReportUpdate = Schemas['ManagerReportUpdate']
export type ManagerReportPeriodOption = Schemas['ManagerReport']['period']

export const managerReportsEndpoint = `${config.apiUrl}v1/manager-reports`
export const managerReportsExportEndpoint = `${managerReportsEndpoint}/export`
export const managerReportEndpoint = (reportId: number): string =>
  `${managerReportsEndpoint}/${reportId}`
