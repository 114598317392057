import { StatusCodes } from 'http-status-codes'
import { createSlice } from '@reduxjs/toolkit'

import { isApiAsyncThunkRejectedWithApiErrorAction } from '../apiAsyncThunk'
import { fetchPortalSession } from '../api/fetchPortalSession'

export type AppAuthState = 'UNAUTHORIZED' | 'AUTHORIZED'

type AppState = {
  authState?: AppAuthState
  blockUIOperations: number
}

const initialState: AppState = {
  blockUIOperations: 0
}

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    dataFetchingFailedWithAuthorizationError: (state) => ({
      ...state,
      authState: 'UNAUTHORIZED'
    }),
    startUIBlockingOperation: (state) => ({
      ...state,
      blockUIOperations: state.blockUIOperations + 1
    }),
    endUIBlockingOperation: (state) => ({
      ...state,
      blockUIOperations: state.blockUIOperations - 1
    }),
    logout: (state) => ({
      ...state,
      authState: 'UNAUTHORIZED'
    })
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPortalSession.fulfilled, (state) => ({
        ...state,
        authState: 'AUTHORIZED'
      }))
      .addMatcher(
        isApiAsyncThunkRejectedWithApiErrorAction,
        (state, { payload }) =>
          payload.code === StatusCodes.UNAUTHORIZED
            ? {
                ...state,
                authState: 'UNAUTHORIZED'
              }
            : state
      )
  }
})

export const {
  dataFetchingFailedWithAuthorizationError,
  startUIBlockingOperation,
  endUIBlockingOperation,
  logout
} = appSlice.actions
export default appSlice.reducer
