import { PartialDeep } from 'type-fest'

import { DeTranslations } from './german'

const landbw: PartialDeep<DeTranslations> = {
  employerArea: 'Dienstherrenbereich',
  employeeOrder: 'Bedienstetenantrag',
  employeeOrders: 'Bedienstetenanträge',
  navigation: {
    employeeOrders: 'Bedienstetenanträge',
    managerLinks: 'Dienstherrenbereich'
  },
  pages: {
    pageEmployeeAccountHeader: 'Ihr JobRad-Bedienstetenkonto'
  },
  features: {
    company: 'Dienstherr',
    employee: 'Bediensteter',
    leasingProcess: {
      createPurchaseOrder: 'Bestellantrag erstellen',
      detail: {
        approveOrderNotification:
          'Der Bedienstete wird über die Antragsfreigabe per E-Mail informiert.',
        conversionUsed:
          'Der Bedienstete hat bereits <bold>{{conversionUsed}}</bold> Euro genutzt.',
        createOrderSteps: {
          conclude: 'Antrag abschließen',
          uploadDocuments: 'Dokumente hochladen',
          approval: 'Freigabe Dienstherr',
          ordered: 'JobRad bestellt',
          active: 'Leasingvertrag aktiv'
        },
        detailManager: {
          subManagerAlert:
            'Erst dann kann der zuständige JobRad-Bestellberechtigte den Antrag des Bediensteten bestätigen.',
          notApproveOrderModalDescription:
            'Der Bedienstete muss einen neuen Antrag stellen, ' +
            'der Ihnen anschließend zur Prüfung vorliegt. ' +
            'Bitte gebe im untenstehenden Feld den Grund für die Ablehnung an. ' +
            'Der betroffene Bedienstete erhält automatisch eine E-Mail mit der Begründung.'
        }
      },
      createAndEditForm: {
        explanationHeader:
          'Bitte gebe den Grund für die Bearbeitung an. ' +
          'Diese wird ebenfalls per E-Mail an den Bediensteten übersandt.',
        subtitle:
          'Wenn Sie auf <highlight>Weiter</highlight> klicken, ' +
          'wird der durch den Bediensteten angelegte Antrag storniert. ' +
          'Ein neuer Bestellantrag wird mit von Ihnen getätigten Änderungen erstellt ' +
          'und zur erneuten Prüfung an den Bediensteten übersendet.'
      },
      list: {
        manager: {
          managerOrderListNoResultsHelpText:
            'Klicken Sie hier um einen neuen Antrag für sich selbst, oder im Namen eines Bediensteten zu erstellen.'
        }
      },
      regularContractTerminationFaq: {
        answer2Part1ForRrpBaseContract:
          'Zum Vertragsende kalkulieren wir in unserem <linktarget>Vergleichsrechner</linktarget> mit einem erwarteten Übernahmepreis ' +
          'von',
        answer2Part1ForNonRrpBaseContract:
          'Zum Vertragsende kalkulieren wir in der Regel mit einem erwarteten Übernahmepreis ' +
          'von',
        calculator: 'Vergleichsrechner',
        answer3:
          'Eine Ratenzahlung der Angebotssumme ist leider nicht möglich.\n' +
          'Wir arbeiten aber gerade daran, dies für zukünftige Bedienstete möglich zu machen. Unser Tipp: ' +
          'Für Unterstützung wenden Sie sich an bestehende Anbieter, zum Beispiel an Ihre Hausbank. '
      }
    }
  },
  uiBuildingBlocks: {
    feedback: {
      actionStatusMessage: {
        feOrderCan:
          'Bei der Änderung des Bedienstetenantrages ist ein Fehler aufgetreten. ' +
          'Bitte wenden Sie sich an einen JobRad Mitarbeiter.',
        fsOrderEdit:
          'Der Antrag wurde erfolgreich geändert. ' +
          'Der Bedienstete wurde per E-Mail darüber informiert ' +
          'und muss nun die notwendigen Dokumente hochladen.'
      }
    }
  }
}

export default landbw
export type LandBW = typeof landbw
