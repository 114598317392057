import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgArrowRight: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M2 8C1.44772 8 1 7.55228 1 7C1 6.44772 1.44772 6 2 6H9.58579L7.29289 3.70711C6.90237 3.31658 6.90237 2.68342 7.29289 2.29289C7.68342 1.90237 8.31658 1.90237 8.70711 2.29289L12.7071 6.29289C13.0976 6.68342 13.0976 7.31658 12.7071 7.70711L8.70711 11.7071C8.31658 12.0976 7.68342 12.0976 7.29289 11.7071C6.90237 11.3166 6.90237 10.6834 7.29289 10.2929L9.58579 8L2 8Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgArrowRight
