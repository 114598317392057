import { FunctionComponent, SVGProps } from 'react'

import Plus from './SvgComponents/Plus'
import ArrowDown from './SvgComponents/ArrowDown'
import ArrowUp from './SvgComponents/ArrowUp'
import ArrowLeft from './SvgComponents/ArrowLeft'
import ArrowRight from './SvgComponents/ArrowRight'
import Bubble from './SvgComponents/Bubble'
import ButtonLoader from './SvgComponents/ButtonLoader'
import Calender from './SvgComponents/Calendar'
import Check from './SvgComponents/Check'
import Checkbox from './SvgComponents/Checkbox'
import CheckboxReadOnly from './SvgComponents/CheckboxReadOnly'
import ChevronDown from './SvgComponents/ChevronDown'
import ChevronLeft from './SvgComponents/ChevronLeft'
import ChevronRight from './SvgComponents/ChevronRight'
import ChevronUp from './SvgComponents/ChevronUp'
import CloseChip from './SvgComponents/CloseChip'
import Delete from './SvgComponents/Delete'
import Download from './SvgComponents/Download'
import Edit from './SvgComponents/Edit'
import Error from './SvgComponents/Error'
import Euro from './SvgComponents/Euro'
import ExternalLink from './SvgComponents/ExternalLink'
import Heart from './SvgComponents/Heart'
import Info from './SvgComponents/Info'
import Locate from './SvgComponents/Locate'
import Mail from './SvgComponents/Mail'
import Menu from './SvgComponents/Menu'
import Photo from './SvgComponents/Photo'
import Search from './SvgComponents/Search'
import ServiceEmpty from './SvgComponents/ServiceEmpty'
import ServiceFull from './SvgComponents/ServiceFull'
import Time from './SvgComponents/Time'
import Upload from './SvgComponents/Upload'
import View from './SvgComponents/View'
import Close from './SvgComponents/Close'
import Support from './SvgComponents/Support'

export const iconMap: Record<
  IconNames,
  FunctionComponent<SVGProps<SVGSVGElement>>
> = {
  plusIcon: Plus,
  arrowUp: ArrowUp,
  arrowDown: ArrowDown,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bubble: Bubble,
  buttonLoader: ButtonLoader,
  calender: Calender,
  check: Check,
  checkbox: Checkbox,
  checkboxReadOnly: CheckboxReadOnly,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  closeChip: CloseChip,
  delete: Delete,
  download: Download,
  edit: Edit,
  error: Error,
  euro: Euro,
  externalLink: ExternalLink,
  heart: Heart,
  info: Info,
  locate: Locate,
  mail: Mail,
  menu: Menu,
  photo: Photo,
  search: Search,
  serviceEmpty: ServiceEmpty,
  serviceFull: ServiceFull,
  time: Time,
  upload: Upload,
  view: View,
  close: Close,
  support: Support
}

export type IconNames =
  | 'plusIcon'
  | 'arrowUp'
  | 'arrowDown'
  | 'arrowLeft'
  | 'arrowRight'
  | 'bubble'
  | 'buttonLoader'
  | 'calender'
  | 'check'
  | 'checkbox'
  | 'checkboxReadOnly'
  | 'chevronDown'
  | 'chevronLeft'
  | 'chevronRight'
  | 'chevronUp'
  | 'closeChip'
  | 'delete'
  | 'download'
  | 'edit'
  | 'error'
  | 'euro'
  | 'externalLink'
  | 'heart'
  | 'info'
  | 'locate'
  | 'mail'
  | 'menu'
  | 'photo'
  | 'search'
  | 'serviceEmpty'
  | 'serviceFull'
  | 'time'
  | 'upload'
  | 'view'
  | 'close'
  | 'support'
