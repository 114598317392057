import { createSlice } from '@reduxjs/toolkit'

import { Brand } from '../../api/brand'
import { RootState, ErrorAndPendingAwareState } from '../'
import {
  addFetchBrandsFulfilledCase,
  addFetchBrandsPendingCase,
  addFetchBrandsRejectedCase
} from '../api/fetchBrands'

export type BrandList = ErrorAndPendingAwareState<Brand[]>

export type BrandsSlice = {
  list: BrandList
}

export const initialState: BrandsSlice = {
  list: {
    pending: false
  }
}

const brandsSlice = createSlice({
  name: 'brands',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchBrandsPendingCase(builder)
    addFetchBrandsFulfilledCase(builder)
    addFetchBrandsRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareBrands = (
  state: RootState
): ErrorAndPendingAwareState<Brand[]> => state.brands.list

export default brandsSlice.reducer
