import { createSlice } from '@reduxjs/toolkit'

import { RootState, ErrorAndPendingAwareState } from '../index'
import {
  addFetchReportVersionsFulfilledCase,
  addFetchReportVersionsPendingCase,
  addFetchReportVersionsRejectedCase
} from '../api/fetchReportVersions'
import { ReportVersion } from '../../api/report'

export type ManagerReportVersionList = ErrorAndPendingAwareState<
  ReportVersion[]
>

export type ManagerReportVersionsSlice = {
  list: ManagerReportVersionList
}

export const initialState: ManagerReportVersionsSlice = {
  list: {
    pending: false
  }
}

const managerReportVersionsSlice = createSlice({
  name: 'managerReportVersions',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchReportVersionsFulfilledCase(builder)
    addFetchReportVersionsPendingCase(builder)
    addFetchReportVersionsRejectedCase(builder)
  }
})

export const selectErrorAndPendingAwareManagerReportVersions = (
  state: RootState
): ErrorAndPendingAwareState<ReportVersion[]> => state.reports.list

export default managerReportVersionsSlice.reducer
