import React, { useState } from 'react'
import html2canvas from '@trainiac/html2canvas'

import SButton from '@uiLibrary/SButton'
import Body from '@uiLibrary/typography/Body'
import IconButton from '@uiLibrary/IconButton'
import HelpdeskScreenshotPreviewModal from '@features/helpdesk/HelpdeskScreenshotPreviewModal'

import { useI18n } from '../../../../../../../i18n'

import styles from './HelpdeskScreenshotCapture.module.scss'

type HelpdeskScreenshotCaptureProps = {
  screenshot: string
  setScreenshot: (screenshot: string) => void
}

const HelpdeskScreenshotCapture: React.FunctionComponent<
  HelpdeskScreenshotCaptureProps
> = ({ screenshot, setScreenshot: setScreenshot }) => {
  const { t } = useI18n()

  const onTakeScreenshotClick = async (): Promise<void> => {
    try {
      const canvas = await html2canvas(document.body)
      const screenshotURL = canvas.toDataURL()
      setScreenshot(screenshotURL)
    } catch (error) {
      setScreenshot('')
    }
  }
  const onDeleteScreenshotClick = (): void => {
    setScreenshot('')
  }

  const [
    isHelpdeskScreenshotPreviewModalOpen,
    setHelpdeskScreenshotPreviewModalOpen
  ] = useState(false)
  const closeModal = (): void => {
    setHelpdeskScreenshotPreviewModalOpen(false)
  }

  return (
    <>
      <div>
        {screenshot && (
          <div
            id="cap_file"
            data-testid="cap_file"
            className={styles.screenshotFile}
          >
            <Body inline>
              {t(
                'features.helpdesk.fileAndScreenshotCapture.screenShotFileName'
              )}
            </Body>
            <div className={styles.screenshotButtonGroup}>
              <IconButton
                variant="primary"
                ariaLabel={t(
                  'features.helpdesk.fileAndScreenshotCapture.screenShotModal.openScreenShotModal'
                )}
                iconName="view"
                onClick={() => setHelpdeskScreenshotPreviewModalOpen(true)}
              />
              <IconButton
                variant="primary"
                ariaLabel={t(
                  'features.helpdesk.fileAndScreenshotCapture.deleteScreenShot'
                )}
                iconName="delete"
                onClick={onDeleteScreenshotClick}
              />
            </div>
          </div>
        )}

        <SButton
          onClick={onTakeScreenshotClick}
          iconName="photo"
          label={
            screenshot
              ? t(
                  'features.helpdesk.fileAndScreenshotCapture.replaceScreenShot'
                )
              : t('features.helpdesk.fileAndScreenshotCapture.createScreenShot')
          }
        />
      </div>

      <HelpdeskScreenshotPreviewModal
        isModalOpen={isHelpdeskScreenshotPreviewModalOpen}
        closeModal={() => closeModal()}
        screenshot={screenshot}
      />
    </>
  )
}

export default HelpdeskScreenshotCapture
