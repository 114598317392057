import { selectCsrfToken } from '../slices/session'
import { buildRejectValue, createApiAsyncThunk } from '../apiAsyncThunk'
import { HelpdeskTicket, helpdeskTicketEndpoint } from '../../api/helpdesk'

import { fetchOrderMessages } from './fetchOrderMessages'

export const addHelpdeskTicket = createApiAsyncThunk<
  HelpdeskTicket,
  HelpdeskTicket
>('orders/addOrder', async (helpdeskTicket, thunkApi) => {
  const response = await fetch(`${helpdeskTicketEndpoint}`, {
    method: 'POST',
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      accept: 'application/json',
      'content-type': 'application/json',
      'x-csrf-token': selectCsrfToken(thunkApi.getState())
      /* eslint-enable @typescript-eslint/naming-convention */
    },
    body: JSON.stringify(helpdeskTicket)
  })

  if (
    response.ok &&
    helpdeskTicket.vva !== undefined &&
    helpdeskTicket.vva.order_id !== undefined
  ) {
    await thunkApi.dispatch(fetchOrderMessages(helpdeskTicket.vva.order_id))
  }

  const responseBody = await response.json()

  if (!response.ok) {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return responseBody
})
