import React from 'react'
import classNames from 'classnames'

import Icon from '@uiLibrary/Icon'
import { IconNames } from '@uiLibrary/Icon/iconMap'

import styles from './SButton.module.scss'

export type SButtonProps = {
  label?: string
  ariaLabelledby?: string
  iconName: IconNames
  isSubmit?: boolean
  onClick?: React.MouseEventHandler
  onBlur?: React.FocusEventHandler
  ariaLabel?: string
  visuallyHidden?: string
}

export const SButton: React.FunctionComponent<SButtonProps> = ({
  label,
  ariaLabelledby,
  iconName,
  isSubmit,
  onBlur,
  onClick,
  ariaLabel,
  visuallyHidden
}) => (
  <button
    aria-labelledby={ariaLabelledby}
    onClick={onClick}
    onBlur={onBlur}
    type={isSubmit ? 'submit' : 'button'}
    className={classNames(styles.sButton, label ? undefined : styles.iconOnly)}
    aria-label={ariaLabel}
  >
    <span className={styles.icon}>
      <Icon variant="normal" iconName={iconName} />
    </span>
    <span className={styles.visuallyHidden}>{visuallyHidden}</span>
    {label}
  </button>
)
