import { ActionReducerMapBuilder, createAction } from '@reduxjs/toolkit'

import { selectCsrfToken } from '../slices/session'
import {
  OrderList,
  OrderListData,
  OrderListUpdateOptions,
  OrdersSlice
} from '../slices/orders'
import {
  buildFetchError,
  buildRejectValue,
  createApiAsyncThunk
} from '../apiAsyncThunk'
import { mapFilterToParams, ordersEndpoint } from '../../api/order'

export const PAGE_SIZE = 25

// we define the action here, to avoid cyclic dependencies between the order slice and this file
export const orderListUpdateOptionsChanged =
  createAction<OrderListUpdateOptions>('orders/orderListUpdateOptionsChanged')

export const constructOrdersFetchParameters = (
  orderList: OrderList | undefined
): string => {
  const filters = (orderList && orderList.filters) || []
  const sorting = orderList && orderList.sorting
  const pageOffset = (orderList && orderList.pageOffset) || 0

  const parameterSets = [
    mapFilterToParams(filters),
    `offset=${PAGE_SIZE * pageOffset}&limit=${PAGE_SIZE}`,
    sorting ? `&order=${sorting.column}+${sorting.order}` : undefined,
    'count=1' // todo: maybe only request count for the first time
  ]
  return parameterSets.filter((x) => x && x !== '').join('&')
}

export const fetchOrders = createApiAsyncThunk<
  OrderListData,
  OrderListUpdateOptions | undefined
>('orders/fetchOrders', async (arg = {}, thunkApi) => {
  if (Object.keys(arg).length) {
    thunkApi.dispatch(orderListUpdateOptionsChanged(arg))
  }

  const state = thunkApi.getState()
  const parameters = constructOrdersFetchParameters(state.orders.list)

  const response = await fetch(`${ordersEndpoint}?${parameters}`, {
    headers: {
      /* eslint-disable @typescript-eslint/naming-convention */
      accept: 'application/json',
      'x-csrf-token': selectCsrfToken(thunkApi.getState())
      /* eslint-enable @typescript-eslint/naming-convention */
    }
  })

  const responseBody = await response.json()

  if (!response.ok) {
    return thunkApi.rejectWithValue(buildRejectValue(responseBody))
  }

  return {
    total: parseInt(response.headers.get('X-Total-Count') || '0'),
    page: responseBody
  }
})

export const addFetchOrdersPendingCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrders.pending, (state) => ({
    ...state,
    list: {
      ...state.list,
      loading: true
    }
  }))
}

export const addFetchOrdersFulfilledCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrders.fulfilled, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      error: undefined,
      loading: false,
      data: action.payload
    }
  }))
}

export const addFetchOrdersRejectedCase = (
  builder: ActionReducerMapBuilder<OrdersSlice>
): void => {
  builder.addCase(fetchOrders.rejected, (state, action) => ({
    ...state,
    list: {
      ...state.list,
      loading: false,
      data: undefined,
      error: buildFetchError(action)
    }
  }))
}
