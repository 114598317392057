// eslint-disable-next-line no-restricted-imports
import { useLocation, useParams } from 'react-router-dom'
import type { Location, Params } from 'react-router-dom'

import { useAccessInfo } from '@context/useAccessInfo'

// Please keep it alphabetically sorted.
const pageNames = [
  'Delivery Confirmation' as const,
  'Employee Account' as const,
  'Employee Digital UEV Signature' as const,
  'Employee Explanatory Videos' as const,
  'Employee Leasing Detail' as const,
  'Employee Order Create' as const,
  'Employee Order Edit' as const,
  'Leasing RVA Product Team' as const,
  'Manager Explanatory Videos' as const,
  'Manager Order Create' as const,
  'Manager Order Detail' as const,
  'Manager Order Edit' as const,
  'Manager Order List' as const,
  'Manager Reports' as const,
  'Manager Transfer Contract' as const,
  'Manager Vva' as const,
  'My Orders And Offers' as const,
  'Redirect' as const,
  'Regular Contract Termination Faq' as const
] as const
export type PageName = (typeof pageNames)[number]

const urlPathSegmentPattern = `[^\\/]+`
const getUrlPathRegex = (
  pageName: PageName,
  slug: string,
  accessCode: string
): RegExp => {
  // Please keep it alphabetically sorted.
  switch (pageName) {
    case 'Delivery Confirmation':
      return new RegExp(`/receiving/${urlPathSegmentPattern}`)
    case 'Employee Account':
      return new RegExp(`/${slug}/account/${accessCode}`)
    case 'Employee Digital UEV Signature':
      return new RegExp(
        `/${slug}/leasing/${urlPathSegmentPattern}/${accessCode}/uev`
      )
    case 'Employee Explanatory Videos':
      return new RegExp(
        `/${slug}/explanatory-videos-for-employees/${accessCode}`
      )
    case 'Employee Leasing Detail':
      return new RegExp(
        `/${slug}/leasing/${urlPathSegmentPattern}/${accessCode}`
      )
    case 'Employee Order Create':
      return new RegExp(`/${slug}/order/create/${accessCode}`)
    case 'Employee Order Edit':
      return new RegExp(
        `/${slug}/leasing/edit/${urlPathSegmentPattern}/${accessCode}`
      )
    case 'Leasing RVA Product Team':
      return new RegExp(`/leasing/end/${urlPathSegmentPattern}`)
    case 'Manager Explanatory Videos':
      return new RegExp(
        `/${slug}/manager/explanatory-videos-for-managers/${accessCode}`
      )
    case 'Manager Order Create':
      return new RegExp(`/${slug}/manager/order/create/${accessCode}`)
    case 'Manager Order Detail':
      return new RegExp(
        `/${slug}/manager/leasing/${urlPathSegmentPattern}/${accessCode}`
      )
    case 'Manager Order Edit':
      return new RegExp(
        `/${slug}/manager/leasing/edit/${urlPathSegmentPattern}/${accessCode}`
      )
    case 'Manager Order List':
      return new RegExp(`/${slug}/manager/leasing/${accessCode}`)
    case 'Manager Reports':
      return new RegExp(`/${slug}/manager/reports/${accessCode}`)
    case 'Manager Transfer Contract':
      return new RegExp(`/${slug}/manager/contract/${accessCode}`)
    case 'Manager Vva':
      return new RegExp(
        `/${slug}/manager/leasing/${urlPathSegmentPattern}/${accessCode}/vva`
      )
    case 'My Orders And Offers':
      return new RegExp(`/${slug}/leasing/${accessCode}`)
    case 'Redirect':
      return new RegExp(`/${slug}/login/${accessCode}`)
    case 'Regular Contract Termination Faq':
      return new RegExp(`/leasing/ending-faq/${urlPathSegmentPattern}`)
    default:
      throw new Error()
  }
}

export const getPageName = (
  slug: string,
  accessCode: string,
  urlPath: string
): PageName => {
  for (const pageName of pageNames) {
    const regex = getUrlPathRegex(pageName as PageName, slug, accessCode)
    if (regex.test(urlPath)) return pageName as PageName
  }
  throw new Error()
}

// Please keep it alphabetically sorted.
type LocationDeliveryConfirmation = {
  internal: true
  pageName: 'Delivery Confirmation'
  context: { shipmentCode: string }
}
type LocationEmployeeAccount = {
  internal: true
  pageName: 'Employee Account'
}
type LocationEmployeeDigitalUEVSignature = {
  internal: true
  pageName: 'Employee Digital UEV Signature'
  context: { orderId: number }
}
type LocationEmployeeExplanatoryVideos = {
  internal: true
  pageName: 'Employee Explanatory Videos'
  context: { videoId: string | null }
}
type LocationEmployeeLeasingDetail = {
  internal: true
  pageName: 'Employee Leasing Detail'
  context: {
    orderId: number
    event: 'user confirmed order' | null
  }
}
type LocationEmployeeOrderCreate = {
  internal: true
  pageName: 'Employee Order Create'
  context: { offerCode: string | null }
}
type LocationEmployeeOrderEdit = {
  internal: true
  pageName: 'Employee Order Edit'
  context: { orderId: number }
}
type LocationLeasingRVAProductTeam = {
  internal: true
  pageName: 'Leasing RVA Product Team'
  context: {
    rvaKauCode: string
    event: 'user clicked link from within the portal' | null
  }
}
type LocationManagerExplanatoryVideos = {
  internal: true
  pageName: 'Manager Explanatory Videos'
  context: { videoId: string | null }
}
type LocationManagerOrderCreate = {
  internal: true
  pageName: 'Manager Order Create'
  context: { offerCode: string | null }
}
type LocationManagerOrderDetail = {
  internal: true
  pageName: 'Manager Order Detail'
  context: {
    orderId: number
    event: 'user created VVA ticket' | null
  }
}
type LocationManagerOrderEdit = {
  internal: true
  pageName: 'Manager Order Edit'
  context: { orderId: number }
}
type LocationManagerOrderList = {
  internal: true
  pageName: 'Manager Order List'
  context: {
    event:
      | 'user canceled order'
      | 'user discarded order draft'
      | 'user rejected offer'
      | 'user saved order draft'
      | null
  }
}
type LocationManagerReports = {
  internal: true
  pageName: 'Manager Reports'
}
type LocationManagerTransferContract = {
  internal: true
  pageName: 'Manager Transfer Contract'
}
type LocationManagerVva = {
  internal: true
  pageName: 'Manager Vva'
  context: { orderId: number }
}
type LocationMyOrdersAndOffers = {
  internal: true
  pageName: 'My Orders And Offers'
  context: {
    event:
      | 'user canceled order'
      | 'user discarded order draft'
      | 'user rejected offer'
      | 'user saved order draft'
      | null
  }
}
type LocationRedirect = {
  internal: true
  pageName: 'Redirect'
}
type LocationRegularContractTerminationFaq = {
  internal: true
  pageName: 'Regular Contract Termination Faq'
  context: { rvaKauCode: string }
}
// Please keep it alphabetically sorted.
export type InternalLocation =
  | LocationDeliveryConfirmation
  | LocationEmployeeAccount
  | LocationEmployeeDigitalUEVSignature
  | LocationEmployeeExplanatoryVideos
  | LocationEmployeeLeasingDetail
  | LocationEmployeeOrderCreate
  | LocationEmployeeOrderEdit
  | LocationLeasingRVAProductTeam
  | LocationManagerExplanatoryVideos
  | LocationManagerOrderCreate
  | LocationManagerOrderDetail
  | LocationManagerOrderEdit
  | LocationManagerOrderList
  | LocationManagerReports
  | LocationManagerVva
  | LocationManagerTransferContract
  | LocationMyOrdersAndOffers
  | LocationRedirect
  | LocationRegularContractTerminationFaq
export type ExternalLocation = {
  internal: false
  location: string
}

/**
 * A location internal or external to this app.
 *
 * - An external location may be any `location` string representing a URI.
 * - An internal location represents a page (identified by `pageName`)
 * within this app, optionally accompanied by page-specific, app-level
 * `context` information. For example, some pages have as context the user
 * `event` that causes the page to be shown.
 */
export type TypedLocation = InternalLocation | ExternalLocation

// Please keep it alphabetically sorted.
export type Event =
  | 'user canceled order'
  | 'user clicked link from within the portal'
  | 'user confirmed order'
  | 'user created VVA ticket'
  | 'user discarded order draft'
  | 'user rejected offer'
  | 'user saved order draft'
export const getLocationContextEvent = (
  location: InternalLocation
): Event | null => {
  if (
    !location.internal ||
    (location.pageName !== 'Employee Leasing Detail' &&
      location.pageName !== 'Leasing RVA Product Team' &&
      location.pageName !== 'Manager Order Detail' &&
      location.pageName !== 'Manager Order List' &&
      location.pageName !== 'My Orders And Offers')
  )
    return null

  // The presence of location.context.event is a symptom of not having an
  // independent notification system in place.
  // TODO: Implement an independent notification system;
  //  then remove events from the InternalLocation data structure
  return location.context.event
}

// Please keep it alphabetically sorted.
const getLocationDeliveryConfirmation = (
  routeParams: Readonly<Params>
): LocationDeliveryConfirmation => ({
  internal: true,
  pageName: 'Delivery Confirmation',
  context: { shipmentCode: routeParams.shipmentCode ?? '' }
})
const getLocationEmployeeAccount = (): LocationEmployeeAccount => ({
  internal: true,
  pageName: 'Employee Account'
})
const getLocationEmployeeDigitalUEVSignature = (
  routeParams: Readonly<Params>
): LocationEmployeeDigitalUEVSignature => ({
  internal: true,
  pageName: 'Employee Digital UEV Signature',
  context: { orderId: Number(routeParams.orderId) }
})
const getLocationEmployeeExplanatoryVideos = (
  location: Location
): LocationEmployeeExplanatoryVideos => {
  const urlHashFragment = location.hash.slice(1)
  const videoId = urlHashFragment === '' ? null : urlHashFragment
  return {
    internal: true,
    pageName: 'Employee Explanatory Videos',
    context: { videoId: videoId }
  }
}
const getLocationEmployeeLeasingDetail = (
  location: Location,
  routeParams: Readonly<Params>
): LocationEmployeeLeasingDetail => ({
  internal: true,
  pageName: 'Employee Leasing Detail',
  context: {
    orderId: Number(routeParams.orderId),
    event: location.state?.event
  }
})
const getLocationEmployeeOrderCreate = (
  location: Location
): LocationEmployeeOrderCreate => ({
  internal: true,
  pageName: 'Employee Order Create',
  context: { offerCode: location.state?.offerCode }
})
const getLocationEmployeeOrderEdit = (
  routeParams: Readonly<Params>
): LocationEmployeeOrderEdit => ({
  internal: true,
  pageName: 'Employee Order Edit',
  context: { orderId: Number(routeParams.orderId) }
})
const getLocationLeasingRVAProductTeam = (
  location: Location,
  routeParams: Readonly<Params>
): LocationLeasingRVAProductTeam => ({
  internal: true,
  pageName: 'Leasing RVA Product Team',
  context: {
    rvaKauCode: routeParams.rvaKauCode ?? '',
    event: location.state?.event
  }
})
const getLocationManagerExplanatoryVideos = (
  location: Location
): LocationManagerExplanatoryVideos => {
  const urlHashFragment = location.hash.slice(1)
  const videoId = urlHashFragment === '' ? null : urlHashFragment
  return {
    internal: true,
    pageName: 'Manager Explanatory Videos',
    context: { videoId: videoId }
  }
}
const getLocationManagerOrderCreate = (
  location: Location
): LocationManagerOrderCreate => ({
  internal: true,
  pageName: 'Manager Order Create',
  context: { offerCode: location.state?.offerCode }
})
const getLocationManagerOrderDetail = (
  location: Location,
  routeParams: Readonly<Params>
): LocationManagerOrderDetail => ({
  internal: true,
  pageName: 'Manager Order Detail',
  context: {
    orderId: Number(routeParams.orderId),
    event: location.state?.event
  }
})
const getLocationManagerOrderEdit = (
  routeParams: Readonly<Params>
): LocationManagerOrderEdit => ({
  internal: true,
  pageName: 'Manager Order Edit',
  context: { orderId: Number(routeParams.orderId) }
})
const getLocationManagerOrderList = (
  location: Location
): LocationManagerOrderList => ({
  internal: true,
  pageName: 'Manager Order List',
  context: { event: location.state?.event }
})
const getLocationManagerReports = (): LocationManagerReports => ({
  internal: true,
  pageName: 'Manager Reports'
})
const getLocationManagerTransferContract =
  (): LocationManagerTransferContract => ({
    internal: true,
    pageName: 'Manager Transfer Contract'
  })
const getLocationManagerVva = (
  routeParams: Readonly<Params>
): LocationManagerVva => ({
  internal: true,
  pageName: 'Manager Vva',
  context: { orderId: Number(routeParams.orderId) }
})
const getLocationMyOrdersAndOffers = (
  location: Location
): LocationMyOrdersAndOffers => ({
  internal: true,
  pageName: 'My Orders And Offers',
  context: { event: location.state?.event }
})
const getLocationRedirect = (): LocationRedirect => ({
  internal: true,
  pageName: 'Redirect'
})
const getLocationRegularContractTerminationFaq = (
  routeParams: Readonly<Params>
): LocationRegularContractTerminationFaq => ({
  internal: true,
  pageName: 'Regular Contract Termination Faq',
  context: { rvaKauCode: routeParams.rvaKauCode ?? '' }
})
const getInternalLocation = (
  pageName: PageName,
  location: Location,
  routeParams: Readonly<Params>
): InternalLocation => {
  // Please keep it alphabetically sorted.
  switch (pageName) {
    case 'Delivery Confirmation':
      return getLocationDeliveryConfirmation(routeParams)
    case 'Employee Account':
      return getLocationEmployeeAccount()
    case 'Employee Digital UEV Signature':
      return getLocationEmployeeDigitalUEVSignature(routeParams)
    case 'Employee Explanatory Videos':
      return getLocationEmployeeExplanatoryVideos(location)
    case 'Employee Leasing Detail':
      return getLocationEmployeeLeasingDetail(location, routeParams)
    case 'Employee Order Create':
      return getLocationEmployeeOrderCreate(location)
    case 'Employee Order Edit':
      return getLocationEmployeeOrderEdit(routeParams)
    case 'Leasing RVA Product Team':
      return getLocationLeasingRVAProductTeam(location, routeParams)
    case 'Manager Explanatory Videos':
      return getLocationManagerExplanatoryVideos(location)
    case 'Manager Order Create':
      return getLocationManagerOrderCreate(location)
    case 'Manager Order Detail':
      return getLocationManagerOrderDetail(location, routeParams)
    case 'Manager Order Edit':
      return getLocationManagerOrderEdit(routeParams)
    case 'Manager Order List':
      return getLocationManagerOrderList(location)
    case 'Manager Reports':
      return getLocationManagerReports()
    case 'Manager Transfer Contract':
      return getLocationManagerTransferContract()
    case 'Manager Vva':
      return getLocationManagerVva(routeParams)
    case 'My Orders And Offers':
      return getLocationMyOrdersAndOffers(location)
    case 'Redirect':
      return getLocationRedirect()
    case 'Regular Contract Termination Faq':
      return getLocationRegularContractTerminationFaq(routeParams)
    default:
      throw new Error()
  }
}

/**
 * Return the current location within the portal as an `InternalLocation` object.
 *
 * This hook internally uses the browser's location API but adds a thin
 * layer of application logic on top of it to:
 *
 * 1. Give each location within the app a meaningful name.
 * 2. Allow storing page-specific, typed context with each location.
 * 3. Provide a declarative API for navigation across internal locations.
 *
 * @see useTypedNavigate
 */
export const useTypedLocation = (): InternalLocation => {
  const { slug, accessCode } = useAccessInfo()
  const location = useLocation()
  const { pathname: urlPath } = location
  const routeParams = useParams()
  const pageName = getPageName(slug, accessCode, urlPath)
  return getInternalLocation(pageName, location, routeParams)
}
