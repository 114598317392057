import React from 'react'

import Body from '../../typography/Body'
import Icon from '../../Icon'
import { useI18n } from '../../../i18n'

import styles from './NewsletterSubscribedNotification.module.scss'

const NewsletterSubscribedNotification: React.FunctionComponent = () => {
  const { t } = useI18n()
  return (
    <div className={styles.newsletterSubscribedNotification}>
      <Icon variant="normal" iconName="check" color="green" />
      <Body small strong>
        {t('newsletterSettings.subscriptionText')}
      </Body>
    </div>
  )
}

export default NewsletterSubscribedNotification
