import * as React from 'react'
import type { SVGProps } from 'react'
import { FunctionComponent } from 'react'

const SvgMenu: FunctionComponent<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    /* eslint-disable-next-line react/jsx-props-no-spreading */
    {...props}
  >
    {props.children}
    <path
      d="M0 2C0 1.44772 0.447715 1 1 1H13C13.5523 1 14 1.44772 14 2C14 2.55228 13.5523 3 13 3L1 3C0.447715 3 0 2.55229 0 2Z"
      fill="currentColor"
    />
    <path
      d="M0 7C0 6.44772 0.447715 6 1 6L13 6C13.5523 6 14 6.44772 14 7C14 7.55228 13.5523 8 13 8L1 8C0.447715 8 0 7.55229 0 7Z"
      fill="currentColor"
    />
    <path
      d="M1 11C0.447715 11 0 11.4477 0 12C0 12.5523 0.447715 13 1 13H13C13.5523 13 14 12.5523 14 12C14 11.4477 13.5523 11 13 11H1Z"
      fill="currentColor"
    />
  </svg>
)
export default SvgMenu
