import React from 'react'
import { Popover } from '@headlessui/react'
import {
  autoUpdate,
  offset,
  shift,
  size,
  useFloating
} from '@floating-ui/react'

import Icon from '@uiLibrary/Icon'

import { useSession } from '../../../context/useSession'
import { useI18n } from '../../../i18n'

import styles from './Helpdesk.module.scss'
import HelpdeskPopover from './HelpdeskPopover'

const Helpdesk: React.FunctionComponent = () => {
  const session = useSession()
  const { t } = useI18n()
  const { refs, floatingStyles } = useFloating({
    whileElementsMounted: autoUpdate,
    placement: 'top-end',
    middleware: [offset(8), shift(), size()]
  })

  if (!session) return null

  return (
    <Popover className={styles.supportPopover} data-html2canvas-ignore="true">
      <Popover.Button
        className={styles.supportPopoverButton}
        ref={refs.setReference}
      >
        <span className={styles.icon}>
          <Icon iconName="support" variant="big" color="white" />
        </span>
        {t('features.helpdesk.buttonLabel')}
      </Popover.Button>
      <Popover.Panel
        ref={refs.setFloating}
        className={styles.supportPopoverContent}
        style={floatingStyles}
      >
        {({ close }) => (
          <HelpdeskPopover
            contract={session.contract}
            closePopover={() => close()}
          />
        )}
      </Popover.Panel>
    </Popover>
  )
}

export default Helpdesk
