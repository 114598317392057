import { createSlice } from '@reduxjs/toolkit'

import { NewsletterSettings } from '@api/newsletterSettings'
import {
  addFetchNewsletterSettingsFulfilledCase,
  addFetchNewsletterSettingsPendingCase,
  addFetchNewsletterSettingsRejectedCase
} from '@redux/api/fetchNewsletterSettings'

import { RootState, ErrorAndPendingAwareState } from '../'
import { addSubscribeToAllNewslettersFulfilledCase } from '../api/subscribeToNewsletters'

export type NewsletterSettingsSlice =
  ErrorAndPendingAwareState<NewsletterSettings>

export const initialState: NewsletterSettingsSlice = {
  pending: false,
  error: undefined,
  data: undefined
}

const newsletterSettingsSlice = createSlice({
  name: 'newsletterSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    addFetchNewsletterSettingsPendingCase(builder)
    addFetchNewsletterSettingsFulfilledCase(builder)
    addFetchNewsletterSettingsRejectedCase(builder)
    addSubscribeToAllNewslettersFulfilledCase(builder)
  }
})

export const selectErrorAndPendingAwareNewsletterSettings = (
  state: RootState
): ErrorAndPendingAwareState<NewsletterSettings> => state.newsletterSettings

export default newsletterSettingsSlice.reducer
