import { ordersEndpoint } from './order'

import { Schemas } from './index'

export type OrderDocument = Schemas['Document']
export type OrderDocumentUploadResponse = Schemas['DocumentUploadResponse']
export type OrderDocumentDeleteResponse = Schemas['DocumentDeleteResponse']

export type StructuredOrderDocuments = {
  commonDocumentTypeCodes: Partial<
    Record<OrderDocumentTypeCommon, OrderDocument>
  >
  customDocumentTypeCodes: OrderDocument[]
}

const ORDER_DOCUMENTS_SUFFIX = 'documents'

export const orderDocumentsEndpoint = (orderId: number): string =>
  `${ordersEndpoint}/${orderId}/${ORDER_DOCUMENTS_SUFFIX}`

export const orderDocumentEndpoint = (
  orderId: number,
  documentId: number
): string => `${orderDocumentsEndpoint(orderId)}/${documentId}`

// until the API has been fixed to contain the correct type for the "datas" key, we provide this type manually here
export type OrderDocumentUploadType = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  doc_type: OrderDocumentTypeUploadable
  datas: File
}
export type OrderDocumentUploadData = {
  document: OrderDocumentUploadType
  orderId: number
}

/*
 * Type guards for order documents
 *
 * the document_type of the order_documents is "string", since it can be anything. However, we still distinguish
 * certain types for which we know the meaning of the value. Ideally, the API would encode that these types can
 * be returned, and what they mean. However, until this is done, the information lives inside these type guards.
 */

export type OrderDocumentTypeView =
  | OrderDocumentTypeCommon
  | 'customDocumentTypeCode'

export const DOCUMENT_TYPE_UPLOAD_EXTRA = 'upload_extra' as const

// TODO: Add document type `info`, because it's also a known (common) document type.
//   Alternatively, remove `upload_extra`, because both `info` and `upload_extra` have a special meaning,
//   different from all other common document types. The meaning of "common document type" would then
//   be something like "regular, standard document type" rather than "known, predefined document type".
const COMMON_DOCUMENT_TYPES = [
  'fha',
  'uev',
  'uev_signed',
  'ueb',
  'ueb_signed',
  'ueb_signed_online',
  DOCUMENT_TYPE_UPLOAD_EXTRA,
  'elv'
] as const

export type OrderDocumentTypeCommon = (typeof COMMON_DOCUMENT_TYPES)[number]

export const valueIsOfTypeOrderDocumentTypeCommon = (
  value: string
): value is OrderDocumentTypeCommon => {
  // widen type to string[], necessary because of https://github.com/microsoft/TypeScript/issues/26255
  const values: readonly string[] = COMMON_DOCUMENT_TYPES
  return values.includes(value)
}

const UPLOADABLE_DOCUMENT_TYPES = ['fha', 'upload_extra', 'uev_signed'] as const
export type OrderDocumentTypeUploadable =
  (typeof UPLOADABLE_DOCUMENT_TYPES)[number]
export const documentIsUploadable = (
  documentType: string
): documentType is OrderDocumentTypeUploadable => {
  // widen type to string[], necessary because of https://github.com/microsoft/TypeScript/issues/26255
  const values: readonly string[] = UPLOADABLE_DOCUMENT_TYPES
  return values.includes(documentType)
}

// documents that can be deleted contain an id (which is otherwise optional). This type guard gives a name to them
export type OrderDocumentDeletable = OrderDocument & {
  id: number
}
export const isTypeOrderDocumentDeletable = (
  document: OrderDocument
): document is OrderDocumentDeletable => !!document.id

/**
 * Important documents are documents that are not directly attached to the KAU, but are related to the leasing itself
 * Example: Information about the insurance. Those always have the document type "info".
 * @param document
 */
export const documentIsImportant = (document: OrderDocument): boolean =>
  document.document_type === 'info'

export const documentIsExtra = (document: OrderDocument): boolean =>
  document.document_type === 'ueb' ||
  document.document_type === 'ueb_signed' ||
  document.document_type === 'ueb_signed_online' ||
  document.document_type === DOCUMENT_TYPE_UPLOAD_EXTRA
